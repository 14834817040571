/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as debug_pb from './debug_pb';
var DebugClient = /** @class */ (function () {
    function DebugClient(hostname, credentials, options) {
        this.methodDescriptorCreateToken = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/CreateToken', grpcWeb.MethodType.UNARY, debug_pb.CreateTokenRequest, debug_pb.CreateTokenResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.CreateTokenResponse.deserializeBinary);
        this.methodDescriptorGetCoinBalance = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetCoinBalance', grpcWeb.MethodType.UNARY, debug_pb.GetCoinBalanceRequest, debug_pb.GetCoinBalanceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetCoinBalanceResponse.deserializeBinary);
        this.methodDescriptorAddCoin = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/AddCoin', grpcWeb.MethodType.UNARY, debug_pb.AddCoinRequest, debug_pb.AddCoinResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.AddCoinResponse.deserializeBinary);
        this.methodDescriptorCreateUsers = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/CreateUsers', grpcWeb.MethodType.UNARY, debug_pb.CreateUsersRequest, debug_pb.CreateUsersResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.CreateUsersResponse.deserializeBinary);
        this.methodDescriptorDeleteDevice = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DeleteDevice', grpcWeb.MethodType.UNARY, debug_pb.DeleteDeviceRequest, debug_pb.DeleteDeviceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DeleteDeviceResponse.deserializeBinary);
        this.methodDescriptorListUsers = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/ListUsers', grpcWeb.MethodType.UNARY, debug_pb.ListUsersRequest, debug_pb.ListUsersResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.ListUsersResponse.deserializeBinary);
        this.methodDescriptorGetServerRequestedTime = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetServerRequestedTime', grpcWeb.MethodType.UNARY, debug_pb.GetServerRequestedTimeRequest, debug_pb.GetServerRequestedTimeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetServerRequestedTimeResponse.deserializeBinary);
        this.methodDescriptorSaveServerRequestedTime = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/SaveServerRequestedTime', grpcWeb.MethodType.UNARY, debug_pb.SaveServerRequestedTimeRequest, debug_pb.SaveServerRequestedTimeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.SaveServerRequestedTimeResponse.deserializeBinary);
        this.methodDescriptorDeleteServerRequestedTime = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DeleteServerRequestedTime', grpcWeb.MethodType.UNARY, debug_pb.DeleteServerRequestedTimeRequest, debug_pb.DeleteServerRequestedTimeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DeleteServerRequestedTimeResponse.deserializeBinary);
        this.methodDescriptorGetLiverLiveActivities = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetLiverLiveActivities', grpcWeb.MethodType.UNARY, debug_pb.GetLiverLiveActivitiesRequest, debug_pb.GetLiverLiveActivitiesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetLiverLiveActivitiesResponse.deserializeBinary);
        this.methodDescriptorGetListenerLiveActivities = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetListenerLiveActivities', grpcWeb.MethodType.UNARY, debug_pb.GetListenerLiveActivitiesRequest, debug_pb.GetListenerLiveActivitiesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetListenerLiveActivitiesResponse.deserializeBinary);
        this.methodDescriptorUpdateLeague = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateLeague', grpcWeb.MethodType.UNARY, debug_pb.UpdateLeagueRequest, debug_pb.UpdateLeagueResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateLeagueResponse.deserializeBinary);
        this.methodDescriptorAddFavePointsSource = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/AddFavePointsSource', grpcWeb.MethodType.UNARY, debug_pb.AddFavePointsSourceRequest, debug_pb.AddFavePointsSourceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.AddFavePointsSourceResponse.deserializeBinary);
        this.methodDescriptorAddFaveBonusSource = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/AddFaveBonusSource', grpcWeb.MethodType.UNARY, debug_pb.AddFaveBonusSourceRequest, debug_pb.AddFaveBonusSourceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.AddFaveBonusSourceResponse.deserializeBinary);
        this.methodDescriptorGetCoinPurchaseFailureMode = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetCoinPurchaseFailureMode', grpcWeb.MethodType.UNARY, debug_pb.GetCoinPurchaseFailureModeRequest, debug_pb.GetCoinPurchaseFailureModeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetCoinPurchaseFailureModeResponse.deserializeBinary);
        this.methodDescriptorUpdateCoinPurchaseFailureMode = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateCoinPurchaseFailureMode', grpcWeb.MethodType.UNARY, debug_pb.UpdateCoinPurchaseFailureModeRequest, debug_pb.UpdateCoinPurchaseFailureModeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateCoinPurchaseFailureModeResponse.deserializeBinary);
        this.methodDescriptorGetUserDevice = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetUserDevice', grpcWeb.MethodType.UNARY, debug_pb.GetUserDeviceRequest, debug_pb.GetUserDeviceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetUserDeviceResponse.deserializeBinary);
        this.methodDescriptorUpdateUserDevice = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateUserDevice', grpcWeb.MethodType.UNARY, debug_pb.UpdateUserDeviceRequest, debug_pb.UpdateUserDeviceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateUserDeviceResponse.deserializeBinary);
        this.methodDescriptorGetDiamondAcquisitionRight = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetDiamondAcquisitionRight', grpcWeb.MethodType.UNARY, debug_pb.GetDiamondAcquisitionRightRequest, debug_pb.GetDiamondAcquisitionRightResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetDiamondAcquisitionRightResponse.deserializeBinary);
        this.methodDescriptorUpdateDiamondAcquisitionRight = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateDiamondAcquisitionRight', grpcWeb.MethodType.UNARY, debug_pb.UpdateDiamondAcquisitionRightRequest, debug_pb.UpdateDiamondAcquisitionRightResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateDiamondAcquisitionRightResponse.deserializeBinary);
        this.methodDescriptorCreateMultiLives = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/CreateMultiLives', grpcWeb.MethodType.UNARY, debug_pb.CreateMultiLivesRequest, debug_pb.CreateMultiLivesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.CreateMultiLivesResponse.deserializeBinary);
        this.methodDescriptorGetLatestUserDiamondDistributedDay = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetLatestUserDiamondDistributedDay', grpcWeb.MethodType.UNARY, debug_pb.GetLatestUserDiamondDistributedDayRequest, debug_pb.GetLatestUserDiamondDistributedDayResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetLatestUserDiamondDistributedDayResponse.deserializeBinary);
        this.methodDescriptorUpdateLatestUserDiamondDistributedDay = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateLatestUserDiamondDistributedDay', grpcWeb.MethodType.UNARY, debug_pb.UpdateLatestUserDiamondDistributedDayRequest, debug_pb.UpdateLatestUserDiamondDistributedDayResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateLatestUserDiamondDistributedDayResponse.deserializeBinary);
        this.methodDescriptorRunDistributeDiamondCommand = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/RunDistributeDiamondCommand', grpcWeb.MethodType.UNARY, debug_pb.RunDistributeDiamondCommandRequest, debug_pb.RunDistributeDiamondCommandResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.RunDistributeDiamondCommandResponse.deserializeBinary);
        this.methodDescriptorUpdateLeaguePromotionBorderAtClosingTime = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateLeaguePromotionBorderAtClosingTime', grpcWeb.MethodType.UNARY, debug_pb.UpdateLeaguePromotionBorderAtClosingTimeRequest, debug_pb.UpdateLeaguePromotionBorderAtClosingTimeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateLeaguePromotionBorderAtClosingTimeResponse.deserializeBinary);
        this.methodDescriptorRecalculateLiverLeague = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/RecalculateLiverLeague', grpcWeb.MethodType.UNARY, debug_pb.RecalculateLiverLeagueRequest, debug_pb.RecalculateLiverLeagueResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.RecalculateLiverLeagueResponse.deserializeBinary);
        this.methodDescriptorGetDiamondFund = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetDiamondFund', grpcWeb.MethodType.UNARY, debug_pb.GetDiamondFundRequest, debug_pb.GetDiamondFundResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetDiamondFundResponse.deserializeBinary);
        this.methodDescriptorOverwriteDiamondFund = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/OverwriteDiamondFund', grpcWeb.MethodType.UNARY, debug_pb.OverwriteDiamondFundRequest, debug_pb.OverwriteDiamondFundResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.OverwriteDiamondFundResponse.deserializeBinary);
        this.methodDescriptorDeleteDiamondFundOverwrite = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DeleteDiamondFundOverwrite', grpcWeb.MethodType.UNARY, debug_pb.DeleteDiamondFundOverwriteRequest, debug_pb.DeleteDiamondFundOverwriteResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DeleteDiamondFundOverwriteResponse.deserializeBinary);
        this.methodDescriptorDeleteLiverReceivedCoinHistory = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DeleteLiverReceivedCoinHistory', grpcWeb.MethodType.UNARY, debug_pb.DeleteLiverReceivedCoinHistoryRequest, debug_pb.DeleteLiverReceivedCoinHistoryResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DeleteLiverReceivedCoinHistoryResponse.deserializeBinary);
        this.methodDescriptorListSingHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/ListSingHistories', grpcWeb.MethodType.UNARY, debug_pb.ListSingHistoriesRequest, debug_pb.ListSingHistoriesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.ListSingHistoriesResponse.deserializeBinary);
        this.methodDescriptorUpdateSingHistory = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateSingHistory', grpcWeb.MethodType.UNARY, debug_pb.UpdateSingHistoryRequest, debug_pb.UpdateSingHistoryResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateSingHistoryResponse.deserializeBinary);
        this.methodDescriptorDeleteSingHistory = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DeleteSingHistory', grpcWeb.MethodType.UNARY, debug_pb.DeleteSingHistoryRequest, debug_pb.DeleteSingHistoryResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DeleteSingHistoryResponse.deserializeBinary);
        this.methodDescriptorGetUsedSingDiamondAcquisitionRight = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetUsedSingDiamondAcquisitionRight', grpcWeb.MethodType.UNARY, debug_pb.GetUsedSingDiamondAcquisitionRightRequest, debug_pb.GetUsedSingDiamondAcquisitionRightResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetUsedSingDiamondAcquisitionRightResponse.deserializeBinary);
        this.methodDescriptorUpdateUsedSingDiamondAcquisitionRight = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateUsedSingDiamondAcquisitionRight', grpcWeb.MethodType.UNARY, debug_pb.UpdateUsedSingDiamondAcquisitionRightRequest, debug_pb.UpdateUsedSingDiamondAcquisitionRightResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateUsedSingDiamondAcquisitionRightResponse.deserializeBinary);
        this.methodDescriptorGetDiamondBalance = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetDiamondBalance', grpcWeb.MethodType.UNARY, debug_pb.GetDiamondBalanceRequest, debug_pb.GetDiamondBalanceResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetDiamondBalanceResponse.deserializeBinary);
        this.methodDescriptorAddDiamond = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/AddDiamond', grpcWeb.MethodType.UNARY, debug_pb.AddDiamondRequest, debug_pb.AddDiamondResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.AddDiamondResponse.deserializeBinary);
        this.methodDescriptorGetTimelineDummyDataSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetTimelineDummyDataSetting', grpcWeb.MethodType.UNARY, debug_pb.GetTimelineDummyDataSettingRequest, debug_pb.GetTimelineDummyDataSettingResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetTimelineDummyDataSettingResponse.deserializeBinary);
        this.methodDescriptorUpdateTimelineDummyDataSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateTimelineDummyDataSetting', grpcWeb.MethodType.UNARY, debug_pb.UpdateTimelineDummyDataSettingRequest, debug_pb.UpdateTimelineDummyDataSettingResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateTimelineDummyDataSettingResponse.deserializeBinary);
        this.methodDescriptorGetDiamondBalanceInOutHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetDiamondBalanceInOutHistories', grpcWeb.MethodType.UNARY, debug_pb.GetDiamondBalanceInOutHistoriesRequest, debug_pb.GetDiamondBalanceInOutHistoriesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetDiamondBalanceInOutHistoriesResponse.deserializeBinary);
        this.methodDescriptorDebugGetLiverPromotionMeter = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DebugGetLiverPromotionMeter', grpcWeb.MethodType.UNARY, debug_pb.DebugGetLiverPromotionMeterV1Request, debug_pb.DebugGetLiverPromotionMeterV1Response, function (request) {
            return request.serializeBinary();
        }, debug_pb.DebugGetLiverPromotionMeterV1Response.deserializeBinary);
        this.methodDescriptorDeleteUserBanHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DeleteUserBanHistories', grpcWeb.MethodType.UNARY, debug_pb.DeleteUserBanHistoriesRequest, debug_pb.DeleteUserBanHistoriesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DeleteUserBanHistoriesResponse.deserializeBinary);
        this.methodDescriptorGetIgnoreLiveAvailableTime = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetIgnoreLiveAvailableTime', grpcWeb.MethodType.UNARY, debug_pb.GetIgnoreLiveAvailableTimeRequest, debug_pb.GetIgnoreLiveAvailableTimeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetIgnoreLiveAvailableTimeResponse.deserializeBinary);
        this.methodDescriptorUpdateIgnoreLiveAvailableTime = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateIgnoreLiveAvailableTime', grpcWeb.MethodType.UNARY, debug_pb.UpdateIgnoreLiveAvailableTimeRequest, debug_pb.UpdateIgnoreLiveAvailableTimeResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateIgnoreLiveAvailableTimeResponse.deserializeBinary);
        this.methodDescriptorRunUpdateWithdrawalDiamondLedgersLast2Weeks = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/RunUpdateWithdrawalDiamondLedgersLast2Weeks', grpcWeb.MethodType.UNARY, debug_pb.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest, debug_pb.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.deserializeBinary);
        this.methodDescriptorGetForceSuperLikeBonusCoinSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetForceSuperLikeBonusCoinSetting', grpcWeb.MethodType.UNARY, debug_pb.GetForceSuperLikeBonusCoinSettingRequest, debug_pb.GetForceSuperLikeBonusCoinSettingResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetForceSuperLikeBonusCoinSettingResponse.deserializeBinary);
        this.methodDescriptorSaveForceSuperLikeBonusCoinSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/SaveForceSuperLikeBonusCoinSetting', grpcWeb.MethodType.UNARY, debug_pb.SaveForceSuperLikeBonusCoinSettingRequest, debug_pb.SaveForceSuperLikeBonusCoinSettingResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.SaveForceSuperLikeBonusCoinSettingResponse.deserializeBinary);
        this.methodDescriptorSaveForceUseCSS = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/SaveForceUseCSS', grpcWeb.MethodType.UNARY, debug_pb.SaveForceUseCSSRequest, debug_pb.SaveForceUseCSSResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.SaveForceUseCSSResponse.deserializeBinary);
        this.methodDescriptorRunSubtractDiamondCommand = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/RunSubtractDiamondCommand', grpcWeb.MethodType.UNARY, debug_pb.RunSubtractDiamondCommandRequest, debug_pb.RunSubtractDiamondCommandResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.RunSubtractDiamondCommandResponse.deserializeBinary);
        this.methodDescriptorDistributePearlByPearlCupResult = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DistributePearlByPearlCupResult', grpcWeb.MethodType.UNARY, debug_pb.DistributePearlByPearlCupResultV1Request, debug_pb.DistributePearlByPearlCupResultV1Response, function (request) {
            return request.serializeBinary();
        }, debug_pb.DistributePearlByPearlCupResultV1Response.deserializeBinary);
        this.methodDescriptorGetForceCapturePossibility = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetForceCapturePossibility', grpcWeb.MethodType.UNARY, debug_pb.GetForceCapturePossibilityRequest, debug_pb.GetForceCapturePossibilityResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetForceCapturePossibilityResponse.deserializeBinary);
        this.methodDescriptorUpdateForceCapturePossibility = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateForceCapturePossibility', grpcWeb.MethodType.UNARY, debug_pb.UpdateForceCapturePossibilityRequest, debug_pb.UpdateForceCapturePossibilityResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateForceCapturePossibilityResponse.deserializeBinary);
        this.methodDescriptorCreateOnePhraseEventEntries = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/CreateOnePhraseEventEntries', grpcWeb.MethodType.UNARY, debug_pb.CreateOnePhraseEventEntriesRequest, debug_pb.CreateOnePhraseEventEntriesResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.CreateOnePhraseEventEntriesResponse.deserializeBinary);
        this.methodDescriptorUpdateMonthlyFavePoint = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/UpdateMonthlyFavePoint', grpcWeb.MethodType.UNARY, debug_pb.UpdateMonthlyFavePointRequest, debug_pb.UpdateMonthlyFavePointResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.UpdateMonthlyFavePointResponse.deserializeBinary);
        this.methodDescriptorGetLatestLiverMembershipGospelCoinDistributedDay = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/GetLatestLiverMembershipGospelCoinDistributedDay', grpcWeb.MethodType.UNARY, debug_pb.GetLatestLiverMembershipGospelCoinDistributedDayRequest, debug_pb.GetLatestLiverMembershipGospelCoinDistributedDayResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.GetLatestLiverMembershipGospelCoinDistributedDayResponse.deserializeBinary);
        this.methodDescriptorRunDistributeMembershipGospelCoinCommand = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/RunDistributeMembershipGospelCoinCommand', grpcWeb.MethodType.UNARY, debug_pb.RunDistributeMembershipGospelCoinCommandRequest, debug_pb.RunDistributeMembershipGospelCoinCommandResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.RunDistributeMembershipGospelCoinCommandResponse.deserializeBinary);
        this.methodDescriptorRunDistributeMembershipSpecificLiverCoinCommand = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/RunDistributeMembershipSpecificLiverCoinCommand', grpcWeb.MethodType.UNARY, debug_pb.RunDistributeMembershipSpecificLiverCoinCommandRequest, debug_pb.RunDistributeMembershipSpecificLiverCoinCommandResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.RunDistributeMembershipSpecificLiverCoinCommandResponse.deserializeBinary);
        this.methodDescriptorDebugStartLive = new grpcWeb.MethodDescriptor('/jp.singcolor.Debug/DebugStartLive', grpcWeb.MethodType.UNARY, debug_pb.DebugStartLiveRequest, debug_pb.DebugStartLiveResponse, function (request) {
            return request.serializeBinary();
        }, debug_pb.DebugStartLiveResponse.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    DebugClient.prototype.createToken = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/CreateToken', request, metadata || {}, this.methodDescriptorCreateToken, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/CreateToken', request, metadata || {}, this.methodDescriptorCreateToken);
    };
    DebugClient.prototype.getCoinBalance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetCoinBalance', request, metadata || {}, this.methodDescriptorGetCoinBalance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetCoinBalance', request, metadata || {}, this.methodDescriptorGetCoinBalance);
    };
    DebugClient.prototype.addCoin = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/AddCoin', request, metadata || {}, this.methodDescriptorAddCoin, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/AddCoin', request, metadata || {}, this.methodDescriptorAddCoin);
    };
    DebugClient.prototype.createUsers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/CreateUsers', request, metadata || {}, this.methodDescriptorCreateUsers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/CreateUsers', request, metadata || {}, this.methodDescriptorCreateUsers);
    };
    DebugClient.prototype.deleteDevice = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DeleteDevice', request, metadata || {}, this.methodDescriptorDeleteDevice, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DeleteDevice', request, metadata || {}, this.methodDescriptorDeleteDevice);
    };
    DebugClient.prototype.listUsers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/ListUsers', request, metadata || {}, this.methodDescriptorListUsers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/ListUsers', request, metadata || {}, this.methodDescriptorListUsers);
    };
    DebugClient.prototype.getServerRequestedTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetServerRequestedTime', request, metadata || {}, this.methodDescriptorGetServerRequestedTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetServerRequestedTime', request, metadata || {}, this.methodDescriptorGetServerRequestedTime);
    };
    DebugClient.prototype.saveServerRequestedTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/SaveServerRequestedTime', request, metadata || {}, this.methodDescriptorSaveServerRequestedTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/SaveServerRequestedTime', request, metadata || {}, this.methodDescriptorSaveServerRequestedTime);
    };
    DebugClient.prototype.deleteServerRequestedTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DeleteServerRequestedTime', request, metadata || {}, this.methodDescriptorDeleteServerRequestedTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DeleteServerRequestedTime', request, metadata || {}, this.methodDescriptorDeleteServerRequestedTime);
    };
    DebugClient.prototype.getLiverLiveActivities = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetLiverLiveActivities', request, metadata || {}, this.methodDescriptorGetLiverLiveActivities, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetLiverLiveActivities', request, metadata || {}, this.methodDescriptorGetLiverLiveActivities);
    };
    DebugClient.prototype.getListenerLiveActivities = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetListenerLiveActivities', request, metadata || {}, this.methodDescriptorGetListenerLiveActivities, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetListenerLiveActivities', request, metadata || {}, this.methodDescriptorGetListenerLiveActivities);
    };
    DebugClient.prototype.updateLeague = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateLeague', request, metadata || {}, this.methodDescriptorUpdateLeague, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateLeague', request, metadata || {}, this.methodDescriptorUpdateLeague);
    };
    DebugClient.prototype.addFavePointsSource = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/AddFavePointsSource', request, metadata || {}, this.methodDescriptorAddFavePointsSource, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/AddFavePointsSource', request, metadata || {}, this.methodDescriptorAddFavePointsSource);
    };
    DebugClient.prototype.addFaveBonusSource = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/AddFaveBonusSource', request, metadata || {}, this.methodDescriptorAddFaveBonusSource, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/AddFaveBonusSource', request, metadata || {}, this.methodDescriptorAddFaveBonusSource);
    };
    DebugClient.prototype.getCoinPurchaseFailureMode = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetCoinPurchaseFailureMode', request, metadata || {}, this.methodDescriptorGetCoinPurchaseFailureMode, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetCoinPurchaseFailureMode', request, metadata || {}, this.methodDescriptorGetCoinPurchaseFailureMode);
    };
    DebugClient.prototype.updateCoinPurchaseFailureMode = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateCoinPurchaseFailureMode', request, metadata || {}, this.methodDescriptorUpdateCoinPurchaseFailureMode, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateCoinPurchaseFailureMode', request, metadata || {}, this.methodDescriptorUpdateCoinPurchaseFailureMode);
    };
    DebugClient.prototype.getUserDevice = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetUserDevice', request, metadata || {}, this.methodDescriptorGetUserDevice, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetUserDevice', request, metadata || {}, this.methodDescriptorGetUserDevice);
    };
    DebugClient.prototype.updateUserDevice = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateUserDevice', request, metadata || {}, this.methodDescriptorUpdateUserDevice, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateUserDevice', request, metadata || {}, this.methodDescriptorUpdateUserDevice);
    };
    DebugClient.prototype.getDiamondAcquisitionRight = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorGetDiamondAcquisitionRight, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorGetDiamondAcquisitionRight);
    };
    DebugClient.prototype.updateDiamondAcquisitionRight = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorUpdateDiamondAcquisitionRight, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorUpdateDiamondAcquisitionRight);
    };
    DebugClient.prototype.createMultiLives = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/CreateMultiLives', request, metadata || {}, this.methodDescriptorCreateMultiLives, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/CreateMultiLives', request, metadata || {}, this.methodDescriptorCreateMultiLives);
    };
    DebugClient.prototype.getLatestUserDiamondDistributedDay = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetLatestUserDiamondDistributedDay', request, metadata || {}, this.methodDescriptorGetLatestUserDiamondDistributedDay, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetLatestUserDiamondDistributedDay', request, metadata || {}, this.methodDescriptorGetLatestUserDiamondDistributedDay);
    };
    DebugClient.prototype.updateLatestUserDiamondDistributedDay = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateLatestUserDiamondDistributedDay', request, metadata || {}, this.methodDescriptorUpdateLatestUserDiamondDistributedDay, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateLatestUserDiamondDistributedDay', request, metadata || {}, this.methodDescriptorUpdateLatestUserDiamondDistributedDay);
    };
    DebugClient.prototype.runDistributeDiamondCommand = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/RunDistributeDiamondCommand', request, metadata || {}, this.methodDescriptorRunDistributeDiamondCommand, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/RunDistributeDiamondCommand', request, metadata || {}, this.methodDescriptorRunDistributeDiamondCommand);
    };
    DebugClient.prototype.updateLeaguePromotionBorderAtClosingTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateLeaguePromotionBorderAtClosingTime', request, metadata || {}, this.methodDescriptorUpdateLeaguePromotionBorderAtClosingTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateLeaguePromotionBorderAtClosingTime', request, metadata || {}, this.methodDescriptorUpdateLeaguePromotionBorderAtClosingTime);
    };
    DebugClient.prototype.recalculateLiverLeague = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/RecalculateLiverLeague', request, metadata || {}, this.methodDescriptorRecalculateLiverLeague, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/RecalculateLiverLeague', request, metadata || {}, this.methodDescriptorRecalculateLiverLeague);
    };
    DebugClient.prototype.getDiamondFund = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetDiamondFund', request, metadata || {}, this.methodDescriptorGetDiamondFund, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetDiamondFund', request, metadata || {}, this.methodDescriptorGetDiamondFund);
    };
    DebugClient.prototype.overwriteDiamondFund = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/OverwriteDiamondFund', request, metadata || {}, this.methodDescriptorOverwriteDiamondFund, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/OverwriteDiamondFund', request, metadata || {}, this.methodDescriptorOverwriteDiamondFund);
    };
    DebugClient.prototype.deleteDiamondFundOverwrite = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DeleteDiamondFundOverwrite', request, metadata || {}, this.methodDescriptorDeleteDiamondFundOverwrite, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DeleteDiamondFundOverwrite', request, metadata || {}, this.methodDescriptorDeleteDiamondFundOverwrite);
    };
    DebugClient.prototype.deleteLiverReceivedCoinHistory = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DeleteLiverReceivedCoinHistory', request, metadata || {}, this.methodDescriptorDeleteLiverReceivedCoinHistory, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DeleteLiverReceivedCoinHistory', request, metadata || {}, this.methodDescriptorDeleteLiverReceivedCoinHistory);
    };
    DebugClient.prototype.listSingHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/ListSingHistories', request, metadata || {}, this.methodDescriptorListSingHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/ListSingHistories', request, metadata || {}, this.methodDescriptorListSingHistories);
    };
    DebugClient.prototype.updateSingHistory = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateSingHistory', request, metadata || {}, this.methodDescriptorUpdateSingHistory, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateSingHistory', request, metadata || {}, this.methodDescriptorUpdateSingHistory);
    };
    DebugClient.prototype.deleteSingHistory = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DeleteSingHistory', request, metadata || {}, this.methodDescriptorDeleteSingHistory, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DeleteSingHistory', request, metadata || {}, this.methodDescriptorDeleteSingHistory);
    };
    DebugClient.prototype.getUsedSingDiamondAcquisitionRight = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetUsedSingDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorGetUsedSingDiamondAcquisitionRight, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetUsedSingDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorGetUsedSingDiamondAcquisitionRight);
    };
    DebugClient.prototype.updateUsedSingDiamondAcquisitionRight = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateUsedSingDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorUpdateUsedSingDiamondAcquisitionRight, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateUsedSingDiamondAcquisitionRight', request, metadata || {}, this.methodDescriptorUpdateUsedSingDiamondAcquisitionRight);
    };
    DebugClient.prototype.getDiamondBalance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetDiamondBalance', request, metadata || {}, this.methodDescriptorGetDiamondBalance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetDiamondBalance', request, metadata || {}, this.methodDescriptorGetDiamondBalance);
    };
    DebugClient.prototype.addDiamond = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/AddDiamond', request, metadata || {}, this.methodDescriptorAddDiamond, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/AddDiamond', request, metadata || {}, this.methodDescriptorAddDiamond);
    };
    DebugClient.prototype.getTimelineDummyDataSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetTimelineDummyDataSetting', request, metadata || {}, this.methodDescriptorGetTimelineDummyDataSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetTimelineDummyDataSetting', request, metadata || {}, this.methodDescriptorGetTimelineDummyDataSetting);
    };
    DebugClient.prototype.updateTimelineDummyDataSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateTimelineDummyDataSetting', request, metadata || {}, this.methodDescriptorUpdateTimelineDummyDataSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateTimelineDummyDataSetting', request, metadata || {}, this.methodDescriptorUpdateTimelineDummyDataSetting);
    };
    DebugClient.prototype.getDiamondBalanceInOutHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetDiamondBalanceInOutHistories', request, metadata || {}, this.methodDescriptorGetDiamondBalanceInOutHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetDiamondBalanceInOutHistories', request, metadata || {}, this.methodDescriptorGetDiamondBalanceInOutHistories);
    };
    DebugClient.prototype.debugGetLiverPromotionMeter = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DebugGetLiverPromotionMeter', request, metadata || {}, this.methodDescriptorDebugGetLiverPromotionMeter, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DebugGetLiverPromotionMeter', request, metadata || {}, this.methodDescriptorDebugGetLiverPromotionMeter);
    };
    DebugClient.prototype.deleteUserBanHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DeleteUserBanHistories', request, metadata || {}, this.methodDescriptorDeleteUserBanHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DeleteUserBanHistories', request, metadata || {}, this.methodDescriptorDeleteUserBanHistories);
    };
    DebugClient.prototype.getIgnoreLiveAvailableTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetIgnoreLiveAvailableTime', request, metadata || {}, this.methodDescriptorGetIgnoreLiveAvailableTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetIgnoreLiveAvailableTime', request, metadata || {}, this.methodDescriptorGetIgnoreLiveAvailableTime);
    };
    DebugClient.prototype.updateIgnoreLiveAvailableTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateIgnoreLiveAvailableTime', request, metadata || {}, this.methodDescriptorUpdateIgnoreLiveAvailableTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateIgnoreLiveAvailableTime', request, metadata || {}, this.methodDescriptorUpdateIgnoreLiveAvailableTime);
    };
    DebugClient.prototype.runUpdateWithdrawalDiamondLedgersLast2Weeks = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/RunUpdateWithdrawalDiamondLedgersLast2Weeks', request, metadata || {}, this.methodDescriptorRunUpdateWithdrawalDiamondLedgersLast2Weeks, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/RunUpdateWithdrawalDiamondLedgersLast2Weeks', request, metadata || {}, this.methodDescriptorRunUpdateWithdrawalDiamondLedgersLast2Weeks);
    };
    DebugClient.prototype.getForceSuperLikeBonusCoinSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetForceSuperLikeBonusCoinSetting', request, metadata || {}, this.methodDescriptorGetForceSuperLikeBonusCoinSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetForceSuperLikeBonusCoinSetting', request, metadata || {}, this.methodDescriptorGetForceSuperLikeBonusCoinSetting);
    };
    DebugClient.prototype.saveForceSuperLikeBonusCoinSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/SaveForceSuperLikeBonusCoinSetting', request, metadata || {}, this.methodDescriptorSaveForceSuperLikeBonusCoinSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/SaveForceSuperLikeBonusCoinSetting', request, metadata || {}, this.methodDescriptorSaveForceSuperLikeBonusCoinSetting);
    };
    DebugClient.prototype.saveForceUseCSS = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/SaveForceUseCSS', request, metadata || {}, this.methodDescriptorSaveForceUseCSS, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/SaveForceUseCSS', request, metadata || {}, this.methodDescriptorSaveForceUseCSS);
    };
    DebugClient.prototype.runSubtractDiamondCommand = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/RunSubtractDiamondCommand', request, metadata || {}, this.methodDescriptorRunSubtractDiamondCommand, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/RunSubtractDiamondCommand', request, metadata || {}, this.methodDescriptorRunSubtractDiamondCommand);
    };
    DebugClient.prototype.distributePearlByPearlCupResult = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DistributePearlByPearlCupResult', request, metadata || {}, this.methodDescriptorDistributePearlByPearlCupResult, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DistributePearlByPearlCupResult', request, metadata || {}, this.methodDescriptorDistributePearlByPearlCupResult);
    };
    DebugClient.prototype.getForceCapturePossibility = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetForceCapturePossibility', request, metadata || {}, this.methodDescriptorGetForceCapturePossibility, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetForceCapturePossibility', request, metadata || {}, this.methodDescriptorGetForceCapturePossibility);
    };
    DebugClient.prototype.updateForceCapturePossibility = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateForceCapturePossibility', request, metadata || {}, this.methodDescriptorUpdateForceCapturePossibility, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateForceCapturePossibility', request, metadata || {}, this.methodDescriptorUpdateForceCapturePossibility);
    };
    DebugClient.prototype.createOnePhraseEventEntries = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/CreateOnePhraseEventEntries', request, metadata || {}, this.methodDescriptorCreateOnePhraseEventEntries, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/CreateOnePhraseEventEntries', request, metadata || {}, this.methodDescriptorCreateOnePhraseEventEntries);
    };
    DebugClient.prototype.updateMonthlyFavePoint = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/UpdateMonthlyFavePoint', request, metadata || {}, this.methodDescriptorUpdateMonthlyFavePoint, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/UpdateMonthlyFavePoint', request, metadata || {}, this.methodDescriptorUpdateMonthlyFavePoint);
    };
    DebugClient.prototype.getLatestLiverMembershipGospelCoinDistributedDay = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/GetLatestLiverMembershipGospelCoinDistributedDay', request, metadata || {}, this.methodDescriptorGetLatestLiverMembershipGospelCoinDistributedDay, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/GetLatestLiverMembershipGospelCoinDistributedDay', request, metadata || {}, this.methodDescriptorGetLatestLiverMembershipGospelCoinDistributedDay);
    };
    DebugClient.prototype.runDistributeMembershipGospelCoinCommand = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/RunDistributeMembershipGospelCoinCommand', request, metadata || {}, this.methodDescriptorRunDistributeMembershipGospelCoinCommand, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/RunDistributeMembershipGospelCoinCommand', request, metadata || {}, this.methodDescriptorRunDistributeMembershipGospelCoinCommand);
    };
    DebugClient.prototype.runDistributeMembershipSpecificLiverCoinCommand = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/RunDistributeMembershipSpecificLiverCoinCommand', request, metadata || {}, this.methodDescriptorRunDistributeMembershipSpecificLiverCoinCommand, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/RunDistributeMembershipSpecificLiverCoinCommand', request, metadata || {}, this.methodDescriptorRunDistributeMembershipSpecificLiverCoinCommand);
    };
    DebugClient.prototype.debugStartLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.Debug/DebugStartLive', request, metadata || {}, this.methodDescriptorDebugStartLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.Debug/DebugStartLive', request, metadata || {}, this.methodDescriptorDebugStartLive);
    };
    return DebugClient;
}());
export { DebugClient };
