"use strict";
// source: coins_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.AvailableMembershipPlan', null, global);
goog.exportSymbol('proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.VerifyStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.CompletePurchaseV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CompletePurchaseV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPurchasableCoinPlansV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPurchasableCoinPlansV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.JoinMembershipV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.JoinMembershipV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListJoinableMembershipLiversV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListJoinableMembershipLiversV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipListenersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipListenersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipPlansForJoinV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMembershipPlansForJoinV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWillJoinMembershipListenersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWillJoinMembershipListenersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListenerMembershipPlan', null, global);
goog.exportSymbol('proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan', null, global);
goog.exportSymbol('proto.jp.singcolor.MembershipPlan', null, global);
goog.exportSymbol('proto.jp.singcolor.PurchaseWebCoinV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PurchaseWebCoinV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMembershipQuantityV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMembershipQuantityV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetPurchasableCoinPlansV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPurchasableCoinPlansV1Request.displayName = 'proto.jp.singcolor.GetPurchasableCoinPlansV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetPurchasableCoinPlansV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetPurchasableCoinPlansV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPurchasableCoinPlansV1Response.displayName = 'proto.jp.singcolor.GetPurchasableCoinPlansV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.displayName = 'proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.displayName = 'proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.displayName = 'proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.displayName = 'proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CompletePurchaseV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CompletePurchaseV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CompletePurchaseV1Request.displayName = 'proto.jp.singcolor.CompletePurchaseV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CompletePurchaseV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CompletePurchaseV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CompletePurchaseV1Response.displayName = 'proto.jp.singcolor.CompletePurchaseV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PurchaseWebCoinV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PurchaseWebCoinV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PurchaseWebCoinV1Request.displayName = 'proto.jp.singcolor.PurchaseWebCoinV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PurchaseWebCoinV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PurchaseWebCoinV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PurchaseWebCoinV1Response.displayName = 'proto.jp.singcolor.PurchaseWebCoinV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.displayName = 'proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.displayName = 'proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListJoinableMembershipLiversV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListJoinableMembershipLiversV1Request.displayName = 'proto.jp.singcolor.ListJoinableMembershipLiversV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListJoinableMembershipLiversV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListJoinableMembershipLiversV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListJoinableMembershipLiversV1Response.displayName = 'proto.jp.singcolor.ListJoinableMembershipLiversV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.JoinMembershipV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.JoinMembershipV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.JoinMembershipV1Request.displayName = 'proto.jp.singcolor.JoinMembershipV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.JoinMembershipV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.JoinMembershipV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.JoinMembershipV1Response.displayName = 'proto.jp.singcolor.JoinMembershipV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMembershipQuantityV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMembershipQuantityV1Request.displayName = 'proto.jp.singcolor.UpdateMembershipQuantityV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMembershipQuantityV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMembershipQuantityV1Response.displayName = 'proto.jp.singcolor.UpdateMembershipQuantityV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MembershipPlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.MembershipPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MembershipPlan.displayName = 'proto.jp.singcolor.MembershipPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AvailableMembershipPlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AvailableMembershipPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AvailableMembershipPlan.displayName = 'proto.jp.singcolor.AvailableMembershipPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipPlansForJoinV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipPlansForJoinV1Request.displayName = 'proto.jp.singcolor.ListMembershipPlansForJoinV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListMembershipPlansForJoinV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipPlansForJoinV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipPlansForJoinV1Response.displayName = 'proto.jp.singcolor.ListMembershipPlansForJoinV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.displayName = 'proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.displayName = 'proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.displayName = 'proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.displayName = 'proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListenerMembershipPlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListenerMembershipPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListenerMembershipPlan.displayName = 'proto.jp.singcolor.ListenerMembershipPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.displayName = 'proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.displayName = 'proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.displayName = 'proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipListenersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipListenersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipListenersV1Request.displayName = 'proto.jp.singcolor.ListMembershipListenersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMembershipListenersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListMembershipListenersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListMembershipListenersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMembershipListenersV1Response.displayName = 'proto.jp.singcolor.ListMembershipListenersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListWillJoinMembershipListenersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.displayName = 'proto.jp.singcolor.ListWillJoinMembershipListenersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListWillJoinMembershipListenersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.displayName = 'proto.jp.singcolor.ListWillJoinMembershipListenersV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPurchasableCoinPlansV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPurchasableCoinPlansV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPurchasableCoinPlansV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPurchasableCoinPlansV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Request}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPurchasableCoinPlansV1Request;
    return proto.jp.singcolor.GetPurchasableCoinPlansV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPurchasableCoinPlansV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Request}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPurchasableCoinPlansV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPurchasableCoinPlansV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPurchasableCoinPlansV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPurchasableCoinPlansV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinPurchasePlansList: jspb.Message.toObjectList(msg.getCoinPurchasePlansList(), coins_entity_pb.CoinPurchasePlan.toObject, includeInstance),
            isOverpriced: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPurchasableCoinPlansV1Response;
    return proto.jp.singcolor.GetPurchasableCoinPlansV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinPurchasePlan;
                reader.readMessage(value, coins_entity_pb.CoinPurchasePlan.deserializeBinaryFromReader);
                msg.addCoinPurchasePlans(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsOverpriced(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPurchasableCoinPlansV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinPurchasePlansList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, coins_entity_pb.CoinPurchasePlan.serializeBinaryToWriter);
    }
    f = message.getIsOverpriced();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * repeated CoinPurchasePlan coin_purchase_plans = 1;
 * @return {!Array<!proto.CoinPurchasePlan>}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.getCoinPurchasePlansList = function () {
    return /** @type{!Array<!proto.CoinPurchasePlan>} */ (jspb.Message.getRepeatedWrapperField(this, coins_entity_pb.CoinPurchasePlan, 1));
};
/**
 * @param {!Array<!proto.CoinPurchasePlan>} value
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response} returns this
*/
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.setCoinPurchasePlansList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.CoinPurchasePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CoinPurchasePlan}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.addCoinPurchasePlans = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CoinPurchasePlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response} returns this
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.clearCoinPurchasePlansList = function () {
    return this.setCoinPurchasePlansList([]);
};
/**
 * optional bool is_overpriced = 2;
 * @return {boolean}
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.getIsOverpriced = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetPurchasableCoinPlansV1Response} returns this
 */
proto.jp.singcolor.GetPurchasableCoinPlansV1Response.prototype.setIsOverpriced = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request;
    return proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            webCoinPurchasePlansList: jspb.Message.toObjectList(msg.getWebCoinPurchasePlansList(), coins_entity_pb.WebCoinPurchasePlan.toObject, includeInstance),
            coinEarningRate: (f = msg.getCoinEarningRate()) && coins_entity_pb.CoinEarningRate.toObject(includeInstance, f),
            isLineConnected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            enabledOverpricedCoin: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response;
    return proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.WebCoinPurchasePlan;
                reader.readMessage(value, coins_entity_pb.WebCoinPurchasePlan.deserializeBinaryFromReader);
                msg.addWebCoinPurchasePlans(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinEarningRate;
                reader.readMessage(value, coins_entity_pb.CoinEarningRate.deserializeBinaryFromReader);
                msg.setCoinEarningRate(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsLineConnected(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setEnabledOverpricedCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWebCoinPurchasePlansList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, coins_entity_pb.WebCoinPurchasePlan.serializeBinaryToWriter);
    }
    f = message.getCoinEarningRate();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinEarningRate.serializeBinaryToWriter);
    }
    f = message.getIsLineConnected();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getEnabledOverpricedCoin();
    if (f) {
        writer.writeBool(4, f);
    }
};
/**
 * repeated WebCoinPurchasePlan web_coin_purchase_plans = 1;
 * @return {!Array<!proto.WebCoinPurchasePlan>}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.getWebCoinPurchasePlansList = function () {
    return /** @type{!Array<!proto.WebCoinPurchasePlan>} */ (jspb.Message.getRepeatedWrapperField(this, coins_entity_pb.WebCoinPurchasePlan, 1));
};
/**
 * @param {!Array<!proto.WebCoinPurchasePlan>} value
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} returns this
*/
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.setWebCoinPurchasePlansList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.WebCoinPurchasePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.WebCoinPurchasePlan}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.addWebCoinPurchasePlans = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.WebCoinPurchasePlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} returns this
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.clearWebCoinPurchasePlansList = function () {
    return this.setWebCoinPurchasePlansList([]);
};
/**
 * optional CoinEarningRate coin_earning_rate = 2;
 * @return {?proto.CoinEarningRate}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.getCoinEarningRate = function () {
    return /** @type{?proto.CoinEarningRate} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinEarningRate, 2));
};
/**
 * @param {?proto.CoinEarningRate|undefined} value
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} returns this
*/
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.setCoinEarningRate = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} returns this
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.clearCoinEarningRate = function () {
    return this.setCoinEarningRate(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.hasCoinEarningRate = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional bool is_line_connected = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.getIsLineConnected = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} returns this
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.setIsLineConnected = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional bool enabled_overpriced_coin = 4;
 * @return {boolean}
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.getEnabledOverpricedCoin = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response} returns this
 */
proto.jp.singcolor.GetPurchasableWebCoinPlansV1Response.prototype.setEnabledOverpricedCoin = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            receipt: jspb.Message.getFieldWithDefault(msg, 1, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request;
    return proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setReceipt(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getReceipt();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string receipt = 1;
 * @return {string}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.prototype.getReceipt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request} returns this
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.prototype.setReceipt = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string signature = 2;
 * @return {string}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request} returns this
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Request.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response;
    return proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.VerifyStatus} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setErrorMessage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getErrorMessage();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.VerifyStatus = {
    VERIFY_STATUS_UNKNOWN: 0,
    VERIFY_STATUS_RECEIPT_INVALID: 1,
    VERIFY_STATUS_PRODUCT_INVALID: 2,
    VERIFY_STATUS_VALID: 3
};
/**
 * optional VerifyStatus status = 1;
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.VerifyStatus}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.prototype.getStatus = function () {
    return /** @type {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.VerifyStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.VerifyStatus} value
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response} returns this
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.prototype.setStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.prototype.getErrorMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response} returns this
 */
proto.jp.singcolor.BeginPurchaseAndVerifyReceiptV1Response.prototype.setErrorMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CompletePurchaseV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CompletePurchaseV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CompletePurchaseV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CompletePurchaseV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            transactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CompletePurchaseV1Request}
 */
proto.jp.singcolor.CompletePurchaseV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CompletePurchaseV1Request;
    return proto.jp.singcolor.CompletePurchaseV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CompletePurchaseV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CompletePurchaseV1Request}
 */
proto.jp.singcolor.CompletePurchaseV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTransactionId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CompletePurchaseV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CompletePurchaseV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CompletePurchaseV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CompletePurchaseV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTransactionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CompletePurchaseV1Request.prototype.getTransactionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CompletePurchaseV1Request} returns this
 */
proto.jp.singcolor.CompletePurchaseV1Request.prototype.setTransactionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.CompletePurchaseV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CompletePurchaseV1Request} returns this
 */
proto.jp.singcolor.CompletePurchaseV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CompletePurchaseV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CompletePurchaseV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CompletePurchaseV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CompletePurchaseV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            specificLiverCoin: (f = msg.getSpecificLiverCoin()) && coins_entity_pb.SpecificLiverCoin.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CompletePurchaseV1Response}
 */
proto.jp.singcolor.CompletePurchaseV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CompletePurchaseV1Response;
    return proto.jp.singcolor.CompletePurchaseV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CompletePurchaseV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CompletePurchaseV1Response}
 */
proto.jp.singcolor.CompletePurchaseV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 2:
                var value = new coins_entity_pb.SpecificLiverCoin;
                reader.readMessage(value, coins_entity_pb.SpecificLiverCoin.deserializeBinaryFromReader);
                msg.setSpecificLiverCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CompletePurchaseV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CompletePurchaseV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CompletePurchaseV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getSpecificLiverCoin();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.SpecificLiverCoin.serializeBinaryToWriter);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.CoinBalance}
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.CompletePurchaseV1Response} returns this
*/
proto.jp.singcolor.CompletePurchaseV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CompletePurchaseV1Response} returns this
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional SpecificLiverCoin specific_liver_coin = 2;
 * @return {?proto.SpecificLiverCoin}
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.getSpecificLiverCoin = function () {
    return /** @type{?proto.SpecificLiverCoin} */ (jspb.Message.getWrapperField(this, coins_entity_pb.SpecificLiverCoin, 2));
};
/**
 * @param {?proto.SpecificLiverCoin|undefined} value
 * @return {!proto.jp.singcolor.CompletePurchaseV1Response} returns this
*/
proto.jp.singcolor.CompletePurchaseV1Response.prototype.setSpecificLiverCoin = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CompletePurchaseV1Response} returns this
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.clearSpecificLiverCoin = function () {
    return this.setSpecificLiverCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CompletePurchaseV1Response.prototype.hasSpecificLiverCoin = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PurchaseWebCoinV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PurchaseWebCoinV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PurchaseWebCoinV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            webCoinPurchasePlanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
            successUrlPath: jspb.Message.getFieldWithDefault(msg, 3, ""),
            cancelUrlPath: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Request}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PurchaseWebCoinV1Request;
    return proto.jp.singcolor.PurchaseWebCoinV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PurchaseWebCoinV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Request}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setWebCoinPurchasePlanId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setQuantity(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setSuccessUrlPath(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCancelUrlPath(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PurchaseWebCoinV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PurchaseWebCoinV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWebCoinPurchasePlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getQuantity();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getSuccessUrlPath();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCancelUrlPath();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional string web_coin_purchase_plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.getWebCoinPurchasePlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Request} returns this
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.setWebCoinPurchasePlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.getQuantity = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Request} returns this
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.setQuantity = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string success_url_path = 3;
 * @return {string}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.getSuccessUrlPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Request} returns this
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.setSuccessUrlPath = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string cancel_url_path = 4;
 * @return {string}
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.getCancelUrlPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Request} returns this
 */
proto.jp.singcolor.PurchaseWebCoinV1Request.prototype.setCancelUrlPath = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PurchaseWebCoinV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PurchaseWebCoinV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PurchaseWebCoinV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PurchaseWebCoinV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Response}
 */
proto.jp.singcolor.PurchaseWebCoinV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PurchaseWebCoinV1Response;
    return proto.jp.singcolor.PurchaseWebCoinV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PurchaseWebCoinV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Response}
 */
proto.jp.singcolor.PurchaseWebCoinV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PurchaseWebCoinV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PurchaseWebCoinV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PurchaseWebCoinV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PurchaseWebCoinV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRedirectUrl();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.jp.singcolor.PurchaseWebCoinV1Response.prototype.getRedirectUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PurchaseWebCoinV1Response} returns this
 */
proto.jp.singcolor.PurchaseWebCoinV1Response.prototype.setRedirectUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request;
    return proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            stripeCustomerPortalUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response;
    return proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setStripeCustomerPortalUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStripeCustomerPortalUrl();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string stripe_customer_portal_url = 1;
 * @return {string}
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.prototype.getStripeCustomerPortalUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response} returns this
 */
proto.jp.singcolor.GetStripeCustomerPortalUrlV1Response.prototype.setStripeCustomerPortalUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListJoinableMembershipLiversV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListJoinableMembershipLiversV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListJoinableMembershipLiversV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListJoinableMembershipLiversV1Request;
    return proto.jp.singcolor.ListJoinableMembershipLiversV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListJoinableMembershipLiversV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Request} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListJoinableMembershipLiversV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListJoinableMembershipLiversV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liversList: jspb.Message.toObjectList(msg.getLiversList(), users_entity_pb.UserItem.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListJoinableMembershipLiversV1Response;
    return proto.jp.singcolor.ListJoinableMembershipLiversV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.addLivers(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListJoinableMembershipLiversV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated UserItem livers = 1;
 * @return {!Array<!proto.UserItem>}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.getLiversList = function () {
    return /** @type{!Array<!proto.UserItem>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {!Array<!proto.UserItem>} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
*/
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.setLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.UserItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UserItem}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.addLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.UserItem, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.clearLiversList = function () {
    return this.setLiversList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListJoinableMembershipLiversV1Response} returns this
 */
proto.jp.singcolor.ListJoinableMembershipLiversV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.JoinMembershipV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.JoinMembershipV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.JoinMembershipV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.JoinMembershipV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            membershipType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
            successUrlPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
            cancelUrlPath: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.JoinMembershipV1Request}
 */
proto.jp.singcolor.JoinMembershipV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.JoinMembershipV1Request;
    return proto.jp.singcolor.JoinMembershipV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.JoinMembershipV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.JoinMembershipV1Request}
 */
proto.jp.singcolor.JoinMembershipV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.MembershipType} */ (reader.readEnum());
                msg.setMembershipType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setQuantity(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setSuccessUrlPath(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setCancelUrlPath(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.JoinMembershipV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.JoinMembershipV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.JoinMembershipV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembershipType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getQuantity();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getSuccessUrlPath();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getCancelUrlPath();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional MembershipType membership_type = 1;
 * @return {!proto.MembershipType}
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.getMembershipType = function () {
    return /** @type {!proto.MembershipType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.MembershipType} value
 * @return {!proto.jp.singcolor.JoinMembershipV1Request} returns this
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.setMembershipType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.JoinMembershipV1Request} returns this
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 quantity = 3;
 * @return {number}
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.getQuantity = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.JoinMembershipV1Request} returns this
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.setQuantity = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string success_url_path = 4;
 * @return {string}
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.getSuccessUrlPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.JoinMembershipV1Request} returns this
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.setSuccessUrlPath = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string cancel_url_path = 5;
 * @return {string}
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.getCancelUrlPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.JoinMembershipV1Request} returns this
 */
proto.jp.singcolor.JoinMembershipV1Request.prototype.setCancelUrlPath = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.JoinMembershipV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.JoinMembershipV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.JoinMembershipV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.JoinMembershipV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            paymentUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.JoinMembershipV1Response}
 */
proto.jp.singcolor.JoinMembershipV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.JoinMembershipV1Response;
    return proto.jp.singcolor.JoinMembershipV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.JoinMembershipV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.JoinMembershipV1Response}
 */
proto.jp.singcolor.JoinMembershipV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPaymentUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.JoinMembershipV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.JoinMembershipV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.JoinMembershipV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.JoinMembershipV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPaymentUrl();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string payment_url = 1;
 * @return {string}
 */
proto.jp.singcolor.JoinMembershipV1Response.prototype.getPaymentUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.JoinMembershipV1Response} returns this
 */
proto.jp.singcolor.JoinMembershipV1Response.prototype.setPaymentUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMembershipQuantityV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMembershipQuantityV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            membershipType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
            successUrlPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
            cancelUrlPath: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMembershipQuantityV1Request;
    return proto.jp.singcolor.UpdateMembershipQuantityV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.MembershipType} */ (reader.readEnum());
                msg.setMembershipType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setQuantity(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setSuccessUrlPath(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setCancelUrlPath(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMembershipQuantityV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembershipType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getQuantity();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getSuccessUrlPath();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getCancelUrlPath();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional MembershipType membership_type = 1;
 * @return {!proto.MembershipType}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.getMembershipType = function () {
    return /** @type {!proto.MembershipType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.MembershipType} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.setMembershipType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 quantity = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.getQuantity = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.setQuantity = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string success_url_path = 4;
 * @return {string}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.getSuccessUrlPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.setSuccessUrlPath = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string cancel_url_path = 5;
 * @return {string}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.getCancelUrlPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Request} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Request.prototype.setCancelUrlPath = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMembershipQuantityV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMembershipQuantityV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMembershipQuantityV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMembershipQuantityV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            paymentRequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            paymentUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Response}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMembershipQuantityV1Response;
    return proto.jp.singcolor.UpdateMembershipQuantityV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMembershipQuantityV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Response}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPaymentRequired(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPaymentUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMembershipQuantityV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMembershipQuantityV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPaymentRequired();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getPaymentUrl();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional bool payment_required = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.prototype.getPaymentRequired = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Response} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.prototype.setPaymentRequired = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional string payment_url = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.prototype.getPaymentUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMembershipQuantityV1Response} returns this
 */
proto.jp.singcolor.UpdateMembershipQuantityV1Response.prototype.setPaymentUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MembershipPlan.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MembershipPlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MembershipPlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MembershipPlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            membershipType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            planName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
            priceJpy: jspb.Message.getFieldWithDefault(msg, 4, 0),
            favePtReward: jspb.Message.getFieldWithDefault(msg, 5, 0),
            requiredFavePt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            specificLiverCoinAmountPerMonth: (f = msg.getSpecificLiverCoinAmountPerMonth()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            gospelCoinAmountPerDay: (f = msg.getGospelCoinAmountPerDay()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            totalCoinAmount: (f = msg.getTotalCoinAmount()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            diamondAmountPerTerm: jspb.Message.getFieldWithDefault(msg, 10, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MembershipPlan}
 */
proto.jp.singcolor.MembershipPlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MembershipPlan;
    return proto.jp.singcolor.MembershipPlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MembershipPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MembershipPlan}
 */
proto.jp.singcolor.MembershipPlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.MembershipType} */ (reader.readEnum());
                msg.setMembershipType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPlanName(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setQuantity(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPriceJpy(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFavePtReward(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRequiredFavePt(value);
                break;
            case 7:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setSpecificLiverCoinAmountPerMonth(value);
                break;
            case 8:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setGospelCoinAmountPerDay(value);
                break;
            case 9:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setTotalCoinAmount(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondAmountPerTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MembershipPlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MembershipPlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MembershipPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MembershipPlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembershipType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getPlanName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getQuantity();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getPriceJpy();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFavePtReward();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getRequiredFavePt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getSpecificLiverCoinAmountPerMonth();
    if (f != null) {
        writer.writeMessage(7, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getGospelCoinAmountPerDay();
    if (f != null) {
        writer.writeMessage(8, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getTotalCoinAmount();
    if (f != null) {
        writer.writeMessage(9, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getDiamondAmountPerTerm();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
};
/**
 * optional MembershipType membership_type = 1;
 * @return {!proto.MembershipType}
 */
proto.jp.singcolor.MembershipPlan.prototype.getMembershipType = function () {
    return /** @type {!proto.MembershipType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.MembershipType} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setMembershipType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string plan_name = 2;
 * @return {string}
 */
proto.jp.singcolor.MembershipPlan.prototype.getPlanName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setPlanName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 quantity = 3;
 * @return {number}
 */
proto.jp.singcolor.MembershipPlan.prototype.getQuantity = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setQuantity = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 price_jpy = 4;
 * @return {number}
 */
proto.jp.singcolor.MembershipPlan.prototype.getPriceJpy = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setPriceJpy = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 fave_pt_reward = 5;
 * @return {number}
 */
proto.jp.singcolor.MembershipPlan.prototype.getFavePtReward = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setFavePtReward = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 required_fave_pt = 6;
 * @return {number}
 */
proto.jp.singcolor.MembershipPlan.prototype.getRequiredFavePt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setRequiredFavePt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional CoinAmount specific_liver_coin_amount_per_month = 7;
 * @return {?proto.CoinAmount}
 */
proto.jp.singcolor.MembershipPlan.prototype.getSpecificLiverCoinAmountPerMonth = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 7));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
*/
proto.jp.singcolor.MembershipPlan.prototype.setSpecificLiverCoinAmountPerMonth = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.clearSpecificLiverCoinAmountPerMonth = function () {
    return this.setSpecificLiverCoinAmountPerMonth(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MembershipPlan.prototype.hasSpecificLiverCoinAmountPerMonth = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional CoinAmount gospel_coin_amount_per_day = 8;
 * @return {?proto.CoinAmount}
 */
proto.jp.singcolor.MembershipPlan.prototype.getGospelCoinAmountPerDay = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 8));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
*/
proto.jp.singcolor.MembershipPlan.prototype.setGospelCoinAmountPerDay = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.clearGospelCoinAmountPerDay = function () {
    return this.setGospelCoinAmountPerDay(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MembershipPlan.prototype.hasGospelCoinAmountPerDay = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional CoinAmount total_coin_amount = 9;
 * @return {?proto.CoinAmount}
 */
proto.jp.singcolor.MembershipPlan.prototype.getTotalCoinAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 9));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
*/
proto.jp.singcolor.MembershipPlan.prototype.setTotalCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.clearTotalCoinAmount = function () {
    return this.setTotalCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MembershipPlan.prototype.hasTotalCoinAmount = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional int64 diamond_amount_per_term = 10;
 * @return {number}
 */
proto.jp.singcolor.MembershipPlan.prototype.getDiamondAmountPerTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.MembershipPlan} returns this
 */
proto.jp.singcolor.MembershipPlan.prototype.setDiamondAmountPerTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AvailableMembershipPlan.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AvailableMembershipPlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AvailableMembershipPlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AvailableMembershipPlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            membershipPlan: (f = msg.getMembershipPlan()) && proto.jp.singcolor.MembershipPlan.toObject(includeInstance, f),
            contractStartDay: jspb.Message.getFieldWithDefault(msg, 2, ""),
            contractEndDay: jspb.Message.getFieldWithDefault(msg, 3, ""),
            priceJpy: jspb.Message.getFieldWithDefault(msg, 4, 0),
            contractMonths: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.AvailableMembershipPlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AvailableMembershipPlan;
    return proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AvailableMembershipPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.MembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.MembershipPlan.deserializeBinaryFromReader);
                msg.setMembershipPlan(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setContractStartDay(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setContractEndDay(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPriceJpy(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setContractMonths(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AvailableMembershipPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembershipPlan();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.MembershipPlan.serializeBinaryToWriter);
    }
    f = message.getContractStartDay();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getContractEndDay();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getPriceJpy();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getContractMonths();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional MembershipPlan membership_plan = 1;
 * @return {?proto.jp.singcolor.MembershipPlan}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.getMembershipPlan = function () {
    return /** @type{?proto.jp.singcolor.MembershipPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.MembershipPlan, 1));
};
/**
 * @param {?proto.jp.singcolor.MembershipPlan|undefined} value
 * @return {!proto.jp.singcolor.AvailableMembershipPlan} returns this
*/
proto.jp.singcolor.AvailableMembershipPlan.prototype.setMembershipPlan = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.AvailableMembershipPlan} returns this
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.clearMembershipPlan = function () {
    return this.setMembershipPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.hasMembershipPlan = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string contract_start_day = 2;
 * @return {string}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.getContractStartDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AvailableMembershipPlan} returns this
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.setContractStartDay = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string contract_end_day = 3;
 * @return {string}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.getContractEndDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AvailableMembershipPlan} returns this
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.setContractEndDay = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 price_jpy = 4;
 * @return {number}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.getPriceJpy = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AvailableMembershipPlan} returns this
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.setPriceJpy = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string contract_months = 5;
 * @return {string}
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.getContractMonths = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AvailableMembershipPlan} returns this
 */
proto.jp.singcolor.AvailableMembershipPlan.prototype.setContractMonths = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipPlansForJoinV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipPlansForJoinV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipPlansForJoinV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipPlansForJoinV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Request}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipPlansForJoinV1Request;
    return proto.jp.singcolor.ListMembershipPlansForJoinV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipPlansForJoinV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Request}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipPlansForJoinV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipPlansForJoinV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Request} returns this
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.repeatedFields_ = [1, 2, 3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipPlansForJoinV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipPlansForJoinV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            plans1MonthList: jspb.Message.toObjectList(msg.getPlans1MonthList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance),
            plans3MonthsList: jspb.Message.toObjectList(msg.getPlans3MonthsList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance),
            plans6MonthsList: jspb.Message.toObjectList(msg.getPlans6MonthsList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipPlansForJoinV1Response;
    return proto.jp.singcolor.ListMembershipPlansForJoinV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans1Month(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans3Months(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans6Months(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipPlansForJoinV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPlans1MonthList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlans3MonthsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlans6MonthsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
};
/**
 * repeated AvailableMembershipPlan plans_1_month = 1;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.getPlans1MonthList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.setPlans1MonthList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.addPlans1Month = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.clearPlans1MonthList = function () {
    return this.setPlans1MonthList([]);
};
/**
 * repeated AvailableMembershipPlan plans_3_months = 2;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.getPlans3MonthsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.setPlans3MonthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.addPlans3Months = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.clearPlans3MonthsList = function () {
    return this.setPlans3MonthsList([]);
};
/**
 * repeated AvailableMembershipPlan plans_6_months = 3;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.getPlans6MonthsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.setPlans6MonthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.addPlans6Months = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForJoinV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForJoinV1Response.prototype.clearPlans6MonthsList = function () {
    return this.setPlans6MonthsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request;
    return proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request} returns this
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.repeatedFields_ = [1, 2, 3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            plans1MonthList: jspb.Message.toObjectList(msg.getPlans1MonthList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance),
            plans3MonthsList: jspb.Message.toObjectList(msg.getPlans3MonthsList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance),
            plans6MonthsList: jspb.Message.toObjectList(msg.getPlans6MonthsList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response;
    return proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans1Month(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans3Months(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans6Months(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPlans1MonthList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlans3MonthsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlans6MonthsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
};
/**
 * repeated AvailableMembershipPlan plans_1_month = 1;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.getPlans1MonthList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.setPlans1MonthList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.addPlans1Month = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.clearPlans1MonthList = function () {
    return this.setPlans1MonthList([]);
};
/**
 * repeated AvailableMembershipPlan plans_3_months = 2;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.getPlans3MonthsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.setPlans3MonthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.addPlans3Months = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.clearPlans3MonthsList = function () {
    return this.setPlans3MonthsList([]);
};
/**
 * repeated AvailableMembershipPlan plans_6_months = 3;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.getPlans6MonthsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.setPlans6MonthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.addPlans6Months = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForChangeQuantityV1Response.prototype.clearPlans6MonthsList = function () {
    return this.setPlans6MonthsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request;
    return proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request} returns this
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.repeatedFields_ = [1, 2, 3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            plans1MonthList: jspb.Message.toObjectList(msg.getPlans1MonthList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance),
            plans3MonthsList: jspb.Message.toObjectList(msg.getPlans3MonthsList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance),
            plans6MonthsList: jspb.Message.toObjectList(msg.getPlans6MonthsList(), proto.jp.singcolor.AvailableMembershipPlan.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response;
    return proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans1Month(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans3Months(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.AvailableMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.AvailableMembershipPlan.deserializeBinaryFromReader);
                msg.addPlans6Months(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPlans1MonthList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlans3MonthsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlans6MonthsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.jp.singcolor.AvailableMembershipPlan.serializeBinaryToWriter);
    }
};
/**
 * repeated AvailableMembershipPlan plans_1_month = 1;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.getPlans1MonthList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.setPlans1MonthList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.addPlans1Month = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.clearPlans1MonthList = function () {
    return this.setPlans1MonthList([]);
};
/**
 * repeated AvailableMembershipPlan plans_3_months = 2;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.getPlans3MonthsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.setPlans3MonthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.addPlans3Months = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.clearPlans3MonthsList = function () {
    return this.setPlans3MonthsList([]);
};
/**
 * repeated AvailableMembershipPlan plans_6_months = 3;
 * @return {!Array<!proto.jp.singcolor.AvailableMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.getPlans6MonthsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.AvailableMembershipPlan, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} returns this
*/
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.setPlans6MonthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableMembershipPlan}
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.addPlans6Months = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.AvailableMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response} returns this
 */
proto.jp.singcolor.ListMembershipPlansForIncreaseQuantityV1Response.prototype.clearPlans6MonthsList = function () {
    return this.setPlans6MonthsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListenerMembershipPlan.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListenerMembershipPlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListenerMembershipPlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListenerMembershipPlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            liver: (f = msg.getLiver()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            listener: (f = msg.getListener()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            currentPlan: (f = msg.getCurrentPlan()) && proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.toObject(includeInstance, f),
            planAfterRenew: (f = msg.getPlanAfterRenew()) && proto.jp.singcolor.MembershipPlan.toObject(includeInstance, f),
            contractStartDay: jspb.Message.getFieldWithDefault(msg, 5, ""),
            contractEndDay: jspb.Message.getFieldWithDefault(msg, 6, ""),
            contractRenewDay: jspb.Message.getFieldWithDefault(msg, 7, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListenerMembershipPlan;
    return proto.jp.singcolor.ListenerMembershipPlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListenerMembershipPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setLiver(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.deserializeBinaryFromReader);
                msg.setCurrentPlan(value);
                break;
            case 4:
                var value = new proto.jp.singcolor.MembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.MembershipPlan.deserializeBinaryFromReader);
                msg.setPlanAfterRenew(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setContractStartDay(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setContractEndDay(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setContractRenewDay(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListenerMembershipPlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListenerMembershipPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListenerMembershipPlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiver();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getCurrentPlan();
    if (f != null) {
        writer.writeMessage(3, f, proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getPlanAfterRenew();
    if (f != null) {
        writer.writeMessage(4, f, proto.jp.singcolor.MembershipPlan.serializeBinaryToWriter);
    }
    f = message.getContractStartDay();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getContractEndDay();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getContractRenewDay();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            thisMonthPlan: (f = msg.getThisMonthPlan()) && proto.jp.singcolor.MembershipPlan.toObject(includeInstance, f),
            nextMonthPlan: (f = msg.getNextMonthPlan()) && proto.jp.singcolor.MembershipPlan.toObject(includeInstance, f),
            nextMonthPlanStartDay: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan;
    return proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.MembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.MembershipPlan.deserializeBinaryFromReader);
                msg.setThisMonthPlan(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.MembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.MembershipPlan.deserializeBinaryFromReader);
                msg.setNextMonthPlan(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextMonthPlanStartDay(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getThisMonthPlan();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.MembershipPlan.serializeBinaryToWriter);
    }
    f = message.getNextMonthPlan();
    if (f != null) {
        writer.writeMessage(2, f, proto.jp.singcolor.MembershipPlan.serializeBinaryToWriter);
    }
    f = message.getNextMonthPlanStartDay();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional MembershipPlan this_month_plan = 1;
 * @return {?proto.jp.singcolor.MembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.getThisMonthPlan = function () {
    return /** @type{?proto.jp.singcolor.MembershipPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.MembershipPlan, 1));
};
/**
 * @param {?proto.jp.singcolor.MembershipPlan|undefined} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} returns this
*/
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.setThisMonthPlan = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.clearThisMonthPlan = function () {
    return this.setThisMonthPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.hasThisMonthPlan = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional MembershipPlan next_month_plan = 2;
 * @return {?proto.jp.singcolor.MembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.getNextMonthPlan = function () {
    return /** @type{?proto.jp.singcolor.MembershipPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.MembershipPlan, 2));
};
/**
 * @param {?proto.jp.singcolor.MembershipPlan|undefined} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} returns this
*/
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.setNextMonthPlan = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.clearNextMonthPlan = function () {
    return this.setNextMonthPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.hasNextMonthPlan = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string next_month_plan_start_day = 3;
 * @return {string}
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.getNextMonthPlanStartDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan.prototype.setNextMonthPlanStartDay = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional UserItem liver = 1;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getLiver = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
*/
proto.jp.singcolor.ListenerMembershipPlan.prototype.setLiver = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.clearLiver = function () {
    return this.setLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.hasLiver = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem listener = 2;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getListener = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
*/
proto.jp.singcolor.ListenerMembershipPlan.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.hasListener = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional CurrentMembershipPlan current_plan = 3;
 * @return {?proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getCurrentPlan = function () {
    return /** @type{?proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan, 3));
};
/**
 * @param {?proto.jp.singcolor.ListenerMembershipPlan.CurrentMembershipPlan|undefined} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
*/
proto.jp.singcolor.ListenerMembershipPlan.prototype.setCurrentPlan = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.clearCurrentPlan = function () {
    return this.setCurrentPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.hasCurrentPlan = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional MembershipPlan plan_after_renew = 4;
 * @return {?proto.jp.singcolor.MembershipPlan}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getPlanAfterRenew = function () {
    return /** @type{?proto.jp.singcolor.MembershipPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.MembershipPlan, 4));
};
/**
 * @param {?proto.jp.singcolor.MembershipPlan|undefined} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
*/
proto.jp.singcolor.ListenerMembershipPlan.prototype.setPlanAfterRenew = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.clearPlanAfterRenew = function () {
    return this.setPlanAfterRenew(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.hasPlanAfterRenew = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional string contract_start_day = 5;
 * @return {string}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getContractStartDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.setContractStartDay = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string contract_end_day = 6;
 * @return {string}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getContractEndDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.setContractEndDay = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string contract_renew_day = 7;
 * @return {string}
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.getContractRenewDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListenerMembershipPlan} returns this
 */
proto.jp.singcolor.ListenerMembershipPlan.prototype.setContractRenewDay = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request;
    return proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerMembershipPlansList: jspb.Message.toObjectList(msg.getListenerMembershipPlansList(), proto.jp.singcolor.ListenerMembershipPlan.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response;
    return proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListenerMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.ListenerMembershipPlan.deserializeBinaryFromReader);
                msg.addListenerMembershipPlans(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerMembershipPlansList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListenerMembershipPlan.serializeBinaryToWriter);
    }
};
/**
 * repeated ListenerMembershipPlan listener_membership_plans = 1;
 * @return {!Array<!proto.jp.singcolor.ListenerMembershipPlan>}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.prototype.getListenerMembershipPlansList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListenerMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListenerMembershipPlan, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListenerMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response} returns this
*/
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.prototype.setListenerMembershipPlansList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListenerMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListenerMembershipPlan}
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.prototype.addListenerMembershipPlans = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListenerMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response} returns this
 */
proto.jp.singcolor.ListActiveListenerMembershipPlansV1Response.prototype.clearListenerMembershipPlansList = function () {
    return this.setListenerMembershipPlansList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipListenersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipListenersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipListenersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipListenersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Request}
 */
proto.jp.singcolor.ListMembershipListenersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipListenersV1Request;
    return proto.jp.singcolor.ListMembershipListenersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipListenersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Request}
 */
proto.jp.singcolor.ListMembershipListenersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipListenersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipListenersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipListenersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipListenersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListMembershipListenersV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Request} returns this
 */
proto.jp.singcolor.ListMembershipListenersV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListMembershipListenersV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Request} returns this
 */
proto.jp.singcolor.ListMembershipListenersV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListMembershipListenersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMembershipListenersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMembershipListenersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMembershipListenersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMembershipListenersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            membershipListenersList: jspb.Message.toObjectList(msg.getMembershipListenersList(), proto.jp.singcolor.ListenerMembershipPlan.toObject, includeInstance),
            totalDiamondAmountPerTerm: jspb.Message.getFieldWithDefault(msg, 2, 0),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Response}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMembershipListenersV1Response;
    return proto.jp.singcolor.ListMembershipListenersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMembershipListenersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Response}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListenerMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.ListenerMembershipPlan.deserializeBinaryFromReader);
                msg.addMembershipListeners(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTotalDiamondAmountPerTerm(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMembershipListenersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMembershipListenersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMembershipListenersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembershipListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListenerMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getTotalDiamondAmountPerTerm();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * repeated ListenerMembershipPlan membership_listeners = 1;
 * @return {!Array<!proto.jp.singcolor.ListenerMembershipPlan>}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.getMembershipListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListenerMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListenerMembershipPlan, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListenerMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Response} returns this
*/
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.setMembershipListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListenerMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListenerMembershipPlan}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.addMembershipListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListenerMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Response} returns this
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.clearMembershipListenersList = function () {
    return this.setMembershipListenersList([]);
};
/**
 * optional int64 total_diamond_amount_per_term = 2;
 * @return {number}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.getTotalDiamondAmountPerTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Response} returns this
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.setTotalDiamondAmountPerTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListMembershipListenersV1Response} returns this
 */
proto.jp.singcolor.ListMembershipListenersV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWillJoinMembershipListenersV1Request;
    return proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request} returns this
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Request} returns this
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            willJoinMembershipListenersList: jspb.Message.toObjectList(msg.getWillJoinMembershipListenersList(), proto.jp.singcolor.ListenerMembershipPlan.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWillJoinMembershipListenersV1Response;
    return proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListenerMembershipPlan;
                reader.readMessage(value, proto.jp.singcolor.ListenerMembershipPlan.deserializeBinaryFromReader);
                msg.addWillJoinMembershipListeners(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWillJoinMembershipListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListenerMembershipPlan.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * repeated ListenerMembershipPlan will_join_membership_listeners = 1;
 * @return {!Array<!proto.jp.singcolor.ListenerMembershipPlan>}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.getWillJoinMembershipListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListenerMembershipPlan>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListenerMembershipPlan, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListenerMembershipPlan>} value
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response} returns this
*/
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.setWillJoinMembershipListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListenerMembershipPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListenerMembershipPlan}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.addWillJoinMembershipListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListenerMembershipPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response} returns this
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.clearWillJoinMembershipListenersList = function () {
    return this.setWillJoinMembershipListenersList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWillJoinMembershipListenersV1Response} returns this
 */
proto.jp.singcolor.ListWillJoinMembershipListenersV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
goog.object.extend(exports, proto.jp.singcolor);
