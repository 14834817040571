"use strict";
// source: debug.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var debug_entity_pb = require('./debug_entity_pb.js');
goog.object.extend(proto, debug_entity_pb);
var lives_entity_pb = require('./lives_entity_pb.js');
goog.object.extend(proto, lives_entity_pb);
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
var leagues_entity_pb = require('./leagues_entity_pb.js');
goog.object.extend(proto, leagues_entity_pb);
goog.exportSymbol('proto.jp.singcolor.AddCoinRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.AddCoinRequest.Type', null, global);
goog.exportSymbol('proto.jp.singcolor.AddCoinResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.AddDiamondRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.AddDiamondResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.AddFaveBonusSourceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.AddFaveBonusSourceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.AddFavePointsSourceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.AddFavePointsSourceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateMultiLivesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateMultiLivesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOnePhraseEventEntriesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOnePhraseEventEntriesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateTokenRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateTokenRequest.LoginType', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateTokenResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateUserType', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateUsersRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateUsersResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugStartLiveRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugStartLiveResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteDeviceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteDeviceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteDiamondFundOverwriteRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteDiamondFundOverwriteResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteServerRequestedTimeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteServerRequestedTimeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteSingHistoryRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteSingHistoryResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteUserBanHistoriesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteUserBanHistoriesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DistributePearlByPearlCupResultV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DistributePearlByPearlCupResultV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetCoinBalanceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetCoinBalanceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetCoinPurchaseFailureModeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetCoinPurchaseFailureModeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondAcquisitionRightRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondAcquisitionRightResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondBalanceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondBalanceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondFundRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDiamondFundResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetForceCapturePossibilityRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetForceCapturePossibilityResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetListenerLiveActivitiesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetListenerLiveActivitiesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiverLiveActivitiesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiverLiveActivitiesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetServerRequestedTimeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetServerRequestedTimeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTimelineDummyDataSettingRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTimelineDummyDataSettingResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDeviceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDeviceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDeviceResponse.UserDevice', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingHistoriesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingHistoriesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUsersRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUsersResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListenerLiveActivitiesRes', null, global);
goog.exportSymbol('proto.jp.singcolor.OverwriteDiamondFundRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OverwriteDiamondFundResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RecalculateLiverLeagueRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RecalculateLiverLeagueResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RunDistributeDiamondCommandRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RunDistributeDiamondCommandResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RunSubtractDiamondCommandRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RunSubtractDiamondCommandResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.GMOAozoraMockType', null, global);
goog.exportSymbol('proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveForceUseCSSRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveForceUseCSSResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveServerRequestedTimeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveServerRequestedTimeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateForceCapturePossibilityRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateForceCapturePossibilityResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLeagueRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLeagueResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMonthlyFavePointRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMonthlyFavePointResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateSingHistoryRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateSingHistoryResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserDeviceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserDeviceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateTokenRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateTokenRequest.displayName = 'proto.jp.singcolor.CreateTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateTokenResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateTokenResponse.displayName = 'proto.jp.singcolor.CreateTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetCoinBalanceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetCoinBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetCoinBalanceRequest.displayName = 'proto.jp.singcolor.GetCoinBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetCoinBalanceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetCoinBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetCoinBalanceResponse.displayName = 'proto.jp.singcolor.GetCoinBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddCoinRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddCoinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddCoinRequest.displayName = 'proto.jp.singcolor.AddCoinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddCoinResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddCoinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddCoinResponse.displayName = 'proto.jp.singcolor.AddCoinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateUsersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateUsersRequest.displayName = 'proto.jp.singcolor.CreateUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateUsersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.CreateUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.CreateUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateUsersResponse.displayName = 'proto.jp.singcolor.CreateUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteDeviceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteDeviceRequest.displayName = 'proto.jp.singcolor.DeleteDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteDeviceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteDeviceResponse.displayName = 'proto.jp.singcolor.DeleteDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUsersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUsersRequest.displayName = 'proto.jp.singcolor.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUsersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUsersResponse.displayName = 'proto.jp.singcolor.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetServerRequestedTimeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetServerRequestedTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetServerRequestedTimeRequest.displayName = 'proto.jp.singcolor.GetServerRequestedTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetServerRequestedTimeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetServerRequestedTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetServerRequestedTimeResponse.displayName = 'proto.jp.singcolor.GetServerRequestedTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveServerRequestedTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveServerRequestedTimeRequest.displayName = 'proto.jp.singcolor.SaveServerRequestedTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveServerRequestedTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveServerRequestedTimeResponse.displayName = 'proto.jp.singcolor.SaveServerRequestedTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteServerRequestedTimeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteServerRequestedTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteServerRequestedTimeRequest.displayName = 'proto.jp.singcolor.DeleteServerRequestedTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteServerRequestedTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteServerRequestedTimeResponse.displayName = 'proto.jp.singcolor.DeleteServerRequestedTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListenerLiveActivitiesRes = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListenerLiveActivitiesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListenerLiveActivitiesRes.displayName = 'proto.jp.singcolor.ListenerLiveActivitiesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiverLiveActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiverLiveActivitiesRequest.displayName = 'proto.jp.singcolor.GetLiverLiveActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetLiverLiveActivitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetLiverLiveActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiverLiveActivitiesResponse.displayName = 'proto.jp.singcolor.GetLiverLiveActivitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetListenerLiveActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetListenerLiveActivitiesRequest.displayName = 'proto.jp.singcolor.GetListenerLiveActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetListenerLiveActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetListenerLiveActivitiesResponse.displayName = 'proto.jp.singcolor.GetListenerLiveActivitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLeagueRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLeagueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLeagueRequest.displayName = 'proto.jp.singcolor.UpdateLeagueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLeagueResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLeagueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLeagueResponse.displayName = 'proto.jp.singcolor.UpdateLeagueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddFavePointsSourceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddFavePointsSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddFavePointsSourceRequest.displayName = 'proto.jp.singcolor.AddFavePointsSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddFavePointsSourceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddFavePointsSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddFavePointsSourceResponse.displayName = 'proto.jp.singcolor.AddFavePointsSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddFaveBonusSourceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddFaveBonusSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddFaveBonusSourceRequest.displayName = 'proto.jp.singcolor.AddFaveBonusSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddFaveBonusSourceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddFaveBonusSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddFaveBonusSourceResponse.displayName = 'proto.jp.singcolor.AddFaveBonusSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetCoinPurchaseFailureModeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.displayName = 'proto.jp.singcolor.GetCoinPurchaseFailureModeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetCoinPurchaseFailureModeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.displayName = 'proto.jp.singcolor.GetCoinPurchaseFailureModeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.displayName = 'proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.displayName = 'proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDeviceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDeviceRequest.displayName = 'proto.jp.singcolor.GetUserDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDeviceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetUserDeviceResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetUserDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDeviceResponse.displayName = 'proto.jp.singcolor.GetUserDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserDeviceResponse.UserDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDeviceResponse.UserDevice.displayName = 'proto.jp.singcolor.GetUserDeviceResponse.UserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserDeviceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserDeviceRequest.displayName = 'proto.jp.singcolor.UpdateUserDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserDeviceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserDeviceResponse.displayName = 'proto.jp.singcolor.UpdateUserDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondAcquisitionRightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondAcquisitionRightRequest.displayName = 'proto.jp.singcolor.GetDiamondAcquisitionRightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondAcquisitionRightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondAcquisitionRightResponse.displayName = 'proto.jp.singcolor.GetDiamondAcquisitionRightResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.displayName = 'proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.displayName = 'proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateMultiLivesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateMultiLivesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateMultiLivesRequest.displayName = 'proto.jp.singcolor.CreateMultiLivesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateMultiLivesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateMultiLivesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateMultiLivesResponse.displayName = 'proto.jp.singcolor.CreateMultiLivesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.displayName = 'proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.displayName = 'proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.displayName = 'proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.displayName = 'proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunDistributeDiamondCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunDistributeDiamondCommandRequest.displayName = 'proto.jp.singcolor.RunDistributeDiamondCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunDistributeDiamondCommandResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunDistributeDiamondCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunDistributeDiamondCommandResponse.displayName = 'proto.jp.singcolor.RunDistributeDiamondCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.displayName = 'proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.displayName = 'proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RecalculateLiverLeagueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RecalculateLiverLeagueRequest.displayName = 'proto.jp.singcolor.RecalculateLiverLeagueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RecalculateLiverLeagueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RecalculateLiverLeagueResponse.displayName = 'proto.jp.singcolor.RecalculateLiverLeagueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondFundRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondFundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondFundRequest.displayName = 'proto.jp.singcolor.GetDiamondFundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondFundResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondFundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondFundResponse.displayName = 'proto.jp.singcolor.GetDiamondFundResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OverwriteDiamondFundRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OverwriteDiamondFundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OverwriteDiamondFundRequest.displayName = 'proto.jp.singcolor.OverwriteDiamondFundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OverwriteDiamondFundResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OverwriteDiamondFundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OverwriteDiamondFundResponse.displayName = 'proto.jp.singcolor.OverwriteDiamondFundResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteDiamondFundOverwriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.displayName = 'proto.jp.singcolor.DeleteDiamondFundOverwriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteDiamondFundOverwriteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.displayName = 'proto.jp.singcolor.DeleteDiamondFundOverwriteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.displayName = 'proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.displayName = 'proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSingHistoriesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSingHistoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSingHistoriesRequest.displayName = 'proto.jp.singcolor.ListSingHistoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSingHistoriesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSingHistoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSingHistoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSingHistoriesResponse.displayName = 'proto.jp.singcolor.ListSingHistoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateSingHistoryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateSingHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateSingHistoryRequest.displayName = 'proto.jp.singcolor.UpdateSingHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateSingHistoryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateSingHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateSingHistoryResponse.displayName = 'proto.jp.singcolor.UpdateSingHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteSingHistoryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteSingHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteSingHistoryRequest.displayName = 'proto.jp.singcolor.DeleteSingHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteSingHistoryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteSingHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteSingHistoryResponse.displayName = 'proto.jp.singcolor.DeleteSingHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.displayName = 'proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.displayName = 'proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.displayName = 'proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.displayName = 'proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondBalanceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondBalanceRequest.displayName = 'proto.jp.singcolor.GetDiamondBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondBalanceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondBalanceResponse.displayName = 'proto.jp.singcolor.GetDiamondBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddDiamondRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddDiamondRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddDiamondRequest.displayName = 'proto.jp.singcolor.AddDiamondRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddDiamondResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddDiamondResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddDiamondResponse.displayName = 'proto.jp.singcolor.AddDiamondResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTimelineDummyDataSettingRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTimelineDummyDataSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTimelineDummyDataSettingRequest.displayName = 'proto.jp.singcolor.GetTimelineDummyDataSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTimelineDummyDataSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTimelineDummyDataSettingResponse.displayName = 'proto.jp.singcolor.GetTimelineDummyDataSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.displayName = 'proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.displayName = 'proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.displayName = 'proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.displayName = 'proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.displayName = 'proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.displayName = 'proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteUserBanHistoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteUserBanHistoriesRequest.displayName = 'proto.jp.singcolor.DeleteUserBanHistoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteUserBanHistoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteUserBanHistoriesResponse.displayName = 'proto.jp.singcolor.DeleteUserBanHistoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.displayName = 'proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.displayName = 'proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.displayName = 'proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.displayName = 'proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.displayName = 'proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.displayName = 'proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.displayName = 'proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.displayName = 'proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.displayName = 'proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.displayName = 'proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveForceUseCSSRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveForceUseCSSRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveForceUseCSSRequest.displayName = 'proto.jp.singcolor.SaveForceUseCSSRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveForceUseCSSResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveForceUseCSSResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveForceUseCSSResponse.displayName = 'proto.jp.singcolor.SaveForceUseCSSResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunSubtractDiamondCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunSubtractDiamondCommandRequest.displayName = 'proto.jp.singcolor.RunSubtractDiamondCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunSubtractDiamondCommandResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunSubtractDiamondCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunSubtractDiamondCommandResponse.displayName = 'proto.jp.singcolor.RunSubtractDiamondCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DistributePearlByPearlCupResultV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.displayName = 'proto.jp.singcolor.DistributePearlByPearlCupResultV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DistributePearlByPearlCupResultV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.displayName = 'proto.jp.singcolor.DistributePearlByPearlCupResultV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetForceCapturePossibilityRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetForceCapturePossibilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetForceCapturePossibilityRequest.displayName = 'proto.jp.singcolor.GetForceCapturePossibilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetForceCapturePossibilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetForceCapturePossibilityResponse.displayName = 'proto.jp.singcolor.GetForceCapturePossibilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateForceCapturePossibilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateForceCapturePossibilityRequest.displayName = 'proto.jp.singcolor.UpdateForceCapturePossibilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateForceCapturePossibilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateForceCapturePossibilityResponse.displayName = 'proto.jp.singcolor.UpdateForceCapturePossibilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOnePhraseEventEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.displayName = 'proto.jp.singcolor.CreateOnePhraseEventEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOnePhraseEventEntriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.displayName = 'proto.jp.singcolor.CreateOnePhraseEventEntriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMonthlyFavePointRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMonthlyFavePointRequest.displayName = 'proto.jp.singcolor.UpdateMonthlyFavePointRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMonthlyFavePointResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMonthlyFavePointResponse.displayName = 'proto.jp.singcolor.UpdateMonthlyFavePointResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.displayName = 'proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.displayName = 'proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.displayName = 'proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.displayName = 'proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.displayName = 'proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.displayName = 'proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugStartLiveRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.DebugStartLiveRequest.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.DebugStartLiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugStartLiveRequest.displayName = 'proto.jp.singcolor.DebugStartLiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugStartLiveResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugStartLiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugStartLiveResponse.displayName = 'proto.jp.singcolor.DebugStartLiveResponse';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateTokenRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateTokenRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateTokenRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateTokenRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            loginType: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateTokenRequest}
 */
proto.jp.singcolor.CreateTokenRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateTokenRequest;
    return proto.jp.singcolor.CreateTokenRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateTokenRequest}
 */
proto.jp.singcolor.CreateTokenRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.CreateTokenRequest.LoginType} */ (reader.readEnum());
                msg.setLoginType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateTokenRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateTokenRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLoginType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.CreateTokenRequest.LoginType = {
    TYPE_UNKNOWN: 0,
    TYPE_CUSTOMER: 1,
    TYPE_OPE: 2
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateTokenRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateTokenRequest} returns this
 */
proto.jp.singcolor.CreateTokenRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional LoginType login_type = 2;
 * @return {!proto.jp.singcolor.CreateTokenRequest.LoginType}
 */
proto.jp.singcolor.CreateTokenRequest.prototype.getLoginType = function () {
    return /** @type {!proto.jp.singcolor.CreateTokenRequest.LoginType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.CreateTokenRequest.LoginType} value
 * @return {!proto.jp.singcolor.CreateTokenRequest} returns this
 */
proto.jp.singcolor.CreateTokenRequest.prototype.setLoginType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateTokenResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateTokenResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateTokenResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateTokenResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            token: jspb.Message.getFieldWithDefault(msg, 1, ""),
            customToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateTokenResponse}
 */
proto.jp.singcolor.CreateTokenResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateTokenResponse;
    return proto.jp.singcolor.CreateTokenResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateTokenResponse}
 */
proto.jp.singcolor.CreateTokenResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setToken(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCustomToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateTokenResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateTokenResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCustomToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string token = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateTokenResponse.prototype.getToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateTokenResponse} returns this
 */
proto.jp.singcolor.CreateTokenResponse.prototype.setToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string custom_token = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateTokenResponse.prototype.getCustomToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateTokenResponse} returns this
 */
proto.jp.singcolor.CreateTokenResponse.prototype.setCustomToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetCoinBalanceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetCoinBalanceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetCoinBalanceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetCoinBalanceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetCoinBalanceRequest}
 */
proto.jp.singcolor.GetCoinBalanceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetCoinBalanceRequest;
    return proto.jp.singcolor.GetCoinBalanceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetCoinBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetCoinBalanceRequest}
 */
proto.jp.singcolor.GetCoinBalanceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetCoinBalanceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetCoinBalanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetCoinBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetCoinBalanceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetCoinBalanceRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetCoinBalanceRequest} returns this
 */
proto.jp.singcolor.GetCoinBalanceRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetCoinBalanceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetCoinBalanceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetCoinBalanceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetCoinBalanceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            specificLiverCoin: (f = msg.getSpecificLiverCoin()) && coins_entity_pb.SpecificLiverCoin.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetCoinBalanceResponse}
 */
proto.jp.singcolor.GetCoinBalanceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetCoinBalanceResponse;
    return proto.jp.singcolor.GetCoinBalanceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetCoinBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetCoinBalanceResponse}
 */
proto.jp.singcolor.GetCoinBalanceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 2:
                var value = new coins_entity_pb.SpecificLiverCoin;
                reader.readMessage(value, coins_entity_pb.SpecificLiverCoin.deserializeBinaryFromReader);
                msg.setSpecificLiverCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetCoinBalanceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetCoinBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetCoinBalanceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getSpecificLiverCoin();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.SpecificLiverCoin.serializeBinaryToWriter);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.CoinBalance}
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.getCoinBalance = function () {
    return /** @type{?proto.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.GetCoinBalanceResponse} returns this
*/
proto.jp.singcolor.GetCoinBalanceResponse.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetCoinBalanceResponse} returns this
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional SpecificLiverCoin specific_liver_coin = 2;
 * @return {?proto.SpecificLiverCoin}
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.getSpecificLiverCoin = function () {
    return /** @type{?proto.SpecificLiverCoin} */ (jspb.Message.getWrapperField(this, coins_entity_pb.SpecificLiverCoin, 2));
};
/**
 * @param {?proto.SpecificLiverCoin|undefined} value
 * @return {!proto.jp.singcolor.GetCoinBalanceResponse} returns this
*/
proto.jp.singcolor.GetCoinBalanceResponse.prototype.setSpecificLiverCoin = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetCoinBalanceResponse} returns this
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.clearSpecificLiverCoin = function () {
    return this.setSpecificLiverCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetCoinBalanceResponse.prototype.hasSpecificLiverCoin = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddCoinRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddCoinRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddCoinRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddCoinRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            coinAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            type: jspb.Message.getFieldWithDefault(msg, 3, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddCoinRequest}
 */
proto.jp.singcolor.AddCoinRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddCoinRequest;
    return proto.jp.singcolor.AddCoinRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddCoinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddCoinRequest}
 */
proto.jp.singcolor.AddCoinRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinAmount(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.AddCoinRequest.Type} */ (reader.readEnum());
                msg.setType(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddCoinRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddCoinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddCoinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddCoinRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCoinAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.AddCoinRequest.Type = {
    TYPE_UNKNOWN: 0,
    TYPE_SPECIFIC_LIVER: 12,
    TYPE_FREE: 3,
    TYPE_PAID: 9,
    TYPE_FROM_DIAMOND: 10,
    TYPE_DISCOUNT: 11,
    TYPE_DOUBLE_PAID: 13
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.AddCoinRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddCoinRequest} returns this
 */
proto.jp.singcolor.AddCoinRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 coin_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.AddCoinRequest.prototype.getCoinAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddCoinRequest} returns this
 */
proto.jp.singcolor.AddCoinRequest.prototype.setCoinAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional Type type = 3;
 * @return {!proto.jp.singcolor.AddCoinRequest.Type}
 */
proto.jp.singcolor.AddCoinRequest.prototype.getType = function () {
    return /** @type {!proto.jp.singcolor.AddCoinRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.AddCoinRequest.Type} value
 * @return {!proto.jp.singcolor.AddCoinRequest} returns this
 */
proto.jp.singcolor.AddCoinRequest.prototype.setType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string liver_id = 4;
 * @return {string}
 */
proto.jp.singcolor.AddCoinRequest.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddCoinRequest} returns this
 */
proto.jp.singcolor.AddCoinRequest.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddCoinResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddCoinResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddCoinResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddCoinResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            specificLiverCoin: (f = msg.getSpecificLiverCoin()) && coins_entity_pb.SpecificLiverCoin.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddCoinResponse}
 */
proto.jp.singcolor.AddCoinResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddCoinResponse;
    return proto.jp.singcolor.AddCoinResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddCoinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddCoinResponse}
 */
proto.jp.singcolor.AddCoinResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 2:
                var value = new coins_entity_pb.SpecificLiverCoin;
                reader.readMessage(value, coins_entity_pb.SpecificLiverCoin.deserializeBinaryFromReader);
                msg.setSpecificLiverCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddCoinResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddCoinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddCoinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddCoinResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getSpecificLiverCoin();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.SpecificLiverCoin.serializeBinaryToWriter);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.CoinBalance}
 */
proto.jp.singcolor.AddCoinResponse.prototype.getCoinBalance = function () {
    return /** @type{?proto.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.AddCoinResponse} returns this
*/
proto.jp.singcolor.AddCoinResponse.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.AddCoinResponse} returns this
 */
proto.jp.singcolor.AddCoinResponse.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.AddCoinResponse.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional SpecificLiverCoin specific_liver_coin = 2;
 * @return {?proto.SpecificLiverCoin}
 */
proto.jp.singcolor.AddCoinResponse.prototype.getSpecificLiverCoin = function () {
    return /** @type{?proto.SpecificLiverCoin} */ (jspb.Message.getWrapperField(this, coins_entity_pb.SpecificLiverCoin, 2));
};
/**
 * @param {?proto.SpecificLiverCoin|undefined} value
 * @return {!proto.jp.singcolor.AddCoinResponse} returns this
*/
proto.jp.singcolor.AddCoinResponse.prototype.setSpecificLiverCoin = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.AddCoinResponse} returns this
 */
proto.jp.singcolor.AddCoinResponse.prototype.clearSpecificLiverCoin = function () {
    return this.setSpecificLiverCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.AddCoinResponse.prototype.hasSpecificLiverCoin = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateUsersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateUsersRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateUsersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateUsersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            createUserType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            createNum: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateUsersRequest}
 */
proto.jp.singcolor.CreateUsersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateUsersRequest;
    return proto.jp.singcolor.CreateUsersRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateUsersRequest}
 */
proto.jp.singcolor.CreateUsersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.CreateUserType} */ (reader.readEnum());
                msg.setCreateUserType(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreateNum(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateUsersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateUsersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCreateUserType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getCreateNum();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional CreateUserType create_user_type = 1;
 * @return {!proto.jp.singcolor.CreateUserType}
 */
proto.jp.singcolor.CreateUsersRequest.prototype.getCreateUserType = function () {
    return /** @type {!proto.jp.singcolor.CreateUserType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.CreateUserType} value
 * @return {!proto.jp.singcolor.CreateUsersRequest} returns this
 */
proto.jp.singcolor.CreateUsersRequest.prototype.setCreateUserType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional int64 create_num = 2;
 * @return {number}
 */
proto.jp.singcolor.CreateUsersRequest.prototype.getCreateNum = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateUsersRequest} returns this
 */
proto.jp.singcolor.CreateUsersRequest.prototype.setCreateNum = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.CreateUsersResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateUsersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateUsersResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateUsersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateUsersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            usersList: jspb.Message.toObjectList(msg.getUsersList(), users_entity_pb.User.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateUsersResponse}
 */
proto.jp.singcolor.CreateUsersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateUsersResponse;
    return proto.jp.singcolor.CreateUsersResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateUsersResponse}
 */
proto.jp.singcolor.CreateUsersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addUsers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateUsersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateUsersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
};
/**
 * repeated User users = 1;
 * @return {!Array<!proto.User>}
 */
proto.jp.singcolor.CreateUsersResponse.prototype.getUsersList = function () {
    return /** @type{!Array<!proto.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {!Array<!proto.User>} value
 * @return {!proto.jp.singcolor.CreateUsersResponse} returns this
*/
proto.jp.singcolor.CreateUsersResponse.prototype.setUsersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.User}
 */
proto.jp.singcolor.CreateUsersResponse.prototype.addUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.CreateUsersResponse} returns this
 */
proto.jp.singcolor.CreateUsersResponse.prototype.clearUsersList = function () {
    return this.setUsersList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteDeviceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteDeviceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteDeviceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteDeviceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteDeviceRequest}
 */
proto.jp.singcolor.DeleteDeviceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteDeviceRequest;
    return proto.jp.singcolor.DeleteDeviceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteDeviceRequest}
 */
proto.jp.singcolor.DeleteDeviceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeviceId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteDeviceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteDeviceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDeviceId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteDeviceRequest.prototype.getDeviceId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteDeviceRequest} returns this
 */
proto.jp.singcolor.DeleteDeviceRequest.prototype.setDeviceId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteDeviceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteDeviceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteDeviceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteDeviceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteDeviceResponse}
 */
proto.jp.singcolor.DeleteDeviceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteDeviceResponse;
    return proto.jp.singcolor.DeleteDeviceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteDeviceResponse}
 */
proto.jp.singcolor.DeleteDeviceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteDeviceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteDeviceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUsersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUsersRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUsersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUsersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUsersRequest}
 */
proto.jp.singcolor.ListUsersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUsersRequest;
    return proto.jp.singcolor.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUsersRequest}
 */
proto.jp.singcolor.ListUsersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUsersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUsersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.ListUsersRequest.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUsersRequest} returns this
 */
proto.jp.singcolor.ListUsersRequest.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListUsersRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUsersRequest} returns this
 */
proto.jp.singcolor.ListUsersRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListUsersResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUsersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUsersResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUsersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUsersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            usersList: jspb.Message.toObjectList(msg.getUsersList(), users_entity_pb.User.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUsersResponse}
 */
proto.jp.singcolor.ListUsersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUsersResponse;
    return proto.jp.singcolor.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUsersResponse}
 */
proto.jp.singcolor.ListUsersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addUsers(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUsersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUsersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated User users = 1;
 * @return {!Array<!proto.User>}
 */
proto.jp.singcolor.ListUsersResponse.prototype.getUsersList = function () {
    return /** @type{!Array<!proto.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {!Array<!proto.User>} value
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
*/
proto.jp.singcolor.ListUsersResponse.prototype.setUsersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.User}
 */
proto.jp.singcolor.ListUsersResponse.prototype.addUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
 */
proto.jp.singcolor.ListUsersResponse.prototype.clearUsersList = function () {
    return this.setUsersList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListUsersResponse.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
 */
proto.jp.singcolor.ListUsersResponse.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListUsersResponse.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
 */
proto.jp.singcolor.ListUsersResponse.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListUsersResponse.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
 */
proto.jp.singcolor.ListUsersResponse.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListUsersResponse.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
 */
proto.jp.singcolor.ListUsersResponse.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListUsersResponse.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListUsersResponse} returns this
 */
proto.jp.singcolor.ListUsersResponse.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetServerRequestedTimeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetServerRequestedTimeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetServerRequestedTimeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetServerRequestedTimeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetServerRequestedTimeRequest}
 */
proto.jp.singcolor.GetServerRequestedTimeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetServerRequestedTimeRequest;
    return proto.jp.singcolor.GetServerRequestedTimeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetServerRequestedTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetServerRequestedTimeRequest}
 */
proto.jp.singcolor.GetServerRequestedTimeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetServerRequestedTimeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetServerRequestedTimeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetServerRequestedTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetServerRequestedTimeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetServerRequestedTimeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetServerRequestedTimeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetServerRequestedTimeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetServerRequestedTimeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            requestedTime: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetServerRequestedTimeResponse}
 */
proto.jp.singcolor.GetServerRequestedTimeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetServerRequestedTimeResponse;
    return proto.jp.singcolor.GetServerRequestedTimeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetServerRequestedTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetServerRequestedTimeResponse}
 */
proto.jp.singcolor.GetServerRequestedTimeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRequestedTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetServerRequestedTimeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetServerRequestedTimeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetServerRequestedTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetServerRequestedTimeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRequestedTime();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 requested_time = 1;
 * @return {number}
 */
proto.jp.singcolor.GetServerRequestedTimeResponse.prototype.getRequestedTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetServerRequestedTimeResponse} returns this
 */
proto.jp.singcolor.GetServerRequestedTimeResponse.prototype.setRequestedTime = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveServerRequestedTimeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveServerRequestedTimeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveServerRequestedTimeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveServerRequestedTimeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            requestedTime: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveServerRequestedTimeRequest}
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveServerRequestedTimeRequest;
    return proto.jp.singcolor.SaveServerRequestedTimeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveServerRequestedTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveServerRequestedTimeRequest}
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRequestedTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveServerRequestedTimeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveServerRequestedTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRequestedTime();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 requested_time = 1;
 * @return {number}
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest.prototype.getRequestedTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SaveServerRequestedTimeRequest} returns this
 */
proto.jp.singcolor.SaveServerRequestedTimeRequest.prototype.setRequestedTime = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveServerRequestedTimeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveServerRequestedTimeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveServerRequestedTimeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveServerRequestedTimeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            requestedTime: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveServerRequestedTimeResponse}
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveServerRequestedTimeResponse;
    return proto.jp.singcolor.SaveServerRequestedTimeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveServerRequestedTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveServerRequestedTimeResponse}
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRequestedTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveServerRequestedTimeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveServerRequestedTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRequestedTime();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 requested_time = 1;
 * @return {number}
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse.prototype.getRequestedTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SaveServerRequestedTimeResponse} returns this
 */
proto.jp.singcolor.SaveServerRequestedTimeResponse.prototype.setRequestedTime = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteServerRequestedTimeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteServerRequestedTimeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteServerRequestedTimeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteServerRequestedTimeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteServerRequestedTimeRequest}
 */
proto.jp.singcolor.DeleteServerRequestedTimeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteServerRequestedTimeRequest;
    return proto.jp.singcolor.DeleteServerRequestedTimeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteServerRequestedTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteServerRequestedTimeRequest}
 */
proto.jp.singcolor.DeleteServerRequestedTimeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteServerRequestedTimeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteServerRequestedTimeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteServerRequestedTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteServerRequestedTimeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteServerRequestedTimeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteServerRequestedTimeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteServerRequestedTimeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteServerRequestedTimeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            requestedTime: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteServerRequestedTimeResponse}
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteServerRequestedTimeResponse;
    return proto.jp.singcolor.DeleteServerRequestedTimeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteServerRequestedTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteServerRequestedTimeResponse}
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRequestedTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteServerRequestedTimeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteServerRequestedTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRequestedTime();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 requested_time = 1;
 * @return {number}
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse.prototype.getRequestedTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteServerRequestedTimeResponse} returns this
 */
proto.jp.singcolor.DeleteServerRequestedTimeResponse.prototype.setRequestedTime = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListenerLiveActivitiesRes.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListenerLiveActivitiesRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListenerLiveActivitiesRes.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            listenerliveactivities: (f = msg.getListenerliveactivities()) && debug_entity_pb.DebugListenerLiveActivities.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListenerLiveActivitiesRes;
    return proto.jp.singcolor.ListenerLiveActivitiesRes.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListenerLiveActivitiesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new debug_entity_pb.DebugListenerLiveActivities;
                reader.readMessage(value, debug_entity_pb.DebugListenerLiveActivities.deserializeBinaryFromReader);
                msg.setListenerliveactivities(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListenerLiveActivitiesRes.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListenerLiveActivitiesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getListenerliveactivities();
    if (f != null) {
        writer.writeMessage(2, f, debug_entity_pb.DebugListenerLiveActivities.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.User}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes} returns this
*/
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes} returns this
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional DebugListenerLiveActivities listenerLiveActivities = 2;
 * @return {?proto.jp.singcolor.DebugListenerLiveActivities}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.getListenerliveactivities = function () {
    return /** @type{?proto.jp.singcolor.DebugListenerLiveActivities} */ (jspb.Message.getWrapperField(this, debug_entity_pb.DebugListenerLiveActivities, 2));
};
/**
 * @param {?proto.jp.singcolor.DebugListenerLiveActivities|undefined} value
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes} returns this
*/
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.setListenerliveactivities = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes} returns this
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.clearListenerliveactivities = function () {
    return this.setListenerliveactivities(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListenerLiveActivitiesRes.prototype.hasListenerliveactivities = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiverLiveActivitiesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiverLiveActivitiesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiverLiveActivitiesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiverLiveActivitiesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesRequest}
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiverLiveActivitiesRequest;
    return proto.jp.singcolor.GetLiverLiveActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiverLiveActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesRequest}
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiverLiveActivitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiverLiveActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesRequest} returns this
 */
proto.jp.singcolor.GetLiverLiveActivitiesRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiverLiveActivitiesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiverLiveActivitiesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverliveactivities: (f = msg.getLiverliveactivities()) && debug_entity_pb.DebugLiverLiveActivities.toObject(includeInstance, f),
            listenerliveactivitiesresList: jspb.Message.toObjectList(msg.getListenerliveactivitiesresList(), proto.jp.singcolor.ListenerLiveActivitiesRes.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesResponse}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiverLiveActivitiesResponse;
    return proto.jp.singcolor.GetLiverLiveActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesResponse}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new debug_entity_pb.DebugLiverLiveActivities;
                reader.readMessage(value, debug_entity_pb.DebugLiverLiveActivities.deserializeBinaryFromReader);
                msg.setLiverliveactivities(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.ListenerLiveActivitiesRes;
                reader.readMessage(value, proto.jp.singcolor.ListenerLiveActivitiesRes.deserializeBinaryFromReader);
                msg.addListenerliveactivitiesres(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiverLiveActivitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverliveactivities();
    if (f != null) {
        writer.writeMessage(1, f, debug_entity_pb.DebugLiverLiveActivities.serializeBinaryToWriter);
    }
    f = message.getListenerliveactivitiesresList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.jp.singcolor.ListenerLiveActivitiesRes.serializeBinaryToWriter);
    }
};
/**
 * optional DebugLiverLiveActivities liverLiveActivities = 1;
 * @return {?proto.jp.singcolor.DebugLiverLiveActivities}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.getLiverliveactivities = function () {
    return /** @type{?proto.jp.singcolor.DebugLiverLiveActivities} */ (jspb.Message.getWrapperField(this, debug_entity_pb.DebugLiverLiveActivities, 1));
};
/**
 * @param {?proto.jp.singcolor.DebugLiverLiveActivities|undefined} value
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} returns this
*/
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.setLiverliveactivities = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} returns this
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.clearLiverliveactivities = function () {
    return this.setLiverliveactivities(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.hasLiverliveactivities = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * repeated ListenerLiveActivitiesRes listenerLiveActivitiesRes = 2;
 * @return {!Array<!proto.jp.singcolor.ListenerLiveActivitiesRes>}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.getListenerliveactivitiesresList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListenerLiveActivitiesRes>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListenerLiveActivitiesRes, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListenerLiveActivitiesRes>} value
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} returns this
*/
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.setListenerliveactivitiesresList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.ListenerLiveActivitiesRes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListenerLiveActivitiesRes}
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.addListenerliveactivitiesres = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.ListenerLiveActivitiesRes, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiverLiveActivitiesResponse} returns this
 */
proto.jp.singcolor.GetLiverLiveActivitiesResponse.prototype.clearListenerliveactivitiesresList = function () {
    return this.setListenerliveactivitiesresList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetListenerLiveActivitiesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetListenerLiveActivitiesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetListenerLiveActivitiesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetListenerLiveActivitiesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverUserId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesRequest}
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetListenerLiveActivitiesRequest;
    return proto.jp.singcolor.GetListenerLiveActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetListenerLiveActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesRequest}
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetListenerLiveActivitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetListenerLiveActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string listener_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.prototype.getListenerUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesRequest} returns this
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.prototype.setListenerUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesRequest} returns this
 */
proto.jp.singcolor.GetListenerLiveActivitiesRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetListenerLiveActivitiesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetListenerLiveActivitiesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetListenerLiveActivitiesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetListenerLiveActivitiesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerliveactivities: (f = msg.getListenerliveactivities()) && debug_entity_pb.DebugListenerLiveActivities.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesResponse}
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetListenerLiveActivitiesResponse;
    return proto.jp.singcolor.GetListenerLiveActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetListenerLiveActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesResponse}
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new debug_entity_pb.DebugListenerLiveActivities;
                reader.readMessage(value, debug_entity_pb.DebugListenerLiveActivities.deserializeBinaryFromReader);
                msg.setListenerliveactivities(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetListenerLiveActivitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetListenerLiveActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerliveactivities();
    if (f != null) {
        writer.writeMessage(1, f, debug_entity_pb.DebugListenerLiveActivities.serializeBinaryToWriter);
    }
};
/**
 * optional DebugListenerLiveActivities listenerLiveActivities = 1;
 * @return {?proto.jp.singcolor.DebugListenerLiveActivities}
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.prototype.getListenerliveactivities = function () {
    return /** @type{?proto.jp.singcolor.DebugListenerLiveActivities} */ (jspb.Message.getWrapperField(this, debug_entity_pb.DebugListenerLiveActivities, 1));
};
/**
 * @param {?proto.jp.singcolor.DebugListenerLiveActivities|undefined} value
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesResponse} returns this
*/
proto.jp.singcolor.GetListenerLiveActivitiesResponse.prototype.setListenerliveactivities = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetListenerLiveActivitiesResponse} returns this
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.prototype.clearListenerliveactivities = function () {
    return this.setListenerliveactivities(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetListenerLiveActivitiesResponse.prototype.hasListenerliveactivities = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLeagueRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLeagueRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLeagueRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLeagueRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            leagueId: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLeagueRequest}
 */
proto.jp.singcolor.UpdateLeagueRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLeagueRequest;
    return proto.jp.singcolor.UpdateLeagueRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLeagueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLeagueRequest}
 */
proto.jp.singcolor.UpdateLeagueRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeagueId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLeagueRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLeagueRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLeagueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLeagueRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLeagueId();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateLeagueRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateLeagueRequest} returns this
 */
proto.jp.singcolor.UpdateLeagueRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 league_id = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeagueRequest.prototype.getLeagueId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeagueRequest} returns this
 */
proto.jp.singcolor.UpdateLeagueRequest.prototype.setLeagueId = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLeagueResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLeagueResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLeagueResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLeagueResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            leagueId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLeagueResponse}
 */
proto.jp.singcolor.UpdateLeagueResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLeagueResponse;
    return proto.jp.singcolor.UpdateLeagueResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLeagueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLeagueResponse}
 */
proto.jp.singcolor.UpdateLeagueResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeagueId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLeagueResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLeagueResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLeagueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLeagueResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLeagueId();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 league_id = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeagueResponse.prototype.getLeagueId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeagueResponse} returns this
 */
proto.jp.singcolor.UpdateLeagueResponse.prototype.setLeagueId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddFavePointsSourceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddFavePointsSourceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddFavePointsSourceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddFavePointsSourceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            giftId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            usedCoin: jspb.Message.getFieldWithDefault(msg, 4, 0),
            viewingTimeDurationSeconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
            superLikeCount: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddFavePointsSourceRequest;
    return proto.jp.singcolor.AddFavePointsSourceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddFavePointsSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setGiftId(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUsedCoin(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setViewingTimeDurationSeconds(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSuperLikeCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddFavePointsSourceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddFavePointsSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddFavePointsSourceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getGiftId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getUsedCoin();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getViewingTimeDurationSeconds();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getSuperLikeCount();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};
/**
 * optional string listener_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.getListenerUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest} returns this
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.setListenerUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest} returns this
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string gift_id = 3;
 * @return {string}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.getGiftId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest} returns this
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.setGiftId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 used_coin = 4;
 * @return {number}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.getUsedCoin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest} returns this
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.setUsedCoin = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 viewing_time_duration_seconds = 5;
 * @return {number}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.getViewingTimeDurationSeconds = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest} returns this
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.setViewingTimeDurationSeconds = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 super_like_count = 6;
 * @return {number}
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.getSuperLikeCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddFavePointsSourceRequest} returns this
 */
proto.jp.singcolor.AddFavePointsSourceRequest.prototype.setSuperLikeCount = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddFavePointsSourceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddFavePointsSourceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddFavePointsSourceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddFavePointsSourceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddFavePointsSourceResponse}
 */
proto.jp.singcolor.AddFavePointsSourceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddFavePointsSourceResponse;
    return proto.jp.singcolor.AddFavePointsSourceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddFavePointsSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddFavePointsSourceResponse}
 */
proto.jp.singcolor.AddFavePointsSourceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddFavePointsSourceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddFavePointsSourceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddFavePointsSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddFavePointsSourceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddFaveBonusSourceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddFaveBonusSourceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddFaveBonusSourceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            monthlySongFaveCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddFaveBonusSourceRequest}
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddFaveBonusSourceRequest;
    return proto.jp.singcolor.AddFaveBonusSourceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddFaveBonusSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddFaveBonusSourceRequest}
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlySongFaveCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddFaveBonusSourceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddFaveBonusSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonthlySongFaveCount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddFaveBonusSourceRequest} returns this
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 monthly_fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddFaveBonusSourceRequest} returns this
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 monthly_song_fave_count = 3;
 * @return {number}
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.getMonthlySongFaveCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddFaveBonusSourceRequest} returns this
 */
proto.jp.singcolor.AddFaveBonusSourceRequest.prototype.setMonthlySongFaveCount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddFaveBonusSourceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddFaveBonusSourceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddFaveBonusSourceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddFaveBonusSourceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddFaveBonusSourceResponse}
 */
proto.jp.singcolor.AddFaveBonusSourceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddFaveBonusSourceResponse;
    return proto.jp.singcolor.AddFaveBonusSourceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddFaveBonusSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddFaveBonusSourceResponse}
 */
proto.jp.singcolor.AddFaveBonusSourceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddFaveBonusSourceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddFaveBonusSourceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddFaveBonusSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddFaveBonusSourceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetCoinPurchaseFailureModeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetCoinPurchaseFailureModeRequest}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetCoinPurchaseFailureModeRequest;
    return proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetCoinPurchaseFailureModeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetCoinPurchaseFailureModeRequest}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetCoinPurchaseFailureModeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetCoinPurchaseFailureModeRequest} returns this
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetCoinPurchaseFailureModeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            mode: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetCoinPurchaseFailureModeResponse}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetCoinPurchaseFailureModeResponse;
    return proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetCoinPurchaseFailureModeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetCoinPurchaseFailureModeResponse}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.CoinPurchaseFailureMode} */ (reader.readEnum());
                msg.setMode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetCoinPurchaseFailureModeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMode();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional CoinPurchaseFailureMode mode = 1;
 * @return {!proto.jp.singcolor.CoinPurchaseFailureMode}
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.prototype.getMode = function () {
    return /** @type {!proto.jp.singcolor.CoinPurchaseFailureMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.CoinPurchaseFailureMode} value
 * @return {!proto.jp.singcolor.GetCoinPurchaseFailureModeResponse} returns this
 */
proto.jp.singcolor.GetCoinPurchaseFailureModeResponse.prototype.setMode = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            mode: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest;
    return proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.CoinPurchaseFailureMode} */ (reader.readEnum());
                msg.setMode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getMode();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest} returns this
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional CoinPurchaseFailureMode mode = 2;
 * @return {!proto.jp.singcolor.CoinPurchaseFailureMode}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.prototype.getMode = function () {
    return /** @type {!proto.jp.singcolor.CoinPurchaseFailureMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.CoinPurchaseFailureMode} value
 * @return {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest} returns this
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeRequest.prototype.setMode = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse;
    return proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateCoinPurchaseFailureModeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDeviceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDeviceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDeviceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDeviceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDeviceRequest}
 */
proto.jp.singcolor.GetUserDeviceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDeviceRequest;
    return proto.jp.singcolor.GetUserDeviceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDeviceRequest}
 */
proto.jp.singcolor.GetUserDeviceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDeviceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDeviceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserDeviceRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserDeviceRequest} returns this
 */
proto.jp.singcolor.GetUserDeviceRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetUserDeviceResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDeviceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDeviceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDeviceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDeviceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userDevicesList: jspb.Message.toObjectList(msg.getUserDevicesList(), proto.jp.singcolor.GetUserDeviceResponse.UserDevice.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDeviceResponse}
 */
proto.jp.singcolor.GetUserDeviceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDeviceResponse;
    return proto.jp.singcolor.GetUserDeviceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDeviceResponse}
 */
proto.jp.singcolor.GetUserDeviceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.GetUserDeviceResponse.UserDevice;
                reader.readMessage(value, proto.jp.singcolor.GetUserDeviceResponse.UserDevice.deserializeBinaryFromReader);
                msg.addUserDevices(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDeviceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDeviceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserDevicesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.GetUserDeviceResponse.UserDevice.serializeBinaryToWriter);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDeviceResponse.UserDevice.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDeviceResponse.UserDevice.toObject = function (includeInstance, msg) {
        var f, obj = {
            deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            permitMultipleUser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice}
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDeviceResponse.UserDevice;
    return proto.jp.singcolor.GetUserDeviceResponse.UserDevice.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice}
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeviceId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPermitMultipleUser(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDeviceResponse.UserDevice.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDeviceId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getPermitMultipleUser();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.getDeviceId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice} returns this
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.setDeviceId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice} returns this
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool permit_multiple_user = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.getPermitMultipleUser = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice} returns this
 */
proto.jp.singcolor.GetUserDeviceResponse.UserDevice.prototype.setPermitMultipleUser = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * repeated UserDevice user_devices = 1;
 * @return {!Array<!proto.jp.singcolor.GetUserDeviceResponse.UserDevice>}
 */
proto.jp.singcolor.GetUserDeviceResponse.prototype.getUserDevicesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.GetUserDeviceResponse.UserDevice>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.GetUserDeviceResponse.UserDevice, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.GetUserDeviceResponse.UserDevice>} value
 * @return {!proto.jp.singcolor.GetUserDeviceResponse} returns this
*/
proto.jp.singcolor.GetUserDeviceResponse.prototype.setUserDevicesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.GetUserDeviceResponse.UserDevice}
 */
proto.jp.singcolor.GetUserDeviceResponse.prototype.addUserDevices = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.GetUserDeviceResponse.UserDevice, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetUserDeviceResponse} returns this
 */
proto.jp.singcolor.GetUserDeviceResponse.prototype.clearUserDevicesList = function () {
    return this.setUserDevicesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserDeviceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserDeviceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserDeviceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserDeviceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            permitMultipleUser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserDeviceRequest}
 */
proto.jp.singcolor.UpdateUserDeviceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserDeviceRequest;
    return proto.jp.singcolor.UpdateUserDeviceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserDeviceRequest}
 */
proto.jp.singcolor.UpdateUserDeviceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeviceId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPermitMultipleUser(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserDeviceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDeviceId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getPermitMultipleUser();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserDeviceRequest} returns this
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.getDeviceId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserDeviceRequest} returns this
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.setDeviceId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool permit_multiple_user = 3;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.getPermitMultipleUser = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserDeviceRequest} returns this
 */
proto.jp.singcolor.UpdateUserDeviceRequest.prototype.setPermitMultipleUser = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserDeviceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserDeviceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserDeviceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserDeviceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserDeviceResponse}
 */
proto.jp.singcolor.UpdateUserDeviceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserDeviceResponse;
    return proto.jp.singcolor.UpdateUserDeviceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserDeviceResponse}
 */
proto.jp.singcolor.UpdateUserDeviceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserDeviceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserDeviceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondAcquisitionRightRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondAcquisitionRightRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondAcquisitionRightRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondAcquisitionRightRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondAcquisitionRightRequest;
    return proto.jp.singcolor.GetDiamondAcquisitionRightRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondAcquisitionRightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondAcquisitionRightRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondAcquisitionRightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.GetDiamondAcquisitionRightRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondAcquisitionRightResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondAcquisitionRightResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            singAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
            chatAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondAcquisitionRightResponse;
    return proto.jp.singcolor.GetDiamondAcquisitionRightResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setSingAmount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setChatAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondAcquisitionRightResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSingAmount();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getChatAmount();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional double sing_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.getSingAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.setSingAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};
/**
 * optional double chat_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.getChatAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.GetDiamondAcquisitionRightResponse.prototype.setChatAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            singAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
            chatAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest;
    return proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setSingAmount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setChatAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSingAmount();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getChatAmount();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional double sing_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.getSingAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.setSingAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};
/**
 * optional double chat_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.getChatAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightRequest.prototype.setChatAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            singAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
            chatAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse;
    return proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setSingAmount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setChatAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSingAmount();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getChatAmount();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional double sing_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.getSingAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.setSingAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};
/**
 * optional double chat_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.getChatAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.UpdateDiamondAcquisitionRightResponse.prototype.setChatAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateMultiLivesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateMultiLivesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateMultiLivesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateMultiLivesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            beginAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateMultiLivesRequest}
 */
proto.jp.singcolor.CreateMultiLivesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateMultiLivesRequest;
    return proto.jp.singcolor.CreateMultiLivesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateMultiLivesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateMultiLivesRequest}
 */
proto.jp.singcolor.CreateMultiLivesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeginAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateMultiLivesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateMultiLivesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateMultiLivesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateMultiLivesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getBeginAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateMultiLivesRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateMultiLivesRequest} returns this
 */
proto.jp.singcolor.CreateMultiLivesRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 begin_at = 2;
 * @return {number}
 */
proto.jp.singcolor.CreateMultiLivesRequest.prototype.getBeginAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateMultiLivesRequest} returns this
 */
proto.jp.singcolor.CreateMultiLivesRequest.prototype.setBeginAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateMultiLivesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateMultiLivesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateMultiLivesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateMultiLivesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateMultiLivesResponse}
 */
proto.jp.singcolor.CreateMultiLivesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateMultiLivesResponse;
    return proto.jp.singcolor.CreateMultiLivesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateMultiLivesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateMultiLivesResponse}
 */
proto.jp.singcolor.CreateMultiLivesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateMultiLivesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateMultiLivesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateMultiLivesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateMultiLivesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest;
    return proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest} returns this
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse;
    return proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestUserDiamondDistributedDayResponse.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest;
    return proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayRequest.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse;
    return proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse} returns this
 */
proto.jp.singcolor.UpdateLatestUserDiamondDistributedDayResponse.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunDistributeDiamondCommandRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunDistributeDiamondCommandRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunDistributeDiamondCommandRequest;
    return proto.jp.singcolor.RunDistributeDiamondCommandRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunDistributeDiamondCommandRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeDiamondCommandRequest.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunDistributeDiamondCommandResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunDistributeDiamondCommandResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunDistributeDiamondCommandResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunDistributeDiamondCommandResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandResponse}
 */
proto.jp.singcolor.RunDistributeDiamondCommandResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunDistributeDiamondCommandResponse;
    return proto.jp.singcolor.RunDistributeDiamondCommandResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunDistributeDiamondCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunDistributeDiamondCommandResponse}
 */
proto.jp.singcolor.RunDistributeDiamondCommandResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunDistributeDiamondCommandResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunDistributeDiamondCommandResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunDistributeDiamondCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunDistributeDiamondCommandResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            year: jspb.Message.getFieldWithDefault(msg, 1, 0),
            month: jspb.Message.getFieldWithDefault(msg, 2, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 4, 0),
            leagueId: jspb.Message.getFieldWithDefault(msg, 5, 0),
            closingTimeJst: jspb.Message.getFieldWithDefault(msg, 6, 0),
            borderLiveScore1: jspb.Message.getFieldWithDefault(msg, 7, 0),
            borderLiveScore2: jspb.Message.getFieldWithDefault(msg, 8, 0),
            borderLiveScore3: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest;
    return proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 3:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeagueId(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setClosingTimeJst(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore1(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore2(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore3(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getLeagueId();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getClosingTimeJst();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getBorderLiveScore1();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getBorderLiveScore2();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getBorderLiveScore3();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
};
/**
 * optional int64 year = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 month = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional TermOfMonth term_of_month = 3;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 day_of_term = 4;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 league_id = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getLeagueId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setLeagueId = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 closing_time_jst = 6;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getClosingTimeJst = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setClosingTimeJst = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 border_live_score1 = 7;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getBorderLiveScore1 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setBorderLiveScore1 = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 border_live_score2 = 8;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getBorderLiveScore2 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setBorderLiveScore2 = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 border_live_score3 = 9;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.getBorderLiveScore3 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeRequest.prototype.setBorderLiveScore3 = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            borderLiveScore1: jspb.Message.getFieldWithDefault(msg, 1, 0),
            borderLiveScore2: jspb.Message.getFieldWithDefault(msg, 2, 0),
            borderLiveScore3: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse;
    return proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore1(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore2(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore3(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBorderLiveScore1();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getBorderLiveScore2();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getBorderLiveScore3();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional int64 border_live_score1 = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.getBorderLiveScore1 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.setBorderLiveScore1 = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 border_live_score2 = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.getBorderLiveScore2 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.setBorderLiveScore2 = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 border_live_score3 = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.getBorderLiveScore3 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse} returns this
 */
proto.jp.singcolor.UpdateLeaguePromotionBorderAtClosingTimeResponse.prototype.setBorderLiveScore3 = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RecalculateLiverLeagueRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RecalculateLiverLeagueRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RecalculateLiverLeagueRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RecalculateLiverLeagueRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueRequest}
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RecalculateLiverLeagueRequest;
    return proto.jp.singcolor.RecalculateLiverLeagueRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RecalculateLiverLeagueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueRequest}
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RecalculateLiverLeagueRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RecalculateLiverLeagueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueRequest} returns this
 */
proto.jp.singcolor.RecalculateLiverLeagueRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RecalculateLiverLeagueResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RecalculateLiverLeagueResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RecalculateLiverLeagueResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RecalculateLiverLeagueResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            leagueId: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueResponse}
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RecalculateLiverLeagueResponse;
    return proto.jp.singcolor.RecalculateLiverLeagueResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RecalculateLiverLeagueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueResponse}
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeagueId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RecalculateLiverLeagueResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RecalculateLiverLeagueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLeagueId();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueResponse} returns this
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 league_id = 2;
 * @return {number}
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.prototype.getLeagueId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.RecalculateLiverLeagueResponse} returns this
 */
proto.jp.singcolor.RecalculateLiverLeagueResponse.prototype.setLeagueId = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondFundRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondFundRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondFundRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondFundRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondFundRequest}
 */
proto.jp.singcolor.GetDiamondFundRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondFundRequest;
    return proto.jp.singcolor.GetDiamondFundRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondFundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondFundRequest}
 */
proto.jp.singcolor.GetDiamondFundRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondFundRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondFundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondFundRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondFundRequest} returns this
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondFundRequest} returns this
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondFundRequest} returns this
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.GetDiamondFundRequest} returns this
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondFundRequest} returns this
 */
proto.jp.singcolor.GetDiamondFundRequest.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondFundResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondFundResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondFundResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondFundResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            dayAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
            accumulationAmount: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondFundResponse}
 */
proto.jp.singcolor.GetDiamondFundResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondFundResponse;
    return proto.jp.singcolor.GetDiamondFundResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondFundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondFundResponse}
 */
proto.jp.singcolor.GetDiamondFundResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayAmount(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAccumulationAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondFundResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondFundResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondFundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondFundResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDayAmount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getAccumulationAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional int64 day_amount = 1;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondFundResponse.prototype.getDayAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondFundResponse} returns this
 */
proto.jp.singcolor.GetDiamondFundResponse.prototype.setDayAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 accumulation_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondFundResponse.prototype.getAccumulationAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondFundResponse} returns this
 */
proto.jp.singcolor.GetDiamondFundResponse.prototype.setAccumulationAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OverwriteDiamondFundRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OverwriteDiamondFundRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OverwriteDiamondFundRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            dayAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            accumulationAmount: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OverwriteDiamondFundRequest}
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OverwriteDiamondFundRequest;
    return proto.jp.singcolor.OverwriteDiamondFundRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OverwriteDiamondFundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OverwriteDiamondFundRequest}
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayAmount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAccumulationAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OverwriteDiamondFundRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OverwriteDiamondFundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDayAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getAccumulationAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OverwriteDiamondFundRequest} returns this
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 day_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.getDayAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OverwriteDiamondFundRequest} returns this
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.setDayAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 accumulation_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.getAccumulationAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OverwriteDiamondFundRequest} returns this
 */
proto.jp.singcolor.OverwriteDiamondFundRequest.prototype.setAccumulationAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OverwriteDiamondFundResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OverwriteDiamondFundResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OverwriteDiamondFundResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            dayAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            accumulationAmount: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OverwriteDiamondFundResponse}
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OverwriteDiamondFundResponse;
    return proto.jp.singcolor.OverwriteDiamondFundResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OverwriteDiamondFundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OverwriteDiamondFundResponse}
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayAmount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAccumulationAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OverwriteDiamondFundResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OverwriteDiamondFundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDayAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getAccumulationAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OverwriteDiamondFundResponse} returns this
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 day_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.getDayAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OverwriteDiamondFundResponse} returns this
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.setDayAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 accumulation_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.getAccumulationAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OverwriteDiamondFundResponse} returns this
 */
proto.jp.singcolor.OverwriteDiamondFundResponse.prototype.setAccumulationAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteDiamondFundOverwriteRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteDiamondFundOverwriteRequest}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteDiamondFundOverwriteRequest;
    return proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteDiamondFundOverwriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteDiamondFundOverwriteRequest}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteDiamondFundOverwriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteDiamondFundOverwriteRequest} returns this
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteDiamondFundOverwriteResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteDiamondFundOverwriteResponse}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteDiamondFundOverwriteResponse;
    return proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteDiamondFundOverwriteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteDiamondFundOverwriteResponse}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteDiamondFundOverwriteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteDiamondFundOverwriteResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest;
    return proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string liver_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.getLiverUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.setLiverUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryRequest.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse;
    return proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLiverReceivedCoinHistoryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSingHistoriesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSingHistoriesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSingHistoriesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSingHistoriesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSingHistoriesRequest}
 */
proto.jp.singcolor.ListSingHistoriesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSingHistoriesRequest;
    return proto.jp.singcolor.ListSingHistoriesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSingHistoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSingHistoriesRequest}
 */
proto.jp.singcolor.ListSingHistoriesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSingHistoriesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSingHistoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSingHistoriesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingHistoriesRequest} returns this
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingHistoriesRequest} returns this
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingHistoriesRequest} returns this
 */
proto.jp.singcolor.ListSingHistoriesRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSingHistoriesResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSingHistoriesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSingHistoriesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSingHistoriesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSingHistoriesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            singHistoriesList: jspb.Message.toObjectList(msg.getSingHistoriesList(), debug_entity_pb.SingHistory.toObject, includeInstance),
            prevPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSingHistoriesResponse}
 */
proto.jp.singcolor.ListSingHistoriesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSingHistoriesResponse;
    return proto.jp.singcolor.ListSingHistoriesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSingHistoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSingHistoriesResponse}
 */
proto.jp.singcolor.ListSingHistoriesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new debug_entity_pb.SingHistory;
                reader.readMessage(value, debug_entity_pb.SingHistory.deserializeBinaryFromReader);
                msg.addSingHistories(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPrevPageToken(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSingHistoriesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSingHistoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSingHistoriesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, debug_entity_pb.SingHistory.serializeBinaryToWriter);
    }
    f = message.getPrevPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * repeated SingHistory sing_histories = 1;
 * @return {!Array<!proto.jp.singcolor.SingHistory>}
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.getSingHistoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.SingHistory>} */ (jspb.Message.getRepeatedWrapperField(this, debug_entity_pb.SingHistory, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.SingHistory>} value
 * @return {!proto.jp.singcolor.ListSingHistoriesResponse} returns this
*/
proto.jp.singcolor.ListSingHistoriesResponse.prototype.setSingHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.SingHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SingHistory}
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.addSingHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.SingHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSingHistoriesResponse} returns this
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.clearSingHistoriesList = function () {
    return this.setSingHistoriesList([]);
};
/**
 * optional string prev_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.getPrevPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingHistoriesResponse} returns this
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.setPrevPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingHistoriesResponse} returns this
 */
proto.jp.singcolor.ListSingHistoriesResponse.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateSingHistoryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateSingHistoryRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateSingHistoryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateSingHistoryRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            singHistory: (f = msg.getSingHistory()) && debug_entity_pb.SingHistory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateSingHistoryRequest}
 */
proto.jp.singcolor.UpdateSingHistoryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateSingHistoryRequest;
    return proto.jp.singcolor.UpdateSingHistoryRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateSingHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateSingHistoryRequest}
 */
proto.jp.singcolor.UpdateSingHistoryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new debug_entity_pb.SingHistory;
                reader.readMessage(value, debug_entity_pb.SingHistory.deserializeBinaryFromReader);
                msg.setSingHistory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateSingHistoryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateSingHistoryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateSingHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateSingHistoryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingHistory();
    if (f != null) {
        writer.writeMessage(1, f, debug_entity_pb.SingHistory.serializeBinaryToWriter);
    }
};
/**
 * optional SingHistory sing_history = 1;
 * @return {?proto.jp.singcolor.SingHistory}
 */
proto.jp.singcolor.UpdateSingHistoryRequest.prototype.getSingHistory = function () {
    return /** @type{?proto.jp.singcolor.SingHistory} */ (jspb.Message.getWrapperField(this, debug_entity_pb.SingHistory, 1));
};
/**
 * @param {?proto.jp.singcolor.SingHistory|undefined} value
 * @return {!proto.jp.singcolor.UpdateSingHistoryRequest} returns this
*/
proto.jp.singcolor.UpdateSingHistoryRequest.prototype.setSingHistory = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateSingHistoryRequest} returns this
 */
proto.jp.singcolor.UpdateSingHistoryRequest.prototype.clearSingHistory = function () {
    return this.setSingHistory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateSingHistoryRequest.prototype.hasSingHistory = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateSingHistoryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateSingHistoryResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateSingHistoryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateSingHistoryResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            singHistory: (f = msg.getSingHistory()) && debug_entity_pb.SingHistory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateSingHistoryResponse}
 */
proto.jp.singcolor.UpdateSingHistoryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateSingHistoryResponse;
    return proto.jp.singcolor.UpdateSingHistoryResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateSingHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateSingHistoryResponse}
 */
proto.jp.singcolor.UpdateSingHistoryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new debug_entity_pb.SingHistory;
                reader.readMessage(value, debug_entity_pb.SingHistory.deserializeBinaryFromReader);
                msg.setSingHistory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateSingHistoryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateSingHistoryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateSingHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateSingHistoryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingHistory();
    if (f != null) {
        writer.writeMessage(1, f, debug_entity_pb.SingHistory.serializeBinaryToWriter);
    }
};
/**
 * optional SingHistory sing_history = 1;
 * @return {?proto.jp.singcolor.SingHistory}
 */
proto.jp.singcolor.UpdateSingHistoryResponse.prototype.getSingHistory = function () {
    return /** @type{?proto.jp.singcolor.SingHistory} */ (jspb.Message.getWrapperField(this, debug_entity_pb.SingHistory, 1));
};
/**
 * @param {?proto.jp.singcolor.SingHistory|undefined} value
 * @return {!proto.jp.singcolor.UpdateSingHistoryResponse} returns this
*/
proto.jp.singcolor.UpdateSingHistoryResponse.prototype.setSingHistory = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateSingHistoryResponse} returns this
 */
proto.jp.singcolor.UpdateSingHistoryResponse.prototype.clearSingHistory = function () {
    return this.setSingHistory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateSingHistoryResponse.prototype.hasSingHistory = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteSingHistoryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteSingHistoryRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteSingHistoryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteSingHistoryRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userSingHistoryId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteSingHistoryRequest}
 */
proto.jp.singcolor.DeleteSingHistoryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteSingHistoryRequest;
    return proto.jp.singcolor.DeleteSingHistoryRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteSingHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteSingHistoryRequest}
 */
proto.jp.singcolor.DeleteSingHistoryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserSingHistoryId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteSingHistoryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteSingHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteSingHistoryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserSingHistoryId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteSingHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteSingHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_sing_history_id = 3;
 * @return {string}
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.getUserSingHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteSingHistoryRequest} returns this
 */
proto.jp.singcolor.DeleteSingHistoryRequest.prototype.setUserSingHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteSingHistoryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteSingHistoryResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteSingHistoryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteSingHistoryResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteSingHistoryResponse}
 */
proto.jp.singcolor.DeleteSingHistoryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteSingHistoryResponse;
    return proto.jp.singcolor.DeleteSingHistoryResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteSingHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteSingHistoryResponse}
 */
proto.jp.singcolor.DeleteSingHistoryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteSingHistoryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteSingHistoryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteSingHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteSingHistoryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest;
    return proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            usedSingDiamondAcquisitionRight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse;
    return proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setUsedSingDiamondAcquisitionRight(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUsedSingDiamondAcquisitionRight();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional double used_sing_diamond_acquisition_right = 2;
 * @return {number}
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.prototype.getUsedSingDiamondAcquisitionRight = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.GetUsedSingDiamondAcquisitionRightResponse.prototype.setUsedSingDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            usedSingDiamondAcquisitionRight: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest;
    return proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setUsedSingDiamondAcquisitionRight(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getUsedSingDiamondAcquisitionRight();
    if (f !== 0.0) {
        writer.writeDouble(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional double used_sing_diamond_acquisition_right = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.getUsedSingDiamondAcquisitionRight = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightRequest.prototype.setUsedSingDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3FloatField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            usedSingDiamondAcquisitionRight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse;
    return proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setUsedSingDiamondAcquisitionRight(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUsedSingDiamondAcquisitionRight();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional double used_sing_diamond_acquisition_right = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.prototype.getUsedSingDiamondAcquisitionRight = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse} returns this
 */
proto.jp.singcolor.UpdateUsedSingDiamondAcquisitionRightResponse.prototype.setUsedSingDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondBalanceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondBalanceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondBalanceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondBalanceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondBalanceRequest}
 */
proto.jp.singcolor.GetDiamondBalanceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondBalanceRequest;
    return proto.jp.singcolor.GetDiamondBalanceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondBalanceRequest}
 */
proto.jp.singcolor.GetDiamondBalanceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondBalanceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondBalanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondBalanceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondBalanceRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceRequest} returns this
 */
proto.jp.singcolor.GetDiamondBalanceRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondBalanceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondBalanceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondBalanceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondBalanceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondBalanceResponse}
 */
proto.jp.singcolor.GetDiamondBalanceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondBalanceResponse;
    return proto.jp.singcolor.GetDiamondBalanceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondBalanceResponse}
 */
proto.jp.singcolor.GetDiamondBalanceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondBalanceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondBalanceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceResponse} returns this
 */
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional DiamondBalance diamond_balance = 2;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceResponse} returns this
*/
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetDiamondBalanceResponse} returns this
 */
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetDiamondBalanceResponse.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddDiamondRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddDiamondRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddDiamondRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddDiamondRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            diamondType: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddDiamondRequest}
 */
proto.jp.singcolor.AddDiamondRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddDiamondRequest;
    return proto.jp.singcolor.AddDiamondRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddDiamondRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddDiamondRequest}
 */
proto.jp.singcolor.AddDiamondRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.DiamondType} */ (reader.readEnum());
                msg.setDiamondType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddDiamondRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddDiamondRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddDiamondRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddDiamondRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.AddDiamondRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddDiamondRequest} returns this
 */
proto.jp.singcolor.AddDiamondRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.jp.singcolor.AddDiamondRequest.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.AddDiamondRequest} returns this
 */
proto.jp.singcolor.AddDiamondRequest.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional DiamondType diamond_type = 3;
 * @return {!proto.jp.singcolor.DiamondType}
 */
proto.jp.singcolor.AddDiamondRequest.prototype.getDiamondType = function () {
    return /** @type {!proto.jp.singcolor.DiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.DiamondType} value
 * @return {!proto.jp.singcolor.AddDiamondRequest} returns this
 */
proto.jp.singcolor.AddDiamondRequest.prototype.setDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddDiamondResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddDiamondResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddDiamondResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddDiamondResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddDiamondResponse}
 */
proto.jp.singcolor.AddDiamondResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddDiamondResponse;
    return proto.jp.singcolor.AddDiamondResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddDiamondResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddDiamondResponse}
 */
proto.jp.singcolor.AddDiamondResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddDiamondResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddDiamondResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddDiamondResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddDiamondResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.AddDiamondResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddDiamondResponse} returns this
 */
proto.jp.singcolor.AddDiamondResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional DiamondBalance diamond_balance = 2;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.AddDiamondResponse.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.AddDiamondResponse} returns this
*/
proto.jp.singcolor.AddDiamondResponse.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.AddDiamondResponse} returns this
 */
proto.jp.singcolor.AddDiamondResponse.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.AddDiamondResponse.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTimelineDummyDataSettingRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTimelineDummyDataSettingRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTimelineDummyDataSettingRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTimelineDummyDataSettingRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTimelineDummyDataSettingRequest}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTimelineDummyDataSettingRequest;
    return proto.jp.singcolor.GetTimelineDummyDataSettingRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTimelineDummyDataSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTimelineDummyDataSettingRequest}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTimelineDummyDataSettingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTimelineDummyDataSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTimelineDummyDataSettingRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTimelineDummyDataSettingResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTimelineDummyDataSettingResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTimelineDummyDataSettingResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTimelineDummyDataSettingResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            fillToCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTimelineDummyDataSettingResponse}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTimelineDummyDataSettingResponse;
    return proto.jp.singcolor.GetTimelineDummyDataSettingResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTimelineDummyDataSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTimelineDummyDataSettingResponse}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFillToCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTimelineDummyDataSettingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTimelineDummyDataSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFillToCount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 fill_to_count = 1;
 * @return {number}
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse.prototype.getFillToCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetTimelineDummyDataSettingResponse} returns this
 */
proto.jp.singcolor.GetTimelineDummyDataSettingResponse.prototype.setFillToCount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            fillToCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest;
    return proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFillToCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFillToCount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 fill_to_count = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.prototype.getFillToCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest} returns this
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingRequest.prototype.setFillToCount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse;
    return proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateTimelineDummyDataSettingResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest;
    return proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest} returns this
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest} returns this
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest} returns this
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondBalanceInOutHistoriesList: jspb.Message.toObjectList(msg.getDiamondBalanceInOutHistoriesList(), debug_entity_pb.DebugDiamondBalanceInOutHistory.toObject, includeInstance),
            prevPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse;
    return proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new debug_entity_pb.DebugDiamondBalanceInOutHistory;
                reader.readMessage(value, debug_entity_pb.DebugDiamondBalanceInOutHistory.deserializeBinaryFromReader);
                msg.addDiamondBalanceInOutHistories(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPrevPageToken(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondBalanceInOutHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, debug_entity_pb.DebugDiamondBalanceInOutHistory.serializeBinaryToWriter);
    }
    f = message.getPrevPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * repeated DebugDiamondBalanceInOutHistory diamond_balance_in_out_histories = 1;
 * @return {!Array<!proto.jp.singcolor.DebugDiamondBalanceInOutHistory>}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.getDiamondBalanceInOutHistoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.DebugDiamondBalanceInOutHistory>} */ (jspb.Message.getRepeatedWrapperField(this, debug_entity_pb.DebugDiamondBalanceInOutHistory, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.DebugDiamondBalanceInOutHistory>} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} returns this
*/
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.setDiamondBalanceInOutHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.addDiamondBalanceInOutHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.DebugDiamondBalanceInOutHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} returns this
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.clearDiamondBalanceInOutHistoriesList = function () {
    return this.setDiamondBalanceInOutHistoriesList([]);
};
/**
 * optional string prev_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.getPrevPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} returns this
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.setPrevPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse} returns this
 */
proto.jp.singcolor.GetDiamondBalanceInOutHistoriesResponse.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request;
    return proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request} returns this
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverPromotionMeter: (f = msg.getLiverPromotionMeter()) && leagues_entity_pb.LiverPromotionMeter.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response;
    return proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new leagues_entity_pb.LiverPromotionMeter;
                reader.readMessage(value, leagues_entity_pb.LiverPromotionMeter.deserializeBinaryFromReader);
                msg.setLiverPromotionMeter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverPromotionMeter();
    if (f != null) {
        writer.writeMessage(1, f, leagues_entity_pb.LiverPromotionMeter.serializeBinaryToWriter);
    }
};
/**
 * optional LiverPromotionMeter liver_promotion_meter = 1;
 * @return {?proto.LiverPromotionMeter}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.prototype.getLiverPromotionMeter = function () {
    return /** @type{?proto.LiverPromotionMeter} */ (jspb.Message.getWrapperField(this, leagues_entity_pb.LiverPromotionMeter, 1));
};
/**
 * @param {?proto.LiverPromotionMeter|undefined} value
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response} returns this
*/
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.prototype.setLiverPromotionMeter = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response} returns this
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.prototype.clearLiverPromotionMeter = function () {
    return this.setLiverPromotionMeter(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DebugGetLiverPromotionMeterV1Response.prototype.hasLiverPromotionMeter = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteUserBanHistoriesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteUserBanHistoriesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteUserBanHistoriesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteUserBanHistoriesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteUserBanHistoriesRequest}
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteUserBanHistoriesRequest;
    return proto.jp.singcolor.DeleteUserBanHistoriesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteUserBanHistoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteUserBanHistoriesRequest}
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteUserBanHistoriesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteUserBanHistoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteUserBanHistoriesRequest} returns this
 */
proto.jp.singcolor.DeleteUserBanHistoriesRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteUserBanHistoriesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteUserBanHistoriesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteUserBanHistoriesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteUserBanHistoriesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteUserBanHistoriesResponse}
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteUserBanHistoriesResponse;
    return proto.jp.singcolor.DeleteUserBanHistoriesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteUserBanHistoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteUserBanHistoriesResponse}
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteUserBanHistoriesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteUserBanHistoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteUserBanHistoriesResponse} returns this
 */
proto.jp.singcolor.DeleteUserBanHistoriesResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest;
    return proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            ignoreLiveAvailableTime: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse;
    return proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIgnoreLiveAvailableTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIgnoreLiveAvailableTime();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool ignore_live_available_time = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.prototype.getIgnoreLiveAvailableTime = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse} returns this
 */
proto.jp.singcolor.GetIgnoreLiveAvailableTimeResponse.prototype.setIgnoreLiveAvailableTime = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            ignoreLiveAvailableTime: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest;
    return proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIgnoreLiveAvailableTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIgnoreLiveAvailableTime();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool ignore_live_available_time = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.prototype.getIgnoreLiveAvailableTime = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest} returns this
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeRequest.prototype.setIgnoreLiveAvailableTime = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            ignoreLiveAvailableTime: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse;
    return proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIgnoreLiveAvailableTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIgnoreLiveAvailableTime();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool ignore_live_available_time = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.prototype.getIgnoreLiveAvailableTime = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse} returns this
 */
proto.jp.singcolor.UpdateIgnoreLiveAvailableTimeResponse.prototype.setIgnoreLiveAvailableTime = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            gmoAozoraMockType: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest;
    return proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.GMOAozoraMockType} */ (reader.readEnum());
                msg.setGmoAozoraMockType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGmoAozoraMockType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.GMOAozoraMockType = {
    GMO_AOZORA_MOCK_TYPE_UNKNOWN: 0,
    GMO_AOZORA_MOCK_TYPE_SUCCESS: 1,
    GMO_AOZORA_MOCK_TYPE_FAILURE: 2,
    GMO_AOZORA_MOCK_TYPE_RANDOM: 3
};
/**
 * optional GMOAozoraMockType gmo_aozora_mock_type = 1;
 * @return {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.GMOAozoraMockType}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.prototype.getGmoAozoraMockType = function () {
    return /** @type {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.GMOAozoraMockType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.GMOAozoraMockType} value
 * @return {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest} returns this
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest.prototype.setGmoAozoraMockType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse;
    return proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunUpdateWithdrawalDiamondLedgersLast2WeeksResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest;
    return proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceSuperLikeBonusCoinSetting: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse;
    return proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} */ (reader.readEnum());
                msg.setForceSuperLikeBonusCoinSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceSuperLikeBonusCoinSetting();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional ForceSuperLikeBonusCoinSetting force_super_like_bonus_coin_setting = 1;
 * @return {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting}
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.prototype.getForceSuperLikeBonusCoinSetting = function () {
    return /** @type {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} value
 * @return {!proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse} returns this
 */
proto.jp.singcolor.GetForceSuperLikeBonusCoinSettingResponse.prototype.setForceSuperLikeBonusCoinSetting = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceSuperLikeBonusCoinSetting: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest;
    return proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} */ (reader.readEnum());
                msg.setForceSuperLikeBonusCoinSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceSuperLikeBonusCoinSetting();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional ForceSuperLikeBonusCoinSetting force_super_like_bonus_coin_setting = 1;
 * @return {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.prototype.getForceSuperLikeBonusCoinSetting = function () {
    return /** @type {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} value
 * @return {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest} returns this
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingRequest.prototype.setForceSuperLikeBonusCoinSetting = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceSuperLikeBonusCoinSetting: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse;
    return proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} */ (reader.readEnum());
                msg.setForceSuperLikeBonusCoinSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceSuperLikeBonusCoinSetting();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional ForceSuperLikeBonusCoinSetting force_super_like_bonus_coin_setting = 1;
 * @return {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting}
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.prototype.getForceSuperLikeBonusCoinSetting = function () {
    return /** @type {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.ForceSuperLikeBonusCoinSetting} value
 * @return {!proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse} returns this
 */
proto.jp.singcolor.SaveForceSuperLikeBonusCoinSettingResponse.prototype.setForceSuperLikeBonusCoinSetting = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveForceUseCSSRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveForceUseCSSRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveForceUseCSSRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveForceUseCSSRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceUseCss: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveForceUseCSSRequest}
 */
proto.jp.singcolor.SaveForceUseCSSRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveForceUseCSSRequest;
    return proto.jp.singcolor.SaveForceUseCSSRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveForceUseCSSRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveForceUseCSSRequest}
 */
proto.jp.singcolor.SaveForceUseCSSRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setForceUseCss(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveForceUseCSSRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveForceUseCSSRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveForceUseCSSRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveForceUseCSSRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceUseCss();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool force_use_css = 1;
 * @return {boolean}
 */
proto.jp.singcolor.SaveForceUseCSSRequest.prototype.getForceUseCss = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.SaveForceUseCSSRequest} returns this
 */
proto.jp.singcolor.SaveForceUseCSSRequest.prototype.setForceUseCss = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveForceUseCSSResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveForceUseCSSResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveForceUseCSSResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveForceUseCSSResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveForceUseCSSResponse}
 */
proto.jp.singcolor.SaveForceUseCSSResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveForceUseCSSResponse;
    return proto.jp.singcolor.SaveForceUseCSSResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveForceUseCSSResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveForceUseCSSResponse}
 */
proto.jp.singcolor.SaveForceUseCSSResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveForceUseCSSResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveForceUseCSSResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveForceUseCSSResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveForceUseCSSResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunSubtractDiamondCommandRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunSubtractDiamondCommandRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunSubtractDiamondCommandRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunSubtractDiamondCommandRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunSubtractDiamondCommandRequest}
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunSubtractDiamondCommandRequest;
    return proto.jp.singcolor.RunSubtractDiamondCommandRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunSubtractDiamondCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunSubtractDiamondCommandRequest}
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunSubtractDiamondCommandRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunSubtractDiamondCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RunSubtractDiamondCommandRequest} returns this
 */
proto.jp.singcolor.RunSubtractDiamondCommandRequest.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunSubtractDiamondCommandResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunSubtractDiamondCommandResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunSubtractDiamondCommandResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunSubtractDiamondCommandResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunSubtractDiamondCommandResponse}
 */
proto.jp.singcolor.RunSubtractDiamondCommandResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunSubtractDiamondCommandResponse;
    return proto.jp.singcolor.RunSubtractDiamondCommandResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunSubtractDiamondCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunSubtractDiamondCommandResponse}
 */
proto.jp.singcolor.RunSubtractDiamondCommandResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunSubtractDiamondCommandResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunSubtractDiamondCommandResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunSubtractDiamondCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunSubtractDiamondCommandResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlCupId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Request}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DistributePearlByPearlCupResultV1Request;
    return proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Request}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPearlCupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlCupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string pearl_cup_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.prototype.getPearlCupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Request} returns this
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Request.prototype.setPearlCupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Response}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DistributePearlByPearlCupResultV1Response;
    return proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Response}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DistributePearlByPearlCupResultV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DistributePearlByPearlCupResultV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetForceCapturePossibilityRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetForceCapturePossibilityRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetForceCapturePossibilityRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetForceCapturePossibilityRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetForceCapturePossibilityRequest}
 */
proto.jp.singcolor.GetForceCapturePossibilityRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetForceCapturePossibilityRequest;
    return proto.jp.singcolor.GetForceCapturePossibilityRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetForceCapturePossibilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetForceCapturePossibilityRequest}
 */
proto.jp.singcolor.GetForceCapturePossibilityRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetForceCapturePossibilityRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetForceCapturePossibilityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetForceCapturePossibilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetForceCapturePossibilityRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetForceCapturePossibilityResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetForceCapturePossibilityResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetForceCapturePossibilityResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetForceCapturePossibilityResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceCapturePossibility: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetForceCapturePossibilityResponse}
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetForceCapturePossibilityResponse;
    return proto.jp.singcolor.GetForceCapturePossibilityResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetForceCapturePossibilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetForceCapturePossibilityResponse}
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setForceCapturePossibility(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetForceCapturePossibilityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetForceCapturePossibilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceCapturePossibility();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool force_capture_possibility = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse.prototype.getForceCapturePossibility = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetForceCapturePossibilityResponse} returns this
 */
proto.jp.singcolor.GetForceCapturePossibilityResponse.prototype.setForceCapturePossibility = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateForceCapturePossibilityRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateForceCapturePossibilityRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateForceCapturePossibilityRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateForceCapturePossibilityRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceCapturePossibility: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateForceCapturePossibilityRequest}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateForceCapturePossibilityRequest;
    return proto.jp.singcolor.UpdateForceCapturePossibilityRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateForceCapturePossibilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateForceCapturePossibilityRequest}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setForceCapturePossibility(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateForceCapturePossibilityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateForceCapturePossibilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceCapturePossibility();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool force_capture_possibility = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest.prototype.getForceCapturePossibility = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateForceCapturePossibilityRequest} returns this
 */
proto.jp.singcolor.UpdateForceCapturePossibilityRequest.prototype.setForceCapturePossibility = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateForceCapturePossibilityResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateForceCapturePossibilityResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateForceCapturePossibilityResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateForceCapturePossibilityResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            forceCapturePossibility: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateForceCapturePossibilityResponse}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateForceCapturePossibilityResponse;
    return proto.jp.singcolor.UpdateForceCapturePossibilityResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateForceCapturePossibilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateForceCapturePossibilityResponse}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setForceCapturePossibility(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateForceCapturePossibilityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateForceCapturePossibilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getForceCapturePossibility();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool force_capture_possibility = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse.prototype.getForceCapturePossibility = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateForceCapturePossibilityResponse} returns this
 */
proto.jp.singcolor.UpdateForceCapturePossibilityResponse.prototype.setForceCapturePossibility = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOnePhraseEventEntriesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            num: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOnePhraseEventEntriesRequest}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOnePhraseEventEntriesRequest;
    return proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOnePhraseEventEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOnePhraseEventEntriesRequest}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNum(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOnePhraseEventEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNum();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 num = 1;
 * @return {number}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.prototype.getNum = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateOnePhraseEventEntriesRequest} returns this
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesRequest.prototype.setNum = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOnePhraseEventEntriesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOnePhraseEventEntriesResponse}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOnePhraseEventEntriesResponse;
    return proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOnePhraseEventEntriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOnePhraseEventEntriesResponse}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOnePhraseEventEntriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOnePhraseEventEntriesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMonthlyFavePointRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMonthlyFavePointRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            month: jspb.Message.getFieldWithDefault(msg, 4, 0),
            favePoint: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMonthlyFavePointRequest;
    return proto.jp.singcolor.UpdateMonthlyFavePointRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFavePoint(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMonthlyFavePointRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFavePoint();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 year = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 month = 4;
 * @return {number}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 fave_point = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.getFavePoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointRequest} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointRequest.prototype.setFavePoint = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMonthlyFavePointResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMonthlyFavePointResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            month: jspb.Message.getFieldWithDefault(msg, 4, 0),
            favePoint: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMonthlyFavePointResponse;
    return proto.jp.singcolor.UpdateMonthlyFavePointResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFavePoint(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMonthlyFavePointResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFavePoint();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 year = 3;
 * @return {number}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 month = 4;
 * @return {number}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 fave_point = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.getFavePoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMonthlyFavePointResponse} returns this
 */
proto.jp.singcolor.UpdateMonthlyFavePointResponse.prototype.setFavePoint = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest;
    return proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest} returns this
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayRequest.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            dayOfTerm: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse;
    return proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDayOfTerm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getDayOfTerm();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 day_of_term = 5;
 * @return {number}
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.getDayOfTerm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse} returns this
 */
proto.jp.singcolor.GetLatestLiverMembershipGospelCoinDistributedDayResponse.prototype.setDayOfTerm = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest;
    return proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse;
    return proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunDistributeMembershipGospelCoinCommandResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest;
    return proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest} returns this
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse;
    return proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RunDistributeMembershipSpecificLiverCoinCommandResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.DebugStartLiveRequest.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugStartLiveRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugStartLiveRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugStartLiveRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugStartLiveRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugStartLiveRequest}
 */
proto.jp.singcolor.DebugStartLiveRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugStartLiveRequest;
    return proto.jp.singcolor.DebugStartLiveRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugStartLiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugStartLiveRequest}
 */
proto.jp.singcolor.DebugStartLiveRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addUserIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugStartLiveRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugStartLiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugStartLiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugStartLiveRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.DebugStartLiveRequest.prototype.getUserIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.DebugStartLiveRequest} returns this
 */
proto.jp.singcolor.DebugStartLiveRequest.prototype.setUserIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.DebugStartLiveRequest} returns this
 */
proto.jp.singcolor.DebugStartLiveRequest.prototype.addUserIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.DebugStartLiveRequest} returns this
 */
proto.jp.singcolor.DebugStartLiveRequest.prototype.clearUserIdsList = function () {
    return this.setUserIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugStartLiveResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugStartLiveResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugStartLiveResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugStartLiveResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugStartLiveResponse}
 */
proto.jp.singcolor.DebugStartLiveResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugStartLiveResponse;
    return proto.jp.singcolor.DebugStartLiveResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugStartLiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugStartLiveResponse}
 */
proto.jp.singcolor.DebugStartLiveResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugStartLiveResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugStartLiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugStartLiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugStartLiveResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * @enum {number}
 */
proto.jp.singcolor.CreateUserType = {
    CREATE_USER_UNKNOWN: 0,
    CREATE_USER_LIVER: 1,
    CREATE_USER_LISTENER: 2
};
goog.object.extend(exports, proto.jp.singcolor);
