"use strict";
// source: ope_one_phrase_events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var one_phrase_events_entity_pb = require('./one_phrase_events_entity_pb.js');
goog.object.extend(proto, one_phrase_events_entity_pb);
goog.exportSymbol('proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.displayName = 'proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.displayName = 'proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.displayName = 'proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.displayName = 'proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request;
    return proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventsList: jspb.Message.toObjectList(msg.getEventsList(), one_phrase_events_entity_pb.OnePhraseEvent.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response;
    return proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new one_phrase_events_entity_pb.OnePhraseEvent;
                reader.readMessage(value, one_phrase_events_entity_pb.OnePhraseEvent.deserializeBinaryFromReader);
                msg.addEvents(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, one_phrase_events_entity_pb.OnePhraseEvent.serializeBinaryToWriter);
    }
};
/**
 * repeated OnePhraseEvent events = 1;
 * @return {!Array<!proto.OnePhraseEvent>}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.prototype.getEventsList = function () {
    return /** @type{!Array<!proto.OnePhraseEvent>} */ (jspb.Message.getRepeatedWrapperField(this, one_phrase_events_entity_pb.OnePhraseEvent, 1));
};
/**
 * @param {!Array<!proto.OnePhraseEvent>} value
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response} returns this
*/
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.prototype.setEventsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OnePhraseEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OnePhraseEvent}
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.prototype.addEvents = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OnePhraseEvent, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response} returns this
 */
proto.jp.singcolor.OpeOnePhraseEventListEventsV1Response.prototype.clearEventsList = function () {
    return this.setEventsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            onePhraseEventId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request;
    return proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOnePhraseEventId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOnePhraseEventId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string one_phrase_event_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.prototype.getOnePhraseEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request} returns this
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Request.prototype.setOnePhraseEventId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            entriesList: jspb.Message.toObjectList(msg.getEntriesList(), one_phrase_events_entity_pb.OnePhraseEventEntryItemWithVotePoint.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response;
    return proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new one_phrase_events_entity_pb.OnePhraseEventEntryItemWithVotePoint;
                reader.readMessage(value, one_phrase_events_entity_pb.OnePhraseEventEntryItemWithVotePoint.deserializeBinaryFromReader);
                msg.addEntries(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEntriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, one_phrase_events_entity_pb.OnePhraseEventEntryItemWithVotePoint.serializeBinaryToWriter);
    }
};
/**
 * repeated OnePhraseEventEntryItemWithVotePoint entries = 1;
 * @return {!Array<!proto.OnePhraseEventEntryItemWithVotePoint>}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.prototype.getEntriesList = function () {
    return /** @type{!Array<!proto.OnePhraseEventEntryItemWithVotePoint>} */ (jspb.Message.getRepeatedWrapperField(this, one_phrase_events_entity_pb.OnePhraseEventEntryItemWithVotePoint, 1));
};
/**
 * @param {!Array<!proto.OnePhraseEventEntryItemWithVotePoint>} value
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response} returns this
*/
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.prototype.setEntriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OnePhraseEventEntryItemWithVotePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint}
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.prototype.addEntries = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OnePhraseEventEntryItemWithVotePoint, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response} returns this
 */
proto.jp.singcolor.OpeOnePhraseEventListRankingsV1Response.prototype.clearEntriesList = function () {
    return this.setEntriesList([]);
};
goog.object.extend(exports, proto.jp.singcolor);
