"use strict";
// source: organization_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var organization_entity_pb = require('./organization_entity_pb.js');
goog.object.extend(proto, organization_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
goog.exportSymbol('proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ChangeOrganizationOwnerV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ChangeOrganizationOwnerV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationLiverGroupV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationLiverGroupV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateOrganizationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateSupportPlanV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateSupportPlanV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationLiverV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationStaffV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteOrganizationStaffV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteSupportPlanV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteSupportPlanV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationLiverGroupV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationLiverGroupV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationLiverV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationLiverV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationStaffV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationStaffV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetOrganizationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverDetailsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverDetailsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverGroupsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverGroupsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiversV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiversV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRedashDashboardsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRedashDashboardsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRedashExportQueryTypesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRedashExportQueryTypesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlanTagsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlanTagsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlansV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSupportPlansV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.MyOrganizationApplicationInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.MyOrganizationApplicationInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchOrganizationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchOrganizationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateOrganizationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateOrganizationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateSupportPlanV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateSupportPlanV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationV1Request.displayName = 'proto.jp.singcolor.GetOrganizationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetOrganizationV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationV1Response.displayName = 'proto.jp.singcolor.GetOrganizationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.CreateOrganizationV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationV1Request.displayName = 'proto.jp.singcolor.CreateOrganizationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationV1Response.displayName = 'proto.jp.singcolor.CreateOrganizationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateOrganizationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateOrganizationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateOrganizationV1Request.displayName = 'proto.jp.singcolor.UpdateOrganizationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateOrganizationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateOrganizationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateOrganizationV1Response.displayName = 'proto.jp.singcolor.UpdateOrganizationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlansV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlansV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlansV1Request.displayName = 'proto.jp.singcolor.ListSupportPlansV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlansV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSupportPlansV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlansV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlansV1Response.displayName = 'proto.jp.singcolor.ListSupportPlansV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.MyOrganizationApplicationInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.displayName = 'proto.jp.singcolor.MyOrganizationApplicationInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.MyOrganizationApplicationInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.displayName = 'proto.jp.singcolor.MyOrganizationApplicationInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateSupportPlanV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.CreateSupportPlanV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.CreateSupportPlanV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateSupportPlanV1Request.displayName = 'proto.jp.singcolor.CreateSupportPlanV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateSupportPlanV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateSupportPlanV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateSupportPlanV1Response.displayName = 'proto.jp.singcolor.CreateSupportPlanV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateSupportPlanV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateSupportPlanV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateSupportPlanV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateSupportPlanV1Request.displayName = 'proto.jp.singcolor.UpdateSupportPlanV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateSupportPlanV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateSupportPlanV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateSupportPlanV1Response.displayName = 'proto.jp.singcolor.UpdateSupportPlanV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteSupportPlanV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteSupportPlanV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteSupportPlanV1Request.displayName = 'proto.jp.singcolor.DeleteSupportPlanV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteSupportPlanV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteSupportPlanV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteSupportPlanV1Response.displayName = 'proto.jp.singcolor.DeleteSupportPlanV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.displayName = 'proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.displayName = 'proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.displayName = 'proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.displayName = 'proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlanTagsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlanTagsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlanTagsV1Request.displayName = 'proto.jp.singcolor.ListSupportPlanTagsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSupportPlanTagsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSupportPlanTagsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSupportPlanTagsV1Response.displayName = 'proto.jp.singcolor.ListSupportPlanTagsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationStaffV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationStaffV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationStaffV1Request.displayName = 'proto.jp.singcolor.GetOrganizationStaffV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationStaffV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationStaffV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationStaffV1Response.displayName = 'proto.jp.singcolor.GetOrganizationStaffV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Request.displayName = 'proto.jp.singcolor.ListOrganizationStaffsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationStaffsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Response.displayName = 'proto.jp.singcolor.ListOrganizationStaffsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.displayName = 'proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.displayName = 'proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.displayName = 'proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationStaffV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationStaffV1Request.displayName = 'proto.jp.singcolor.DeleteOrganizationStaffV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationStaffV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationStaffV1Response.displayName = 'proto.jp.singcolor.DeleteOrganizationStaffV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.displayName = 'proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.displayName = 'proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.displayName = 'proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.displayName = 'proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.displayName = 'proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.displayName = 'proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.displayName = 'proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.displayName = 'proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.displayName = 'proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.displayName = 'proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ChangeOrganizationOwnerV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ChangeOrganizationOwnerV1Request.displayName = 'proto.jp.singcolor.ChangeOrganizationOwnerV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ChangeOrganizationOwnerV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ChangeOrganizationOwnerV1Response.displayName = 'proto.jp.singcolor.ChangeOrganizationOwnerV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationLiverV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationLiverV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationLiverV1Request.displayName = 'proto.jp.singcolor.GetOrganizationLiverV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationLiverV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationLiverV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationLiverV1Response.displayName = 'proto.jp.singcolor.GetOrganizationLiverV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiversV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiversV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiversV1Request.displayName = 'proto.jp.singcolor.ListOrganizationLiversV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiversV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationLiversV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiversV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiversV1Response.displayName = 'proto.jp.singcolor.ListOrganizationLiversV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.displayName = 'proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverDetailsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.displayName = 'proto.jp.singcolor.ListOrganizationLiverDetailsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverDetailsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.displayName = 'proto.jp.singcolor.ListOrganizationLiverDetailsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.displayName = 'proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.displayName = 'proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverV1Request.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverV1Response.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.displayName = 'proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.displayName = 'proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.displayName = 'proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.displayName = 'proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.displayName = 'proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.displayName = 'proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.displayName = 'proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.displayName = 'proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchOrganizationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SearchOrganizationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchOrganizationV1Request.displayName = 'proto.jp.singcolor.SearchOrganizationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchOrganizationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SearchOrganizationV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SearchOrganizationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchOrganizationV1Response.displayName = 'proto.jp.singcolor.SearchOrganizationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRedashDashboardsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRedashDashboardsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRedashDashboardsV1Request.displayName = 'proto.jp.singcolor.ListRedashDashboardsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRedashDashboardsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRedashDashboardsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRedashDashboardsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRedashDashboardsV1Response.displayName = 'proto.jp.singcolor.ListRedashDashboardsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRedashExportQueryTypesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRedashExportQueryTypesV1Request.displayName = 'proto.jp.singcolor.ListRedashExportQueryTypesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRedashExportQueryTypesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRedashExportQueryTypesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRedashExportQueryTypesV1Response.displayName = 'proto.jp.singcolor.ListRedashExportQueryTypesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.displayName = 'proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.displayName = 'proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.displayName = 'proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.displayName = 'proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.displayName = 'proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.displayName = 'proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.displayName = 'proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.displayName = 'proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.displayName = 'proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.displayName = 'proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationLiverGroupV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.displayName = 'proto.jp.singcolor.CreateOrganizationLiverGroupV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationLiverGroupV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.displayName = 'proto.jp.singcolor.CreateOrganizationLiverGroupV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.displayName = 'proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.displayName = 'proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationLiverGroupV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationLiverGroupV1Request.displayName = 'proto.jp.singcolor.GetOrganizationLiverGroupV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetOrganizationLiverGroupV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetOrganizationLiverGroupV1Response.displayName = 'proto.jp.singcolor.GetOrganizationLiverGroupV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverGroupsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.displayName = 'proto.jp.singcolor.ListOrganizationLiverGroupsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverGroupsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.displayName = 'proto.jp.singcolor.ListOrganizationLiverGroupsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.displayName = 'proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.displayName = 'proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.displayName = 'proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.displayName = 'proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.displayName = 'proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationV1Request}
 */
proto.jp.singcolor.GetOrganizationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationV1Request;
    return proto.jp.singcolor.GetOrganizationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationV1Request}
 */
proto.jp.singcolor.GetOrganizationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetOrganizationV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetOrganizationV1Request} returns this
 */
proto.jp.singcolor.GetOrganizationV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetOrganizationV1Response.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && organization_entity_pb.Organization.toObject(includeInstance, f),
            staffApplicationStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
            supportPlansList: jspb.Message.toObjectList(msg.getSupportPlansList(), organization_entity_pb.SupportPlanWithApplicationStatus.toObject, includeInstance),
            isOwner: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationV1Response}
 */
proto.jp.singcolor.GetOrganizationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationV1Response;
    return proto.jp.singcolor.GetOrganizationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationV1Response}
 */
proto.jp.singcolor.GetOrganizationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 2:
                var value = /** @type {!proto.ApplicationStatus} */ (reader.readEnum());
                msg.setStaffApplicationStatus(value);
                break;
            case 3:
                var value = new organization_entity_pb.SupportPlanWithApplicationStatus;
                reader.readMessage(value, organization_entity_pb.SupportPlanWithApplicationStatus.deserializeBinaryFromReader);
                msg.addSupportPlans(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsOwner(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
    f = message.getStaffApplicationStatus();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getSupportPlansList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, organization_entity_pb.SupportPlanWithApplicationStatus.serializeBinaryToWriter);
    }
    f = message.getIsOwner();
    if (f) {
        writer.writeBool(4, f);
    }
};
/**
 * optional Organization organization = 1;
 * @return {?proto.Organization}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.getOrganization = function () {
    return /** @type{?proto.Organization} */ (jspb.Message.getWrapperField(this, organization_entity_pb.Organization, 1));
};
/**
 * @param {?proto.Organization|undefined} value
 * @return {!proto.jp.singcolor.GetOrganizationV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationV1Response.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetOrganizationV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ApplicationStatus staff_application_status = 2;
 * @return {!proto.ApplicationStatus}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.getStaffApplicationStatus = function () {
    return /** @type {!proto.ApplicationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.ApplicationStatus} value
 * @return {!proto.jp.singcolor.GetOrganizationV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.setStaffApplicationStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * repeated SupportPlanWithApplicationStatus support_plans = 3;
 * @return {!Array<!proto.SupportPlanWithApplicationStatus>}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.getSupportPlansList = function () {
    return /** @type{!Array<!proto.SupportPlanWithApplicationStatus>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.SupportPlanWithApplicationStatus, 3));
};
/**
 * @param {!Array<!proto.SupportPlanWithApplicationStatus>} value
 * @return {!proto.jp.singcolor.GetOrganizationV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationV1Response.prototype.setSupportPlansList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.SupportPlanWithApplicationStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SupportPlanWithApplicationStatus}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.addSupportPlans = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.SupportPlanWithApplicationStatus, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetOrganizationV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.clearSupportPlansList = function () {
    return this.setSupportPlansList([]);
};
/**
 * optional bool is_owner = 4;
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.getIsOwner = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetOrganizationV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationV1Response.prototype.setIsOwner = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.CreateOrganizationV1Request.repeatedFields_ = [11];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            code: jspb.Message.getFieldWithDefault(msg, 2, ""),
            description: jspb.Message.getFieldWithDefault(msg, 3, ""),
            url: jspb.Message.getFieldWithDefault(msg, 4, ""),
            thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
            twitterScreenName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            supportPlanName: jspb.Message.getFieldWithDefault(msg, 7, ""),
            supportPlanDescription: jspb.Message.getFieldWithDefault(msg, 8, ""),
            supportPlanCashOutMethod: jspb.Message.getFieldWithDefault(msg, 9, 0),
            supportPlanOrganizerCommissionRate10000x: jspb.Message.getFieldWithDefault(msg, 10, 0),
            supportPlanTagIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
            thumbnail: msg.getThumbnail_asB64(),
            contactUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
            contactButtonTitle: jspb.Message.getFieldWithDefault(msg, 14, ""),
            hideStaffList: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request}
 */
proto.jp.singcolor.CreateOrganizationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationV1Request;
    return proto.jp.singcolor.CreateOrganizationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request}
 */
proto.jp.singcolor.CreateOrganizationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterScreenName(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanName(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanDescription(value);
                break;
            case 9:
                var value = /** @type {!proto.SupportPlanCashOutMethod} */ (reader.readEnum());
                msg.setSupportPlanCashOutMethod(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSupportPlanOrganizerCommissionRate10000x(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.addSupportPlanTagIds(value);
                break;
            case 12:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setThumbnail(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());
                msg.setContactUrl(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());
                msg.setContactButtonTitle(value);
                break;
            case 15:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHideStaffList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getThumbnailUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getTwitterScreenName();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getSupportPlanName();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getSupportPlanDescription();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getSupportPlanCashOutMethod();
    if (f !== 0.0) {
        writer.writeEnum(9, f);
    }
    f = message.getSupportPlanOrganizerCommissionRate10000x();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getSupportPlanTagIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(11, f);
    }
    f = message.getThumbnail_asU8();
    if (f.length > 0) {
        writer.writeBytes(12, f);
    }
    f = message.getContactUrl();
    if (f.length > 0) {
        writer.writeString(13, f);
    }
    f = message.getContactButtonTitle();
    if (f.length > 0) {
        writer.writeString(14, f);
    }
    f = message.getHideStaffList();
    if (f) {
        writer.writeBool(15, f);
    }
};
/**
 * optional string name = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string code = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setCode = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string url = 4;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getThumbnailUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setThumbnailUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string twitter_screen_name = 6;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getTwitterScreenName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setTwitterScreenName = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string support_plan_name = 7;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getSupportPlanName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setSupportPlanName = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string support_plan_description = 8;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getSupportPlanDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setSupportPlanDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional SupportPlanCashOutMethod support_plan_cash_out_method = 9;
 * @return {!proto.SupportPlanCashOutMethod}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getSupportPlanCashOutMethod = function () {
    return /** @type {!proto.SupportPlanCashOutMethod} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {!proto.SupportPlanCashOutMethod} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setSupportPlanCashOutMethod = function (value) {
    return jspb.Message.setProto3EnumField(this, 9, value);
};
/**
 * optional int64 support_plan_organizer_commission_rate_10000x = 10;
 * @return {number}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getSupportPlanOrganizerCommissionRate10000x = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setSupportPlanOrganizerCommissionRate10000x = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * repeated string support_plan_tag_ids = 11;
 * @return {!Array<string>}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getSupportPlanTagIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setSupportPlanTagIdsList = function (value) {
    return jspb.Message.setField(this, 11, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.addSupportPlanTagIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.clearSupportPlanTagIdsList = function () {
    return this.setSupportPlanTagIdsList([]);
};
/**
 * optional bytes thumbnail = 12;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getThumbnail = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};
/**
 * optional bytes thumbnail = 12;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getThumbnail_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getThumbnail()));
};
/**
 * optional bytes thumbnail = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getThumbnail_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getThumbnail()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setThumbnail = function (value) {
    return jspb.Message.setProto3BytesField(this, 12, value);
};
/**
 * optional string contact_url = 13;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getContactUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setContactUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 13, value);
};
/**
 * optional string contact_button_title = 14;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getContactButtonTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setContactButtonTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 14, value);
};
/**
 * optional bool hide_staff_list = 15;
 * @return {boolean}
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.getHideStaffList = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Request.prototype.setHideStaffList = function (value) {
    return jspb.Message.setProto3BooleanField(this, 15, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && organization_entity_pb.Organization.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationV1Response}
 */
proto.jp.singcolor.CreateOrganizationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationV1Response;
    return proto.jp.singcolor.CreateOrganizationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationV1Response}
 */
proto.jp.singcolor.CreateOrganizationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
};
/**
 * optional Organization organization = 1;
 * @return {?proto.Organization}
 */
proto.jp.singcolor.CreateOrganizationV1Response.prototype.getOrganization = function () {
    return /** @type{?proto.Organization} */ (jspb.Message.getWrapperField(this, organization_entity_pb.Organization, 1));
};
/**
 * @param {?proto.Organization|undefined} value
 * @return {!proto.jp.singcolor.CreateOrganizationV1Response} returns this
*/
proto.jp.singcolor.CreateOrganizationV1Response.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateOrganizationV1Response} returns this
 */
proto.jp.singcolor.CreateOrganizationV1Response.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateOrganizationV1Response.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateOrganizationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateOrganizationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateOrganizationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateOrganizationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            description: jspb.Message.getFieldWithDefault(msg, 3, ""),
            url: jspb.Message.getFieldWithDefault(msg, 4, ""),
            thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
            twitterScreenName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            thumbnail: msg.getThumbnail_asB64(),
            code: jspb.Message.getFieldWithDefault(msg, 8, ""),
            contactUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
            contactButtonTitle: jspb.Message.getFieldWithDefault(msg, 10, ""),
            hideStaffList: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateOrganizationV1Request;
    return proto.jp.singcolor.UpdateOrganizationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateOrganizationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterScreenName(value);
                break;
            case 7:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setThumbnail(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setContactUrl(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setContactButtonTitle(value);
                break;
            case 11:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHideStaffList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateOrganizationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateOrganizationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateOrganizationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getThumbnailUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getTwitterScreenName();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getThumbnail_asU8();
    if (f.length > 0) {
        writer.writeBytes(7, f);
    }
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getContactUrl();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
    f = message.getContactButtonTitle();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getHideStaffList();
    if (f) {
        writer.writeBool(11, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string url = 4;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getThumbnailUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setThumbnailUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string twitter_screen_name = 6;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getTwitterScreenName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setTwitterScreenName = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional bytes thumbnail = 7;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getThumbnail = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * optional bytes thumbnail = 7;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getThumbnail_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getThumbnail()));
};
/**
 * optional bytes thumbnail = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getThumbnail_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getThumbnail()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setThumbnail = function (value) {
    return jspb.Message.setProto3BytesField(this, 7, value);
};
/**
 * optional string code = 8;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setCode = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string contact_url = 9;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getContactUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setContactUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string contact_button_title = 10;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getContactButtonTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setContactButtonTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional bool hide_staff_list = 11;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.getHideStaffList = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Request.prototype.setHideStaffList = function (value) {
    return jspb.Message.setProto3BooleanField(this, 11, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateOrganizationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateOrganizationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateOrganizationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateOrganizationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && organization_entity_pb.Organization.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Response}
 */
proto.jp.singcolor.UpdateOrganizationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateOrganizationV1Response;
    return proto.jp.singcolor.UpdateOrganizationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateOrganizationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Response}
 */
proto.jp.singcolor.UpdateOrganizationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateOrganizationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateOrganizationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateOrganizationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
};
/**
 * optional Organization organization = 1;
 * @return {?proto.Organization}
 */
proto.jp.singcolor.UpdateOrganizationV1Response.prototype.getOrganization = function () {
    return /** @type{?proto.Organization} */ (jspb.Message.getWrapperField(this, organization_entity_pb.Organization, 1));
};
/**
 * @param {?proto.Organization|undefined} value
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Response} returns this
*/
proto.jp.singcolor.UpdateOrganizationV1Response.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateOrganizationV1Response} returns this
 */
proto.jp.singcolor.UpdateOrganizationV1Response.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateOrganizationV1Response.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlansV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlansV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlansV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlansV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlansV1Request}
 */
proto.jp.singcolor.ListSupportPlansV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlansV1Request;
    return proto.jp.singcolor.ListSupportPlansV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlansV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlansV1Request}
 */
proto.jp.singcolor.ListSupportPlansV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlansV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlansV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlansV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlansV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSupportPlansV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlansV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlansV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlansV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlansV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlansList: jspb.Message.toObjectList(msg.getSupportPlansList(), organization_entity_pb.SupportPlan.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlansV1Response}
 */
proto.jp.singcolor.ListSupportPlansV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlansV1Response;
    return proto.jp.singcolor.ListSupportPlansV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlansV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlansV1Response}
 */
proto.jp.singcolor.ListSupportPlansV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.SupportPlan;
                reader.readMessage(value, organization_entity_pb.SupportPlan.deserializeBinaryFromReader);
                msg.addSupportPlans(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlansV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlansV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlansV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlansV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlansList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.SupportPlan.serializeBinaryToWriter);
    }
};
/**
 * repeated SupportPlan support_plans = 1;
 * @return {!Array<!proto.SupportPlan>}
 */
proto.jp.singcolor.ListSupportPlansV1Response.prototype.getSupportPlansList = function () {
    return /** @type{!Array<!proto.SupportPlan>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.SupportPlan, 1));
};
/**
 * @param {!Array<!proto.SupportPlan>} value
 * @return {!proto.jp.singcolor.ListSupportPlansV1Response} returns this
*/
proto.jp.singcolor.ListSupportPlansV1Response.prototype.setSupportPlansList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.SupportPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SupportPlan}
 */
proto.jp.singcolor.ListSupportPlansV1Response.prototype.addSupportPlans = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SupportPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSupportPlansV1Response} returns this
 */
proto.jp.singcolor.ListSupportPlansV1Response.prototype.clearSupportPlansList = function () {
    return this.setSupportPlansList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Request}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MyOrganizationApplicationInfoV1Request;
    return proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Request}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverApplication: (f = msg.getOrganizationLiverApplication()) && organization_entity_pb.OrganizationLiverApplication.toObject(includeInstance, f),
            organizationLiver: (f = msg.getOrganizationLiver()) && organization_entity_pb.OrganizationLiver.toObject(includeInstance, f),
            organizationStaffApplication: (f = msg.getOrganizationStaffApplication()) && organization_entity_pb.OrganizationStaffApplication.toObject(includeInstance, f),
            organizationStaff: (f = msg.getOrganizationStaff()) && organization_entity_pb.OrganizationStaff.toObject(includeInstance, f),
            permittedToUseOrganization: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MyOrganizationApplicationInfoV1Response;
    return proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverApplication.deserializeBinaryFromReader);
                msg.setOrganizationLiverApplication(value);
                break;
            case 2:
                var value = new organization_entity_pb.OrganizationLiver;
                reader.readMessage(value, organization_entity_pb.OrganizationLiver.deserializeBinaryFromReader);
                msg.setOrganizationLiver(value);
                break;
            case 3:
                var value = new organization_entity_pb.OrganizationStaffApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationStaffApplication.deserializeBinaryFromReader);
                msg.setOrganizationStaffApplication(value);
                break;
            case 4:
                var value = new organization_entity_pb.OrganizationStaff;
                reader.readMessage(value, organization_entity_pb.OrganizationStaff.deserializeBinaryFromReader);
                msg.setOrganizationStaff(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPermittedToUseOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverApplication.serializeBinaryToWriter);
    }
    f = message.getOrganizationLiver();
    if (f != null) {
        writer.writeMessage(2, f, organization_entity_pb.OrganizationLiver.serializeBinaryToWriter);
    }
    f = message.getOrganizationStaffApplication();
    if (f != null) {
        writer.writeMessage(3, f, organization_entity_pb.OrganizationStaffApplication.serializeBinaryToWriter);
    }
    f = message.getOrganizationStaff();
    if (f != null) {
        writer.writeMessage(4, f, organization_entity_pb.OrganizationStaff.serializeBinaryToWriter);
    }
    f = message.getPermittedToUseOrganization();
    if (f) {
        writer.writeBool(5, f);
    }
};
/**
 * optional OrganizationLiverApplication organization_liver_application = 1;
 * @return {?proto.OrganizationLiverApplication}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.getOrganizationLiverApplication = function () {
    return /** @type{?proto.OrganizationLiverApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverApplication, 1));
};
/**
 * @param {?proto.OrganizationLiverApplication|undefined} value
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
*/
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.setOrganizationLiverApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.clearOrganizationLiverApplication = function () {
    return this.setOrganizationLiverApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.hasOrganizationLiverApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional OrganizationLiver organization_liver = 2;
 * @return {?proto.OrganizationLiver}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.getOrganizationLiver = function () {
    return /** @type{?proto.OrganizationLiver} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiver, 2));
};
/**
 * @param {?proto.OrganizationLiver|undefined} value
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
*/
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.setOrganizationLiver = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.clearOrganizationLiver = function () {
    return this.setOrganizationLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.hasOrganizationLiver = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional OrganizationStaffApplication organization_staff_application = 3;
 * @return {?proto.OrganizationStaffApplication}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.getOrganizationStaffApplication = function () {
    return /** @type{?proto.OrganizationStaffApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaffApplication, 3));
};
/**
 * @param {?proto.OrganizationStaffApplication|undefined} value
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
*/
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.setOrganizationStaffApplication = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.clearOrganizationStaffApplication = function () {
    return this.setOrganizationStaffApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.hasOrganizationStaffApplication = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional OrganizationStaff organization_staff = 4;
 * @return {?proto.OrganizationStaff}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.getOrganizationStaff = function () {
    return /** @type{?proto.OrganizationStaff} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaff, 4));
};
/**
 * @param {?proto.OrganizationStaff|undefined} value
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
*/
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.setOrganizationStaff = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.clearOrganizationStaff = function () {
    return this.setOrganizationStaff(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.hasOrganizationStaff = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional bool permitted_to_use_organization = 5;
 * @return {boolean}
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.getPermittedToUseOrganization = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.MyOrganizationApplicationInfoV1Response} returns this
 */
proto.jp.singcolor.MyOrganizationApplicationInfoV1Response.prototype.setPermittedToUseOrganization = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.CreateSupportPlanV1Request.repeatedFields_ = [5];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateSupportPlanV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateSupportPlanV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateSupportPlanV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateSupportPlanV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            description: jspb.Message.getFieldWithDefault(msg, 2, ""),
            cashOutMethod: jspb.Message.getFieldWithDefault(msg, 3, 0),
            organizerCommissionRate10000x: jspb.Message.getFieldWithDefault(msg, 4, 0),
            supportPlanTagIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateSupportPlanV1Request;
    return proto.jp.singcolor.CreateSupportPlanV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateSupportPlanV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 3:
                var value = /** @type {!proto.SupportPlanCashOutMethod} */ (reader.readEnum());
                msg.setCashOutMethod(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOrganizerCommissionRate10000x(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.addSupportPlanTagIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateSupportPlanV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateSupportPlanV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateSupportPlanV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCashOutMethod();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getOrganizerCommissionRate10000x();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getSupportPlanTagIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(5, f);
    }
};
/**
 * optional string name = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string description = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional SupportPlanCashOutMethod cash_out_method = 3;
 * @return {!proto.SupportPlanCashOutMethod}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.getCashOutMethod = function () {
    return /** @type {!proto.SupportPlanCashOutMethod} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.SupportPlanCashOutMethod} value
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.setCashOutMethod = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 organizer_commission_rate_10000x = 4;
 * @return {number}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.getOrganizerCommissionRate10000x = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.setOrganizerCommissionRate10000x = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * repeated string support_plan_tag_ids = 5;
 * @return {!Array<string>}
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.getSupportPlanTagIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.setSupportPlanTagIdsList = function (value) {
    return jspb.Message.setField(this, 5, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.addSupportPlanTagIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Request.prototype.clearSupportPlanTagIdsList = function () {
    return this.setSupportPlanTagIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateSupportPlanV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateSupportPlanV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateSupportPlanV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateSupportPlanV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlan: (f = msg.getSupportPlan()) && organization_entity_pb.SupportPlan.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Response}
 */
proto.jp.singcolor.CreateSupportPlanV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateSupportPlanV1Response;
    return proto.jp.singcolor.CreateSupportPlanV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateSupportPlanV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Response}
 */
proto.jp.singcolor.CreateSupportPlanV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.SupportPlan;
                reader.readMessage(value, organization_entity_pb.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateSupportPlanV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateSupportPlanV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateSupportPlanV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateSupportPlanV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.SupportPlan.serializeBinaryToWriter);
    }
};
/**
 * optional SupportPlan support_plan = 1;
 * @return {?proto.SupportPlan}
 */
proto.jp.singcolor.CreateSupportPlanV1Response.prototype.getSupportPlan = function () {
    return /** @type{?proto.SupportPlan} */ (jspb.Message.getWrapperField(this, organization_entity_pb.SupportPlan, 1));
};
/**
 * @param {?proto.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Response} returns this
*/
proto.jp.singcolor.CreateSupportPlanV1Response.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateSupportPlanV1Response} returns this
 */
proto.jp.singcolor.CreateSupportPlanV1Response.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateSupportPlanV1Response.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.repeatedFields_ = [6];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateSupportPlanV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateSupportPlanV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateSupportPlanV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            description: jspb.Message.getFieldWithDefault(msg, 3, ""),
            cashOutMethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
            organizerCommissionRate10000x: jspb.Message.getFieldWithDefault(msg, 5, 0),
            supportPlanTagIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
            status: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateSupportPlanV1Request;
    return proto.jp.singcolor.UpdateSupportPlanV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateSupportPlanV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 4:
                var value = /** @type {!proto.SupportPlanCashOutMethod} */ (reader.readEnum());
                msg.setCashOutMethod(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOrganizerCommissionRate10000x(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.addSupportPlanTagIds(value);
                break;
            case 7:
                var value = /** @type {!proto.SupportPlanStatus} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateSupportPlanV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateSupportPlanV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCashOutMethod();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getOrganizerCommissionRate10000x();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getSupportPlanTagIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(6, f);
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
};
/**
 * optional string support_plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional SupportPlanCashOutMethod cash_out_method = 4;
 * @return {!proto.SupportPlanCashOutMethod}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getCashOutMethod = function () {
    return /** @type {!proto.SupportPlanCashOutMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.SupportPlanCashOutMethod} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setCashOutMethod = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 organizer_commission_rate_10000x = 5;
 * @return {number}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getOrganizerCommissionRate10000x = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setOrganizerCommissionRate10000x = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * repeated string support_plan_tag_ids = 6;
 * @return {!Array<string>}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getSupportPlanTagIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setSupportPlanTagIdsList = function (value) {
    return jspb.Message.setField(this, 6, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.addSupportPlanTagIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.clearSupportPlanTagIdsList = function () {
    return this.setSupportPlanTagIdsList([]);
};
/**
 * optional SupportPlanStatus status = 7;
 * @return {!proto.SupportPlanStatus}
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.getStatus = function () {
    return /** @type {!proto.SupportPlanStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.SupportPlanStatus} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Request} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Request.prototype.setStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateSupportPlanV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateSupportPlanV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateSupportPlanV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateSupportPlanV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlan: (f = msg.getSupportPlan()) && organization_entity_pb.SupportPlan.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Response}
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateSupportPlanV1Response;
    return proto.jp.singcolor.UpdateSupportPlanV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateSupportPlanV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Response}
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.SupportPlan;
                reader.readMessage(value, organization_entity_pb.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateSupportPlanV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateSupportPlanV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.SupportPlan.serializeBinaryToWriter);
    }
};
/**
 * optional SupportPlan support_plan = 1;
 * @return {?proto.SupportPlan}
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.prototype.getSupportPlan = function () {
    return /** @type{?proto.SupportPlan} */ (jspb.Message.getWrapperField(this, organization_entity_pb.SupportPlan, 1));
};
/**
 * @param {?proto.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Response} returns this
*/
proto.jp.singcolor.UpdateSupportPlanV1Response.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateSupportPlanV1Response} returns this
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateSupportPlanV1Response.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteSupportPlanV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteSupportPlanV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteSupportPlanV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteSupportPlanV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteSupportPlanV1Request}
 */
proto.jp.singcolor.DeleteSupportPlanV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteSupportPlanV1Request;
    return proto.jp.singcolor.DeleteSupportPlanV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteSupportPlanV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteSupportPlanV1Request}
 */
proto.jp.singcolor.DeleteSupportPlanV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteSupportPlanV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteSupportPlanV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteSupportPlanV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteSupportPlanV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string support_plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteSupportPlanV1Request.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteSupportPlanV1Request} returns this
 */
proto.jp.singcolor.DeleteSupportPlanV1Request.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteSupportPlanV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteSupportPlanV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteSupportPlanV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteSupportPlanV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteSupportPlanV1Response}
 */
proto.jp.singcolor.DeleteSupportPlanV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteSupportPlanV1Response;
    return proto.jp.singcolor.DeleteSupportPlanV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteSupportPlanV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteSupportPlanV1Response}
 */
proto.jp.singcolor.DeleteSupportPlanV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteSupportPlanV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteSupportPlanV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteSupportPlanV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteSupportPlanV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request;
    return proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request} returns this
*/
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanCreationHistoriesList: jspb.Message.toObjectList(msg.getSupportPlanCreationHistoriesList(), organization_entity_pb.SupportPlanCreationHistory.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response;
    return proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.SupportPlanCreationHistory;
                reader.readMessage(value, organization_entity_pb.SupportPlanCreationHistory.deserializeBinaryFromReader);
                msg.addSupportPlanCreationHistories(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanCreationHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.SupportPlanCreationHistory.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated SupportPlanCreationHistory support_plan_creation_histories = 1;
 * @return {!Array<!proto.SupportPlanCreationHistory>}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.getSupportPlanCreationHistoriesList = function () {
    return /** @type{!Array<!proto.SupportPlanCreationHistory>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.SupportPlanCreationHistory, 1));
};
/**
 * @param {!Array<!proto.SupportPlanCreationHistory>} value
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.setSupportPlanCreationHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.SupportPlanCreationHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SupportPlanCreationHistory}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.addSupportPlanCreationHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SupportPlanCreationHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.clearSupportPlanCreationHistoriesList = function () {
    return this.setSupportPlanCreationHistoriesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListSupportPlanCreationHistoriesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request;
    return proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request} returns this
*/
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanUpdateHistoriesList: jspb.Message.toObjectList(msg.getSupportPlanUpdateHistoriesList(), organization_entity_pb.SupportPlanUpdateHistory.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response;
    return proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.SupportPlanUpdateHistory;
                reader.readMessage(value, organization_entity_pb.SupportPlanUpdateHistory.deserializeBinaryFromReader);
                msg.addSupportPlanUpdateHistories(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanUpdateHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.SupportPlanUpdateHistory.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated SupportPlanUpdateHistory support_plan_update_histories = 1;
 * @return {!Array<!proto.SupportPlanUpdateHistory>}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.getSupportPlanUpdateHistoriesList = function () {
    return /** @type{!Array<!proto.SupportPlanUpdateHistory>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.SupportPlanUpdateHistory, 1));
};
/**
 * @param {!Array<!proto.SupportPlanUpdateHistory>} value
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.setSupportPlanUpdateHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.SupportPlanUpdateHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SupportPlanUpdateHistory}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.addSupportPlanUpdateHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SupportPlanUpdateHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.clearSupportPlanUpdateHistoriesList = function () {
    return this.setSupportPlanUpdateHistoriesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListSupportPlanUpdateHistoriesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlanTagsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlanTagsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlanTagsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlanTagsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlanTagsV1Request}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlanTagsV1Request;
    return proto.jp.singcolor.ListSupportPlanTagsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlanTagsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlanTagsV1Request}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlanTagsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlanTagsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlanTagsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSupportPlanTagsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSupportPlanTagsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSupportPlanTagsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSupportPlanTagsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanTagsList: jspb.Message.toObjectList(msg.getSupportPlanTagsList(), organization_entity_pb.SupportPlanTag.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSupportPlanTagsV1Response}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSupportPlanTagsV1Response;
    return proto.jp.singcolor.ListSupportPlanTagsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSupportPlanTagsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSupportPlanTagsV1Response}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.SupportPlanTag;
                reader.readMessage(value, organization_entity_pb.SupportPlanTag.deserializeBinaryFromReader);
                msg.addSupportPlanTags(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSupportPlanTagsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSupportPlanTagsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanTagsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.SupportPlanTag.serializeBinaryToWriter);
    }
};
/**
 * repeated SupportPlanTag support_plan_tags = 1;
 * @return {!Array<!proto.SupportPlanTag>}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.prototype.getSupportPlanTagsList = function () {
    return /** @type{!Array<!proto.SupportPlanTag>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.SupportPlanTag, 1));
};
/**
 * @param {!Array<!proto.SupportPlanTag>} value
 * @return {!proto.jp.singcolor.ListSupportPlanTagsV1Response} returns this
*/
proto.jp.singcolor.ListSupportPlanTagsV1Response.prototype.setSupportPlanTagsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.SupportPlanTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SupportPlanTag}
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.prototype.addSupportPlanTags = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SupportPlanTag, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSupportPlanTagsV1Response} returns this
 */
proto.jp.singcolor.ListSupportPlanTagsV1Response.prototype.clearSupportPlanTagsList = function () {
    return this.setSupportPlanTagsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationStaffV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationStaffV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationStaffV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationStaffV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Request}
 */
proto.jp.singcolor.GetOrganizationStaffV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationStaffV1Request;
    return proto.jp.singcolor.GetOrganizationStaffV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationStaffV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Request}
 */
proto.jp.singcolor.GetOrganizationStaffV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationStaffV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationStaffV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationStaffV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationStaffV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetOrganizationStaffV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Request} returns this
 */
proto.jp.singcolor.GetOrganizationStaffV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationStaffV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationStaffV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationStaffV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaff: (f = msg.getOrganizationStaff()) && organization_entity_pb.OrganizationStaff.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Response}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationStaffV1Response;
    return proto.jp.singcolor.GetOrganizationStaffV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationStaffV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Response}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaff;
                reader.readMessage(value, organization_entity_pb.OrganizationStaff.deserializeBinaryFromReader);
                msg.setOrganizationStaff(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationStaffV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationStaffV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaff();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationStaff.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationStaff organization_staff = 1;
 * @return {?proto.OrganizationStaff}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.getOrganizationStaff = function () {
    return /** @type{?proto.OrganizationStaff} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaff, 1));
};
/**
 * @param {?proto.OrganizationStaff|undefined} value
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.setOrganizationStaff = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.clearOrganizationStaff = function () {
    return this.setOrganizationStaff(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.hasOrganizationStaff = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem user_item = 2;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetOrganizationStaffV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationStaffV1Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Request}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffsV1Request;
    return proto.jp.singcolor.ListOrganizationStaffsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Request}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffsList: jspb.Message.toObjectList(msg.getOrganizationStaffsList(), proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffsV1Response;
    return proto.jp.singcolor.ListOrganizationStaffsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem;
                reader.readMessage(value, proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.deserializeBinaryFromReader);
                msg.addOrganizationStaffs(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaff: (f = msg.getOrganizationStaff()) && organization_entity_pb.OrganizationStaff.toObject(includeInstance, f),
            useritem: (f = msg.getUseritem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem;
    return proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaff;
                reader.readMessage(value, organization_entity_pb.OrganizationStaff.deserializeBinaryFromReader);
                msg.setOrganizationStaff(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUseritem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaff();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationStaff.serializeBinaryToWriter);
    }
    f = message.getUseritem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationStaff organization_staff = 1;
 * @return {?proto.OrganizationStaff}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.getOrganizationStaff = function () {
    return /** @type{?proto.OrganizationStaff} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaff, 1));
};
/**
 * @param {?proto.OrganizationStaff|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} returns this
*/
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.setOrganizationStaff = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.clearOrganizationStaff = function () {
    return this.setOrganizationStaff(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.hasOrganizationStaff = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem userItem = 2;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.getUseritem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} returns this
*/
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.setUseritem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.clearUseritem = function () {
    return this.setUseritem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem.prototype.hasUseritem = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated OrganizationStaffWithUserItem organization_staffs = 1;
 * @return {!Array<!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem>}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.getOrganizationStaffsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem>} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.setOrganizationStaffsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.addOrganizationStaffs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListOrganizationStaffsV1Response.OrganizationStaffWithUserItem, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.clearOrganizationStaffsList = function () {
    return this.setOrganizationStaffsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            role: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request;
    return proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {!proto.OrganizationStaffRole} */ (reader.readEnum());
                msg.setRole(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional OrganizationStaffRole role = 2;
 * @return {!proto.OrganizationStaffRole}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.prototype.getRole = function () {
    return /** @type {!proto.OrganizationStaffRole} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.OrganizationStaffRole} value
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Request.prototype.setRole = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaff: (f = msg.getOrganizationStaff()) && organization_entity_pb.OrganizationStaff.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response;
    return proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaff;
                reader.readMessage(value, organization_entity_pb.OrganizationStaff.deserializeBinaryFromReader);
                msg.setOrganizationStaff(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaff();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationStaff.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationStaff organization_staff = 1;
 * @return {?proto.OrganizationStaff}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.getOrganizationStaff = function () {
    return /** @type{?proto.OrganizationStaff} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaff, 1));
};
/**
 * @param {?proto.OrganizationStaff|undefined} value
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} returns this
*/
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.setOrganizationStaff = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} returns this
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.clearOrganizationStaff = function () {
    return this.setOrganizationStaff(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.hasOrganizationStaff = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem user_item = 2;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} returns this
*/
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response} returns this
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateOrganizationStaffRoleV1Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationStaffV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationStaffV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationStaffV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationStaffV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffV1Request}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationStaffV1Request;
    return proto.jp.singcolor.DeleteOrganizationStaffV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffV1Request}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationStaffV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffV1Request} returns this
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Request.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationStaffV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationStaffV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationStaffV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationStaffV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffV1Response}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationStaffV1Response;
    return proto.jp.singcolor.DeleteOrganizationStaffV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffV1Response}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationStaffV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationStaffV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request;
    return proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplication: (f = msg.getOrganizationStaffApplication()) && organization_entity_pb.OrganizationStaffApplication.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response;
    return proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaffApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationStaffApplication.deserializeBinaryFromReader);
                msg.setOrganizationStaffApplication(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationStaffApplication.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationStaffApplication organization_staff_application = 1;
 * @return {?proto.OrganizationStaffApplication}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.prototype.getOrganizationStaffApplication = function () {
    return /** @type{?proto.OrganizationStaffApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaffApplication, 1));
};
/**
 * @param {?proto.OrganizationStaffApplication|undefined} value
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response} returns this
*/
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.prototype.setOrganizationStaffApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response} returns this
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.prototype.clearOrganizationStaffApplication = function () {
    return this.setOrganizationStaffApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateOrganizationStaffApplicationV1Response.prototype.hasOrganizationStaffApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request;
    return proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplicationsList: jspb.Message.toObjectList(msg.getOrganizationStaffApplicationsList(), organization_entity_pb.OrganizationStaffApplication.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response;
    return proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaffApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationStaffApplication.deserializeBinaryFromReader);
                msg.addOrganizationStaffApplications(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplicationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationStaffApplication.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated OrganizationStaffApplication organization_staff_applications = 1;
 * @return {!Array<!proto.OrganizationStaffApplication>}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.getOrganizationStaffApplicationsList = function () {
    return /** @type{!Array<!proto.OrganizationStaffApplication>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationStaffApplication, 1));
};
/**
 * @param {!Array<!proto.OrganizationStaffApplication>} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.setOrganizationStaffApplicationsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationStaffApplication=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationStaffApplication}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.addOrganizationStaffApplications = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationStaffApplication, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.clearOrganizationStaffApplicationsList = function () {
    return this.setOrganizationStaffApplicationsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffApplicationsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplicationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request;
    return proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationStaffApplicationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplicationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_staff_application_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.prototype.getOrganizationStaffApplicationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request} returns this
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Request.prototype.setOrganizationStaffApplicationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplication: (f = msg.getOrganizationStaffApplication()) && organization_entity_pb.OrganizationStaffApplication.toObject(includeInstance, f),
            organizationStaff: (f = msg.getOrganizationStaff()) && organization_entity_pb.OrganizationStaff.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response;
    return proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaffApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationStaffApplication.deserializeBinaryFromReader);
                msg.setOrganizationStaffApplication(value);
                break;
            case 2:
                var value = new organization_entity_pb.OrganizationStaff;
                reader.readMessage(value, organization_entity_pb.OrganizationStaff.deserializeBinaryFromReader);
                msg.setOrganizationStaff(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationStaffApplication.serializeBinaryToWriter);
    }
    f = message.getOrganizationStaff();
    if (f != null) {
        writer.writeMessage(2, f, organization_entity_pb.OrganizationStaff.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationStaffApplication organization_staff_application = 1;
 * @return {?proto.OrganizationStaffApplication}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.getOrganizationStaffApplication = function () {
    return /** @type{?proto.OrganizationStaffApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaffApplication, 1));
};
/**
 * @param {?proto.OrganizationStaffApplication|undefined} value
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} returns this
*/
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.setOrganizationStaffApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} returns this
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.clearOrganizationStaffApplication = function () {
    return this.setOrganizationStaffApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.hasOrganizationStaffApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional OrganizationStaff organization_staff = 2;
 * @return {?proto.OrganizationStaff}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.getOrganizationStaff = function () {
    return /** @type{?proto.OrganizationStaff} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaff, 2));
};
/**
 * @param {?proto.OrganizationStaff|undefined} value
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} returns this
*/
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.setOrganizationStaff = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response} returns this
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.clearOrganizationStaff = function () {
    return this.setOrganizationStaff(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ApproveOrganizationStaffApplicationV1Response.prototype.hasOrganizationStaff = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplicationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request;
    return proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationStaffApplicationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplicationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_staff_application_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.prototype.getOrganizationStaffApplicationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request} returns this
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Request.prototype.setOrganizationStaffApplicationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplication: (f = msg.getOrganizationStaffApplication()) && organization_entity_pb.OrganizationStaffApplication.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response;
    return proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaffApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationStaffApplication.deserializeBinaryFromReader);
                msg.setOrganizationStaffApplication(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationStaffApplication.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationStaffApplication organization_staff_application = 1;
 * @return {?proto.OrganizationStaffApplication}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.prototype.getOrganizationStaffApplication = function () {
    return /** @type{?proto.OrganizationStaffApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationStaffApplication, 1));
};
/**
 * @param {?proto.OrganizationStaffApplication|undefined} value
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response} returns this
*/
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.prototype.setOrganizationStaffApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response} returns this
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.prototype.clearOrganizationStaffApplication = function () {
    return this.setOrganizationStaffApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.RejectOrganizationStaffApplicationV1Response.prototype.hasOrganizationStaffApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request;
    return proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request}
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response;
    return proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response}
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationStaffApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ChangeOrganizationOwnerV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ChangeOrganizationOwnerV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ChangeOrganizationOwnerV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ChangeOrganizationOwnerV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            newOwnerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ChangeOrganizationOwnerV1Request}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ChangeOrganizationOwnerV1Request;
    return proto.jp.singcolor.ChangeOrganizationOwnerV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ChangeOrganizationOwnerV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ChangeOrganizationOwnerV1Request}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setNewOwnerUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ChangeOrganizationOwnerV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ChangeOrganizationOwnerV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNewOwnerUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string new_owner_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request.prototype.getNewOwnerUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ChangeOrganizationOwnerV1Request} returns this
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Request.prototype.setNewOwnerUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ChangeOrganizationOwnerV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ChangeOrganizationOwnerV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ChangeOrganizationOwnerV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ChangeOrganizationOwnerV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ChangeOrganizationOwnerV1Response}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ChangeOrganizationOwnerV1Response;
    return proto.jp.singcolor.ChangeOrganizationOwnerV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ChangeOrganizationOwnerV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ChangeOrganizationOwnerV1Response}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ChangeOrganizationOwnerV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ChangeOrganizationOwnerV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ChangeOrganizationOwnerV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationLiverV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationLiverV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationLiverV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationLiverV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Request}
 */
proto.jp.singcolor.GetOrganizationLiverV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationLiverV1Request;
    return proto.jp.singcolor.GetOrganizationLiverV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationLiverV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Request}
 */
proto.jp.singcolor.GetOrganizationLiverV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationLiverV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationLiverV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationLiverV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationLiverV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetOrganizationLiverV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Request} returns this
 */
proto.jp.singcolor.GetOrganizationLiverV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationLiverV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationLiverV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationLiverV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiver: (f = msg.getOrganizationLiver()) && organization_entity_pb.OrganizationLiver.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Response}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationLiverV1Response;
    return proto.jp.singcolor.GetOrganizationLiverV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationLiverV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Response}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiver;
                reader.readMessage(value, organization_entity_pb.OrganizationLiver.deserializeBinaryFromReader);
                msg.setOrganizationLiver(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationLiverV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationLiverV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiver();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiver.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiver organization_liver = 1;
 * @return {?proto.OrganizationLiver}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.getOrganizationLiver = function () {
    return /** @type{?proto.OrganizationLiver} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiver, 1));
};
/**
 * @param {?proto.OrganizationLiver|undefined} value
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.setOrganizationLiver = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.clearOrganizationLiver = function () {
    return this.setOrganizationLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.hasOrganizationLiver = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem user_item = 2;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetOrganizationLiverV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationLiverV1Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiversV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiversV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiversV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Request}
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiversV1Request;
    return proto.jp.singcolor.ListOrganizationLiversV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Request}
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiversV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiversV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiversV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiversList: jspb.Message.toObjectList(msg.getOrganizationLiversList(), proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiversV1Response;
    return proto.jp.singcolor.ListOrganizationLiversV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem;
                reader.readMessage(value, proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.deserializeBinaryFromReader);
                msg.addOrganizationLivers(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiversV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiver: (f = msg.getOrganizationLiver()) && organization_entity_pb.OrganizationLiver.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem;
    return proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiver;
                reader.readMessage(value, organization_entity_pb.OrganizationLiver.deserializeBinaryFromReader);
                msg.setOrganizationLiver(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiver();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiver.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiver organization_liver = 1;
 * @return {?proto.OrganizationLiver}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.getOrganizationLiver = function () {
    return /** @type{?proto.OrganizationLiver} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiver, 1));
};
/**
 * @param {?proto.OrganizationLiver|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} returns this
*/
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.setOrganizationLiver = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.clearOrganizationLiver = function () {
    return this.setOrganizationLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.hasOrganizationLiver = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem user_item = 2;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} returns this
*/
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated OrganizationLiverWithUserItem organization_livers = 1;
 * @return {!Array<!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem>}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.getOrganizationLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem>} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.setOrganizationLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.addOrganizationLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListOrganizationLiversV1Response.OrganizationLiverWithUserItem, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.clearOrganizationLiversList = function () {
    return this.setOrganizationLiversList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListOrganizationLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiversV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverDetailsV1Request;
    return proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiversList: jspb.Message.toObjectList(msg.getOrganizationLiversList(), organization_entity_pb.OrganizationLiverDetail.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverDetailsV1Response;
    return proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverDetail;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverDetail.deserializeBinaryFromReader);
                msg.addOrganizationLivers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationLiverDetail.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationLiverDetail organization_livers = 1;
 * @return {!Array<!proto.OrganizationLiverDetail>}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.prototype.getOrganizationLiversList = function () {
    return /** @type{!Array<!proto.OrganizationLiverDetail>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationLiverDetail, 1));
};
/**
 * @param {!Array<!proto.OrganizationLiverDetail>} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.prototype.setOrganizationLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationLiverDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationLiverDetail}
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.prototype.addOrganizationLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationLiverDetail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationLiverDetailsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverDetailsV1Response.prototype.clearOrganizationLiversList = function () {
    return this.setOrganizationLiversList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request}
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request;
    return proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request}
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response}
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response;
    return proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response}
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.InvalidateOrganizationLiverDetailsCacheV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverV1Request;
    return proto.jp.singcolor.DeleteOrganizationLiverV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverV1Request} returns this
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverV1Response;
    return proto.jp.singcolor.DeleteOrganizationLiverV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request;
    return proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string support_plan_id = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Request.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverApplication: (f = msg.getOrganizationLiverApplication()) && organization_entity_pb.OrganizationLiverApplication.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response;
    return proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverApplication.deserializeBinaryFromReader);
                msg.setOrganizationLiverApplication(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverApplication.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiverApplication organization_liver_application = 1;
 * @return {?proto.OrganizationLiverApplication}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.prototype.getOrganizationLiverApplication = function () {
    return /** @type{?proto.OrganizationLiverApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverApplication, 1));
};
/**
 * @param {?proto.OrganizationLiverApplication|undefined} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response} returns this
*/
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.prototype.setOrganizationLiverApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.prototype.clearOrganizationLiverApplication = function () {
    return this.setOrganizationLiverApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateOrganizationLiverApplicationV1Response.prototype.hasOrganizationLiverApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request;
    return proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response;
    return proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
            examined: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request;
    return proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setExamined(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getExamined();
    if (f) {
        writer.writeBool(4, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional bool examined = 4;
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.getExamined = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Request.prototype.setExamined = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverApplicationsList: jspb.Message.toObjectList(msg.getOrganizationLiverApplicationsList(), organization_entity_pb.OrganizationLiverApplication.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response;
    return proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverApplication.deserializeBinaryFromReader);
                msg.addOrganizationLiverApplications(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverApplicationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationLiverApplication.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated OrganizationLiverApplication organization_liver_applications = 1;
 * @return {!Array<!proto.OrganizationLiverApplication>}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.getOrganizationLiverApplicationsList = function () {
    return /** @type{!Array<!proto.OrganizationLiverApplication>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationLiverApplication, 1));
};
/**
 * @param {!Array<!proto.OrganizationLiverApplication>} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.setOrganizationLiverApplicationsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationLiverApplication=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationLiverApplication}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.addOrganizationLiverApplications = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationLiverApplication, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.clearOrganizationLiverApplicationsList = function () {
    return this.setOrganizationLiverApplicationsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverApplicationsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationLiverApplicationId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request;
    return proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverApplicationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationLiverApplicationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string support_plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request} returns this
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_liver_application_id = 2;
 * @return {string}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.prototype.getOrganizationLiverApplicationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request} returns this
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Request.prototype.setOrganizationLiverApplicationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverApplication: (f = msg.getOrganizationLiverApplication()) && organization_entity_pb.OrganizationLiverApplication.toObject(includeInstance, f),
            organizationLiver: (f = msg.getOrganizationLiver()) && organization_entity_pb.OrganizationLiver.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response;
    return proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverApplication.deserializeBinaryFromReader);
                msg.setOrganizationLiverApplication(value);
                break;
            case 2:
                var value = new organization_entity_pb.OrganizationLiver;
                reader.readMessage(value, organization_entity_pb.OrganizationLiver.deserializeBinaryFromReader);
                msg.setOrganizationLiver(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverApplication.serializeBinaryToWriter);
    }
    f = message.getOrganizationLiver();
    if (f != null) {
        writer.writeMessage(2, f, organization_entity_pb.OrganizationLiver.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiverApplication organization_liver_application = 1;
 * @return {?proto.OrganizationLiverApplication}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.getOrganizationLiverApplication = function () {
    return /** @type{?proto.OrganizationLiverApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverApplication, 1));
};
/**
 * @param {?proto.OrganizationLiverApplication|undefined} value
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} returns this
*/
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.setOrganizationLiverApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} returns this
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.clearOrganizationLiverApplication = function () {
    return this.setOrganizationLiverApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.hasOrganizationLiverApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional OrganizationLiver organization_liver = 2;
 * @return {?proto.OrganizationLiver}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.getOrganizationLiver = function () {
    return /** @type{?proto.OrganizationLiver} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiver, 2));
};
/**
 * @param {?proto.OrganizationLiver|undefined} value
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} returns this
*/
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.setOrganizationLiver = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response} returns this
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.clearOrganizationLiver = function () {
    return this.setOrganizationLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ApproveOrganizationLiverApplicationV1Response.prototype.hasOrganizationLiver = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationLiverApplicationId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request;
    return proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverApplicationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationLiverApplicationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string support_plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request} returns this
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_liver_application_id = 2;
 * @return {string}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.prototype.getOrganizationLiverApplicationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request} returns this
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Request.prototype.setOrganizationLiverApplicationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverApplication: (f = msg.getOrganizationLiverApplication()) && organization_entity_pb.OrganizationLiverApplication.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response;
    return proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverApplication;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverApplication.deserializeBinaryFromReader);
                msg.setOrganizationLiverApplication(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverApplication();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverApplication.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiverApplication organization_liver_application = 1;
 * @return {?proto.OrganizationLiverApplication}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.prototype.getOrganizationLiverApplication = function () {
    return /** @type{?proto.OrganizationLiverApplication} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverApplication, 1));
};
/**
 * @param {?proto.OrganizationLiverApplication|undefined} value
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response} returns this
*/
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.prototype.setOrganizationLiverApplication = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response} returns this
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.prototype.clearOrganizationLiverApplication = function () {
    return this.setOrganizationLiverApplication(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.RejectOrganizationLiverApplicationV1Response.prototype.hasOrganizationLiverApplication = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchOrganizationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchOrganizationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchOrganizationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchOrganizationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Request}
 */
proto.jp.singcolor.SearchOrganizationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchOrganizationV1Request;
    return proto.jp.singcolor.SearchOrganizationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchOrganizationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Request}
 */
proto.jp.singcolor.SearchOrganizationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setKeyword(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchOrganizationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchOrganizationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchOrganizationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchOrganizationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getKeyword();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.jp.singcolor.SearchOrganizationV1Request.prototype.getKeyword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchOrganizationV1Request} returns this
 */
proto.jp.singcolor.SearchOrganizationV1Request.prototype.setKeyword = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional SimplePaginationRequest page = 2;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.SearchOrganizationV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 2));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.SearchOrganizationV1Request} returns this
*/
proto.jp.singcolor.SearchOrganizationV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Request} returns this
 */
proto.jp.singcolor.SearchOrganizationV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SearchOrganizationV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SearchOrganizationV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchOrganizationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchOrganizationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchOrganizationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchOrganizationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(), organization_entity_pb.Organization.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Response}
 */
proto.jp.singcolor.SearchOrganizationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchOrganizationV1Response;
    return proto.jp.singcolor.SearchOrganizationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchOrganizationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Response}
 */
proto.jp.singcolor.SearchOrganizationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.addOrganizations(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchOrganizationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchOrganizationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchOrganizationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated Organization organizations = 1;
 * @return {!Array<!proto.Organization>}
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.getOrganizationsList = function () {
    return /** @type{!Array<!proto.Organization>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.Organization, 1));
};
/**
 * @param {!Array<!proto.Organization>} value
 * @return {!proto.jp.singcolor.SearchOrganizationV1Response} returns this
*/
proto.jp.singcolor.SearchOrganizationV1Response.prototype.setOrganizationsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Organization}
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.addOrganizations = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Organization, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Response} returns this
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.clearOrganizationsList = function () {
    return this.setOrganizationsList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.SearchOrganizationV1Response} returns this
*/
proto.jp.singcolor.SearchOrganizationV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SearchOrganizationV1Response} returns this
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SearchOrganizationV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRedashDashboardsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRedashDashboardsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRedashDashboardsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRedashDashboardsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRedashDashboardsV1Request}
 */
proto.jp.singcolor.ListRedashDashboardsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRedashDashboardsV1Request;
    return proto.jp.singcolor.ListRedashDashboardsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRedashDashboardsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRedashDashboardsV1Request}
 */
proto.jp.singcolor.ListRedashDashboardsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRedashDashboardsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRedashDashboardsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRedashDashboardsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRedashDashboardsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRedashDashboardsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRedashDashboardsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRedashDashboardsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRedashDashboardsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            redashDashboardsList: jspb.Message.toObjectList(msg.getRedashDashboardsList(), organization_entity_pb.RedashDashboard.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRedashDashboardsV1Response}
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRedashDashboardsV1Response;
    return proto.jp.singcolor.ListRedashDashboardsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRedashDashboardsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRedashDashboardsV1Response}
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.RedashDashboard;
                reader.readMessage(value, organization_entity_pb.RedashDashboard.deserializeBinaryFromReader);
                msg.addRedashDashboards(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRedashDashboardsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRedashDashboardsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRedashDashboardsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.RedashDashboard.serializeBinaryToWriter);
    }
};
/**
 * repeated RedashDashboard redash_dashboards = 1;
 * @return {!Array<!proto.RedashDashboard>}
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.prototype.getRedashDashboardsList = function () {
    return /** @type{!Array<!proto.RedashDashboard>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.RedashDashboard, 1));
};
/**
 * @param {!Array<!proto.RedashDashboard>} value
 * @return {!proto.jp.singcolor.ListRedashDashboardsV1Response} returns this
*/
proto.jp.singcolor.ListRedashDashboardsV1Response.prototype.setRedashDashboardsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.RedashDashboard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RedashDashboard}
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.prototype.addRedashDashboards = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RedashDashboard, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRedashDashboardsV1Response} returns this
 */
proto.jp.singcolor.ListRedashDashboardsV1Response.prototype.clearRedashDashboardsList = function () {
    return this.setRedashDashboardsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRedashExportQueryTypesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRedashExportQueryTypesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRedashExportQueryTypesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRedashExportQueryTypesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Request}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRedashExportQueryTypesV1Request;
    return proto.jp.singcolor.ListRedashExportQueryTypesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRedashExportQueryTypesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Request}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRedashExportQueryTypesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRedashExportQueryTypesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRedashExportQueryTypesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRedashExportQueryTypesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRedashExportQueryTypesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            redashExportQueryTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRedashExportQueryTypesV1Response;
    return proto.jp.singcolor.ListRedashExportQueryTypesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addRedashExportQueryTypes(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRedashExportQueryTypesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRedashExportQueryTypesList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string redash_export_query_types = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.prototype.getRedashExportQueryTypesList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response} returns this
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.prototype.setRedashExportQueryTypesList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response} returns this
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.prototype.addRedashExportQueryTypes = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRedashExportQueryTypesV1Response} returns this
 */
proto.jp.singcolor.ListRedashExportQueryTypesV1Response.prototype.clearRedashExportQueryTypesList = function () {
    return this.setRedashExportQueryTypesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            queryType: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request;
    return proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setQueryType(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getQueryType();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional string query_type = 1;
 * @return {string}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.getQueryType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request} returns this
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.setQueryType = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional SimplePaginationRequest page = 2;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 2));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request} returns this
*/
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request} returns this
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationRedashExportHistoriesList: jspb.Message.toObjectList(msg.getOrganizationRedashExportHistoriesList(), organization_entity_pb.OrganizationRedashExportHistory.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response;
    return proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationRedashExportHistory;
                reader.readMessage(value, organization_entity_pb.OrganizationRedashExportHistory.deserializeBinaryFromReader);
                msg.addOrganizationRedashExportHistories(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationRedashExportHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationRedashExportHistory.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationRedashExportHistory organization_redash_export_histories = 1;
 * @return {!Array<!proto.OrganizationRedashExportHistory>}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.getOrganizationRedashExportHistoriesList = function () {
    return /** @type{!Array<!proto.OrganizationRedashExportHistory>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationRedashExportHistory, 1));
};
/**
 * @param {!Array<!proto.OrganizationRedashExportHistory>} value
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} returns this
*/
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.setOrganizationRedashExportHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationRedashExportHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationRedashExportHistory}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.addOrganizationRedashExportHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationRedashExportHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} returns this
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.clearOrganizationRedashExportHistoriesList = function () {
    return this.setOrganizationRedashExportHistoriesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} returns this
*/
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response} returns this
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListRedashExportHistoriesByQueryTypeV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationRedashExportHistoryId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request;
    return proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationRedashExportHistoryId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationRedashExportHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_redash_export_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.prototype.getOrganizationRedashExportHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request} returns this
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Request.prototype.setOrganizationRedashExportHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            presignedUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response;
    return proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPresignedUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPresignedUrl();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string presigned_url = 1;
 * @return {string}
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.prototype.getPresignedUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response} returns this
 */
proto.jp.singcolor.GenerateRedashExportPresignedUrlV1Response.prototype.setPresignedUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request;
    return proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request} returns this
*/
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationUpdateHistoriesList: jspb.Message.toObjectList(msg.getOrganizationUpdateHistoriesList(), organization_entity_pb.OrganizationUpdateHistory.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response;
    return proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationUpdateHistory;
                reader.readMessage(value, organization_entity_pb.OrganizationUpdateHistory.deserializeBinaryFromReader);
                msg.addOrganizationUpdateHistories(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationUpdateHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationUpdateHistory.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationUpdateHistory organization_update_histories = 1;
 * @return {!Array<!proto.OrganizationUpdateHistory>}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.getOrganizationUpdateHistoriesList = function () {
    return /** @type{!Array<!proto.OrganizationUpdateHistory>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationUpdateHistory, 1));
};
/**
 * @param {!Array<!proto.OrganizationUpdateHistory>} value
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.setOrganizationUpdateHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationUpdateHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationUpdateHistory}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.addOrganizationUpdateHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationUpdateHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.clearOrganizationUpdateHistoriesList = function () {
    return this.setOrganizationUpdateHistoriesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationUpdateHistoriesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request;
    return proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request} returns this
*/
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffRoleUpdateHistoriesList: jspb.Message.toObjectList(msg.getOrganizationStaffRoleUpdateHistoriesList(), organization_entity_pb.OrganizationStaffRoleUpdateHistory.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response;
    return proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationStaffRoleUpdateHistory;
                reader.readMessage(value, organization_entity_pb.OrganizationStaffRoleUpdateHistory.deserializeBinaryFromReader);
                msg.addOrganizationStaffRoleUpdateHistories(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffRoleUpdateHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationStaffRoleUpdateHistory.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationStaffRoleUpdateHistory organization_staff_role_update_histories = 1;
 * @return {!Array<!proto.OrganizationStaffRoleUpdateHistory>}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.getOrganizationStaffRoleUpdateHistoriesList = function () {
    return /** @type{!Array<!proto.OrganizationStaffRoleUpdateHistory>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationStaffRoleUpdateHistory, 1));
};
/**
 * @param {!Array<!proto.OrganizationStaffRoleUpdateHistory>} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.setOrganizationStaffRoleUpdateHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationStaffRoleUpdateHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationStaffRoleUpdateHistory}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.addOrganizationStaffRoleUpdateHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationStaffRoleUpdateHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.clearOrganizationStaffRoleUpdateHistoriesList = function () {
    return this.setOrganizationStaffRoleUpdateHistoriesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationStaffRoleUpdateHistoriesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request;
    return proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request} returns this
*/
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverLeaveHistoriesList: jspb.Message.toObjectList(msg.getOrganizationLiverLeaveHistoriesList(), organization_entity_pb.OrganizationLiverLeaveHistory.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response;
    return proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverLeaveHistory;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverLeaveHistory.deserializeBinaryFromReader);
                msg.addOrganizationLiverLeaveHistories(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverLeaveHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationLiverLeaveHistory.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationLiverLeaveHistory organization_liver_leave_histories = 1;
 * @return {!Array<!proto.OrganizationLiverLeaveHistory>}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.getOrganizationLiverLeaveHistoriesList = function () {
    return /** @type{!Array<!proto.OrganizationLiverLeaveHistory>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationLiverLeaveHistory, 1));
};
/**
 * @param {!Array<!proto.OrganizationLiverLeaveHistory>} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.setOrganizationLiverLeaveHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationLiverLeaveHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationLiverLeaveHistory}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.addOrganizationLiverLeaveHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationLiverLeaveHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.clearOrganizationLiverLeaveHistoriesList = function () {
    return this.setOrganizationLiverLeaveHistoriesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListOrganizationLiverLeaveHistoriesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationLiverGroupV1Request;
    return proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.addLiverIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
};
/**
 * optional string name = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated string liver_ids = 2;
 * @return {!Array<string>}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.getLiverIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.setLiverIdsList = function (value) {
    return jspb.Message.setField(this, 2, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.addLiverIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Request.prototype.clearLiverIdsList = function () {
    return this.setLiverIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroup: (f = msg.getOrganizationLiverGroup()) && organization_entity_pb.OrganizationLiverGroup.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationLiverGroupV1Response;
    return proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverGroup;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverGroup.deserializeBinaryFromReader);
                msg.setOrganizationLiverGroup(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroup();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverGroup.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiverGroup organization_liver_group = 1;
 * @return {?proto.OrganizationLiverGroup}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.prototype.getOrganizationLiverGroup = function () {
    return /** @type{?proto.OrganizationLiverGroup} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverGroup, 1));
};
/**
 * @param {?proto.OrganizationLiverGroup|undefined} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response} returns this
*/
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.prototype.setOrganizationLiverGroup = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupV1Response} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.prototype.clearOrganizationLiverGroup = function () {
    return this.setOrganizationLiverGroup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupV1Response.prototype.hasOrganizationLiverGroup = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request;
    return proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroup: (f = msg.getOrganizationLiverGroup()) && organization_entity_pb.OrganizationLiverGroup.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response;
    return proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverGroup;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverGroup.deserializeBinaryFromReader);
                msg.setOrganizationLiverGroup(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroup();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverGroup.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiverGroup organization_liver_group = 1;
 * @return {?proto.OrganizationLiverGroup}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.prototype.getOrganizationLiverGroup = function () {
    return /** @type{?proto.OrganizationLiverGroup} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverGroup, 1));
};
/**
 * @param {?proto.OrganizationLiverGroup|undefined} value
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response} returns this
*/
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.prototype.setOrganizationLiverGroup = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response} returns this
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.prototype.clearOrganizationLiverGroup = function () {
    return this.setOrganizationLiverGroup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateOrganizationLiverGroupV1Response.prototype.hasOrganizationLiverGroup = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request;
    return proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Request.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response;
    return proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationLiverGroupV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationLiverGroupV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationLiverGroupV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationLiverGroupV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationLiverGroupV1Request;
    return proto.jp.singcolor.GetOrganizationLiverGroupV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationLiverGroupV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Request}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationLiverGroupV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationLiverGroupV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Request} returns this
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Request.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetOrganizationLiverGroupV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetOrganizationLiverGroupV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetOrganizationLiverGroupV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroup: (f = msg.getOrganizationLiverGroup()) && organization_entity_pb.OrganizationLiverGroup.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetOrganizationLiverGroupV1Response;
    return proto.jp.singcolor.GetOrganizationLiverGroupV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverGroup;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverGroup.deserializeBinaryFromReader);
                msg.setOrganizationLiverGroup(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetOrganizationLiverGroupV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroup();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.OrganizationLiverGroup.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiverGroup organization_liver_group = 1;
 * @return {?proto.OrganizationLiverGroup}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.prototype.getOrganizationLiverGroup = function () {
    return /** @type{?proto.OrganizationLiverGroup} */ (jspb.Message.getWrapperField(this, organization_entity_pb.OrganizationLiverGroup, 1));
};
/**
 * @param {?proto.OrganizationLiverGroup|undefined} value
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response} returns this
*/
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.prototype.setOrganizationLiverGroup = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetOrganizationLiverGroupV1Response} returns this
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.prototype.clearOrganizationLiverGroup = function () {
    return this.setOrganizationLiverGroup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetOrganizationLiverGroupV1Response.prototype.hasOrganizationLiverGroup = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverGroupsV1Request;
    return proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupsList: jspb.Message.toObjectList(msg.getOrganizationLiverGroupsList(), organization_entity_pb.OrganizationLiverGroup.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverGroupsV1Response;
    return proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverGroup;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverGroup.deserializeBinaryFromReader);
                msg.addOrganizationLiverGroups(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationLiverGroup.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationLiverGroup organization_liver_groups = 1;
 * @return {!Array<!proto.OrganizationLiverGroup>}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.prototype.getOrganizationLiverGroupsList = function () {
    return /** @type{!Array<!proto.OrganizationLiverGroup>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationLiverGroup, 1));
};
/**
 * @param {!Array<!proto.OrganizationLiverGroup>} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.prototype.setOrganizationLiverGroupsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationLiverGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationLiverGroup}
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.prototype.addOrganizationLiverGroups = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationLiverGroup, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupsV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverGroupsV1Response.prototype.clearOrganizationLiverGroupsList = function () {
    return this.setOrganizationLiverGroupsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request;
    return proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request} returns this
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response;
    return proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateOrganizationLiverGroupLiversV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request;
    return proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request} returns this
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request} returns this
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response;
    return proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteOrganizationLiverGroupLiverV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request;
    return proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request} returns this
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Request.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupLiversList: jspb.Message.toObjectList(msg.getOrganizationLiverGroupLiversList(), organization_entity_pb.OrganizationLiverGroupLiver.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response;
    return proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.OrganizationLiverGroupLiver;
                reader.readMessage(value, organization_entity_pb.OrganizationLiverGroupLiver.deserializeBinaryFromReader);
                msg.addOrganizationLiverGroupLivers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.OrganizationLiverGroupLiver.serializeBinaryToWriter);
    }
};
/**
 * repeated OrganizationLiverGroupLiver organization_liver_group_livers = 1;
 * @return {!Array<!proto.OrganizationLiverGroupLiver>}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.prototype.getOrganizationLiverGroupLiversList = function () {
    return /** @type{!Array<!proto.OrganizationLiverGroupLiver>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.OrganizationLiverGroupLiver, 1));
};
/**
 * @param {!Array<!proto.OrganizationLiverGroupLiver>} value
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response} returns this
*/
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.prototype.setOrganizationLiverGroupLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OrganizationLiverGroupLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OrganizationLiverGroupLiver}
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.prototype.addOrganizationLiverGroupLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OrganizationLiverGroupLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response} returns this
 */
proto.jp.singcolor.ListOrganizationLiverGroupLiversV1Response.prototype.clearOrganizationLiverGroupLiversList = function () {
    return this.setOrganizationLiverGroupLiversList([]);
};
goog.object.extend(exports, proto.jp.singcolor);
